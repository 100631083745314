import { useDispatch, useSelector } from "react-redux";
import {
  ConcernType,
  ConcernTypeValue,
  GoalTypes,
  GroupRecommendation,
  Intervention,
  InterventionGroup,
  InterventionPlan,
} from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import React, { useEffect, useMemo, useState } from "react";
import {
  checkedInterventionPlan,
  removeFromInterventionPlan,
} from "../../../../../utils";
import moment from "moment";
import {
  addInterventionsToGroup,
  changeSelectedInterventionGroup,
  finishInterventionGroup,
  getInterventions,
  moveStudentFromGroupToGroup,
  openAddInterventionModal,
  openInterventionLibraryModal,
  patchInterventionGroup,
  showInterventionPlanModal,
  unsaveInterventionGroup,
} from "../../../../../../store/onboarding/cases/actions";
import { CoachInfo, Student, TeacherInfo } from "../../../../../../store/onboarding/types";
import { Specific } from "../../../../../../store/groups/types";
import useHighlightGroupCard from "./useHighlightGroupCard";
import { openConfirmSecondaryGoalCreateModal } from "../../../../../../store/onboarding/actions";
import useUserRole from "../../../../../../utils/hooks/useUserRole";

const useUnsavedGroupCards = (caseId?: number) => {
  const dispatch = useDispatch();
  const { isTeacher } = useUserRole();
  const notFinishedGroups = useSelector<ApplicationState, InterventionGroup[]>(
    (s) => s.cases.caseInterventionGroups[caseId!]?.filter((intGrp) => !intGrp.finished)
  );
  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRosterWithDummyUser
  );
  const coaches = useSelector<ApplicationState, CoachInfo[]>(
    (s) => s.onboarding.coachesRosterWithDummyUser
  );

  const [
    normalizedNotFinishedGroups,
    setNormalizedNotFinishedGroups,
  ] = useState<GroupRecommendation[]>([]);

  useEffect(() => {
    setNormalizedNotFinishedGroups(
      notFinishedGroups?.map((g) => ({
        id: g.id,
        oldId: (g as any).oldId,
        name: g.name,
        tier: g.mtss_tier,
        default_interventionist_id: g.teacher_assignment!.user.id,
        default_coach_id: g.coach_assignment!.user.id,
        students: g.students,
        focuses: g.focuses ?? [],
        interventions: g.interventions,
        intervention_plan: checkedInterventionPlan(
          g.interventions,
          g.intervention_plan
        ),
        student_goals: g.student_goals,
        goal_type: g.goal_type,
        //todo fix when API is updated
        time_monday_start: g.time_monday_start
          ? moment(g.time_monday_start, "h:m a").format("HH:mm")
          : null,
        time_tuesday_start: g.time_tuesday_start
          ? moment(g.time_tuesday_start, "h:m a").format("HH:mm")
          : null,
        time_wednesday_start: g.time_wednesday_start
          ? moment(g.time_wednesday_start, "h:m a").format("HH:mm")
          : null,
        time_thursday_start: g.time_thursday_start
          ? moment(g.time_thursday_start, "h:m a").format("HH:mm")
          : null,
        time_friday_start: g.time_friday_start
          ? moment(g.time_friday_start, "h:m a").format("HH:mm")
          : null,

        time_monday_end: g.time_monday_end
          ? moment(g.time_monday_end, "h:m a").format("HH:mm")
          : null,
        time_tuesday_end: g.time_tuesday_end
          ? moment(g.time_tuesday_end, "h:m a").format("HH:mm")
          : null,
        time_wednesday_end: g.time_wednesday_end
          ? moment(g.time_wednesday_end, "h:m a").format("HH:mm")
          : null,
        time_thursday_end: g.time_thursday_end
          ? moment(g.time_thursday_end, "h:m a").format("HH:mm")
          : null,
        time_friday_end: g.time_friday_end
          ? moment(g.time_friday_end, "h:m a").format("HH:mm")
          : null,
        session_duration: g.session_duration,
        session_frequency: g.session_frequency
      }))
    );
  }, []);

  const onInterventionLibraryModal = (group: GroupRecommendation) => () => {
    const foundedGroup = notFinishedGroups.find((ig) => ig.id === group.id); //todo optimize
    if (foundedGroup) {
      dispatch(getInterventions(ConcernTypeValue[ConcernType.BOTH]));
      dispatch(changeSelectedInterventionGroup(foundedGroup));
      dispatch(openInterventionLibraryModal());
    }
  };

  const onAddInterventionModal = (group: GroupRecommendation) => () => {
    const foundedGroup = notFinishedGroups.find((ig) => ig.id === group.id); //todo optimize
    if (foundedGroup) {
      dispatch(changeSelectedInterventionGroup(foundedGroup));
      dispatch(openAddInterventionModal());
    }
  };

  const onFinishGroup = (group: GroupRecommendation) => {
    const foundedGroup = notFinishedGroups.find((ig) => ig.id === group.id); //todo optimize
    if (foundedGroup) {
      dispatch<any>(finishInterventionGroup(foundedGroup.id!)).then(
        (group: InterventionGroup) => {
          highlightGroup(
            `employee_assignment_${isTeacher ? group.coach_assignment?.user.id : group.teacher_assignment?.user.id}`,
            group.id
          );
          dispatch(openConfirmSecondaryGoalCreateModal(group));
        },
        () => {}
      );
    }
  };

  const [groupToChange, setGroupToChange] = useState<
    GroupRecommendation | undefined
  >();
  const [showUnsaveModal, setShowUnsaveModal] = useState<boolean>(false);
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false);
  const [timeDay, setTimeDay] = useState<string | undefined>();

  const onUnsaveGroup = (groupRecommendation: GroupRecommendation) => {
    setGroupToChange(groupRecommendation);
    setShowUnsaveModal(true);
  };

  const { highlightGroup } = useHighlightGroupCard();

  const onConfirmUnsaveGroup = () => {
    if (groupToChange) {
      const interventionGroup = notFinishedGroups.find(
        (ig) => ig.id === groupToChange.id //todo optimize
      );
      if (interventionGroup) {
        dispatch<any>(
          unsaveInterventionGroup(interventionGroup, groupToChange)
        ).then(
          () => {
            highlightGroup("recommendations-collapse", groupToChange.oldId);
          },
          () => {}
        );
      }
    }
  };

  const onHideUnsaveGroup = () => {
    setGroupToChange(undefined);
    setShowUnsaveModal(false);
  };

  const onSaveName = (group: GroupRecommendation) => (name: string) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) => (g.id === group.id ? { ...g, name: name } : g))
      );
      dispatch(patchInterventionGroup(group.id, { name: name }));
    }
  };

  const onChangeGoalType = (group: GroupRecommendation) => (
    goalType?: GoalTypes
  ) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id ? { ...g, goal_type: goalType } : g
        )
      );
      dispatch(patchInterventionGroup(group.id, { goal_type: goalType }));
    }
  };

  const onChangeTier = (group: GroupRecommendation) => (tier: 1 | 2 | 3) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) => (g.id === group.id ? { ...g, tier: tier } : g))
      );
      dispatch(patchInterventionGroup(group.id, { mtss_tier: tier }));
    }
  };

  const onChangeTeacher = (group: GroupRecommendation) => (id: number) => {
    const foundTeacher = teachers.find(
      (t) => t.user.profile.current_assignment && t.user.id === id
    );
    if (group.id && foundTeacher) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id ? { ...g, default_interventionist_id: id } : g
        )
      );
      dispatch(
        patchInterventionGroup(group.id, {
          teacher_assignment: foundTeacher.user.profile.current_assignment?.id,
        })
      );
    }
  };

  const onChangeCoach = (group: GroupRecommendation) => (id: number) => {
    const foundCoach = coaches.find(
      (t) => t.user.profile.current_assignment && t.user.id === id
    );
    if (group.id && foundCoach) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id ? { ...g, default_coach_id: id } : g
        )
      );
      dispatch(
        patchInterventionGroup(group.id, {
          coach_assignment: foundCoach.user.profile.current_assignment?.id,
        })
      );
    }
  };

  const onAddStudent = (group: GroupRecommendation) => (student: Student) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id
            ? {
                ...g,
                students: [student, ...g.students],
              }
            : g
        )
      );
      dispatch(
        patchInterventionGroup(group.id, {
          students: [student.id!, ...group.students.map((st) => st.id!)],
        })
      );
    }
  };

  const onRemoveStudent = (group: GroupRecommendation) => (
    student: Student
  ) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id
            ? {
                ...g,
                students: [...g.students.filter((st) => st !== student)],
              }
            : g
        )
      );
      dispatch(
        patchInterventionGroup(group.id, {
          students: group.students
            .filter((st) => st.id != null && st !== student)
            .map((st) => st.id!),
        })
      );
    }
  };

  const onRemoveFocus = (group: GroupRecommendation) => (focus: Specific) => {
    if (group.id) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id
            ? { ...g, focuses: [...g.focuses.filter((foc) => foc !== focus)] }
            : g
        )
      );

      dispatch(
        patchInterventionGroup(group.id, {
          focuses: group.focuses
            .filter((foc) => foc !== focus)
            .map((fc) => fc.identifier),
        })
      );
    }
  };

  const onRemoveIntervention = (group: GroupRecommendation) => (
    intervention: Intervention
  ) => {
    if (group.id) {
      const changedPlan = removeFromInterventionPlan(
        intervention,
        group.interventions,
        group.intervention_plan
      );

      const changedInterventionsList = group.interventions.filter(
        (int) => int !== intervention
      );

      dispatch<any>(
        addInterventionsToGroup(
          group.id,
          changedInterventionsList.map((int) => int.id)
        )
      ).then(() => {
        if (group.id) {
          dispatch(
            patchInterventionGroup(group.id, {
              intervention_plan: changedPlan,
            })
          );
        }
      });

      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id
            ? {
                ...g,
                interventions: g.interventions.filter(
                  (int) => int.id !== intervention.id
                ),
                intervention_plan: changedPlan,
              }
            : g
        )
      );
    }
  };

  const onClickSelectTime = (group: GroupRecommendation) => (day: string) => {
    setGroupToChange(group);
    setTimeDay(day);
    setShowTimeModal(true);
  };

  const onHideSetTime = () => {
    setGroupToChange(undefined);
    setTimeDay(undefined);
    setShowTimeModal(false);
  };

  const onSaveSetTime = (time: string | null) => {
    if (groupToChange && groupToChange.id && timeDay) {
      dispatch<any>(
        patchInterventionGroup(groupToChange.id, {
          [timeDay]: time,
        })
      ).then(() => {
        setNormalizedNotFinishedGroups((groups) =>
          groups.map((g) =>
            g.id === groupToChange.id ? { ...g, [timeDay]: time } : g
          )
        );
      });
    }
  };

  const dayTime = useMemo(() => {
    if (groupToChange && timeDay) {
      // @ts-ignore
      return groupToChange[timeDay];
    }
  }, [groupToChange, timeDay]);

  const onDragDropStudent = (nextGroup: GroupRecommendation) => (
    student: Student,
    prevGroup: GroupRecommendation
  ) => {
    if (nextGroup.id && prevGroup.id) {
      const studentGoal = prevGroup.student_goals?.find(
        (sg) => sg.student.id === student.id
      );

      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === nextGroup.id
            ? {
                ...nextGroup,
                students: [...nextGroup.students, student],
                student_goals: studentGoal
                  ? [...nextGroup.student_goals, studentGoal]
                  : nextGroup.student_goals,
              }
            : g.id === prevGroup.id
            ? {
                ...prevGroup,
                students: [
                  ...prevGroup.students.filter((st) => st.id !== student.id),
                ],

                student_goals: [
                  ...prevGroup.student_goals.filter(
                    (sg) => sg.student.id !== student.id
                  ),
                ],
              }
            : g
        )
      );

      //todo remove student_goals if api has changed and it's not needed
      dispatch(
        moveStudentFromGroupToGroup(
          prevGroup.id,
          {
            students: prevGroup.students
              .filter((st) => st.id != null && st.id !== student.id)
              .map((st) => st.id!),
            student_goals: studentGoal
              ? prevGroup.student_goals
                  .filter((sg) => sg.id !== studentGoal.id)
                  .map((sg) => sg.id)
              : undefined,
          },
          nextGroup.id,
          {
            students: [...nextGroup.students, student]
              .filter((st) => st.id != null)
              .map((st) => st.id!),
            student_goals: studentGoal
              ? [...nextGroup.student_goals, studentGoal].map((sg) => sg.id)
              : undefined,
            students_with_focuses: [...nextGroup.students, student].filter((std) => std.focuses).map((std) => ({
              focuses: std.focuses,
              student_id: std.id
            }))
          }
        )
      );
    }
  };

  const isLoadingPatchInterventionGroup = useSelector<
    ApplicationState,
    boolean
  >((s) => s.cases.isLoading.patchInterventionGroup);
  const isLoadingUnsaveInterventionGroup = useSelector<
    ApplicationState,
    boolean
  >((s) => s.cases.isLoading.unsaveInterventionGroup);

  const onDragInterventionEnd = (group: GroupRecommendation) => () => {
    if (group.id && !isLoadingPatchInterventionGroup) {
      dispatch(
        patchInterventionGroup(group.id, {
          intervention_plan: group.intervention_plan,
        })
      );
    }
  };

  const onDragInterventionUpdate = (group: GroupRecommendation) => (
    interventionPlan: InterventionPlan
  ) => {
    if (group.id && !isLoadingPatchInterventionGroup) {
      setNormalizedNotFinishedGroups((groups) =>
        groups.map((g) =>
          g.id === group.id
            ? { ...group, intervention_plan: interventionPlan }
            : g
        )
      );
    }
  };

  return {
    normalizedNotFinishedGroups,
    notFinishedGroups,

    showUnsaveModal,
    showTimeModal,
    dayTime,
    isLoadingPatchInterventionGroup,
    isLoadingUnsaveInterventionGroup,

    onFinishGroup,
    onUnsaveGroup,
    onConfirmUnsaveGroup,
    onHideUnsaveGroup,
    onSaveName,
    onChangeGoalType,
    onChangeTier,
    onChangeTeacher,
    onChangeCoach,
    onAddStudent,
    onRemoveStudent,
    onRemoveFocus,
    onRemoveIntervention,

    onClickSelectTime,
    onHideSetTime,
    onSaveSetTime,
    onDragDropStudent,

    onInterventionLibraryModal,
    onAddInterventionModal,
    onDragInterventionEnd,
    onDragInterventionUpdate,
  };
};

export default useUnsavedGroupCards;
