import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { getFullName } from "../../../../../../../../../utils/NamesUtils";
import { Student } from "../../../../../../../../../store/onboarding/types";
import {
  InterventionGroup,
  LessonNotes,
  StudentGoal,
} from "../../../../../../../../../store/onboarding/cases/types";

type OwnProps = {
  allSelectedByDefault?: boolean;
  students: Student[];
  selectedStudents: number[];
  setSelectedStudents: React.Dispatch<React.SetStateAction<number[]>>;
  intervention?: InterventionGroup;
  selectedLessonNotes?: LessonNotes;
};

type Props = OwnProps;

const AttendanceStudentsList: FunctionComponent<Props> = (props) => {
  const {
    students,
    selectedStudents,
    setSelectedStudents,
    allSelectedByDefault = true,
    intervention,
    selectedLessonNotes
  } = props;

  const filterStudents = (
    selectedInterventionGroup: InterventionGroup | undefined
  ) => {
    const students = selectedInterventionGroup?.students.filter(
      (student, index) => {
        let studentGoal: StudentGoal | undefined;
        if (selectedInterventionGroup?.student_goals) {
          studentGoal = selectedInterventionGroup?.student_goals.find(
            (x) => x.student.id === student.id && !x.archived
          );
        }
        return !!studentGoal;
      }
    );
    return students;
  };
  const [currentStudents, setCurrentStudents] = useState(
    intervention ? filterStudents(intervention) : []
  );

  const allStudentsCheckboxRef = useRef<HTMLInputElement>(null);

  const allStudentsIds = useMemo(() => {
    return currentStudents ? currentStudents?.map((st) => st.id!) : [];
  }, [currentStudents]);

  const allSelected = useMemo(
    () => { let uniqueSelectedStudents = selectedStudents.filter((value, index, array) => array.indexOf(value) === index);
      return uniqueSelectedStudents.length === allStudentsIds.length}
  ,[selectedStudents, allStudentsIds])

  useEffect(() => {
    if (selectedLessonNotes) {
      setSelectedStudents(selectedLessonNotes?.pertaining_students!);
    } else {
      setSelectedStudents(allStudentsIds)
    }
  }, [allSelectedByDefault]);

  const handleSelectedStudentsChange = (studentId: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    const newSelectedStudents = checked
      ? [...selectedStudents, studentId]
      : selectedStudents.filter((id) => id !== studentId);

    if (allStudentsCheckboxRef.current) {
      if (newSelectedStudents.length) {
        allStudentsCheckboxRef.current.indeterminate =
          newSelectedStudents.length !== allStudentsIds.length;
      } else {
        allStudentsCheckboxRef.current.indeterminate = false;
      }
    }
    setSelectedStudents(newSelectedStudents);
  };

  return (
    <Form.Group className="my-2">
      <Form.Label>Students:</Form.Label>
      <Form.Check
        id={"all-students-checkbox"}
        ref={allStudentsCheckboxRef as any}
        custom
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const checked = e.target.checked;
          setSelectedStudents(checked ? allStudentsIds : []);
        }}
        className="customDefaultCheckbox"
        type="checkbox"
        label="All Students"
        checked={allSelected}
      />
      {currentStudents?.map((st) => (
        <Form.Check
          id={`student-checkbox-${st.id}`}
          key={`student-checkbox-${st.id}`}
          custom
          onChange={handleSelectedStudentsChange(st.id!)}
          className="customDefaultCheckbox"
          type="checkbox"
          label={getFullName(st)}
          checked={selectedStudents.includes(st.id!)}
        />
      ))}
    </Form.Group>
  );
};

export default AttendanceStudentsList;
