import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import StudentGroupsTooltip from "./StudentGroupsTooltip";
import { Student } from "../../../../../../store/onboarding/types";

type Props = {
  interventionGroups: any;
  student: Student;
};

const StudentGroupIconOverlay = ({ interventionGroups, student }: Props) => {
  const studentInterventionGroups = interventionGroups ? interventionGroups[student.id!]: null;
  if (!studentInterventionGroups) return null;
  return (
    <OverlayTrigger
      placement="right-start"
      overlay={
        <StudentGroupsTooltip interventionGroups={studentInterventionGroups} />
      }
      trigger={['hover', 'focus']}
    >
      <div className="interventionGroupIcon">&nbsp;&nbsp;
        {/* <GroupCountIcon svgSize={10} studentsNum={0} /> */}
      </div>
    </OverlayTrigger>
  );
};
export default StudentGroupIconOverlay;
