import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  AlertReason,
  GroupRecommendation,
  InterventionGroup,
  InterventionPlan,
  StudentGoal,
} from "../../../../../../store/onboarding/cases/types";
import useInterventionGroupItem from "./hooks/useInterventionGroupItem";
import ChangeGroupOverlay from "./ChangeGroupOverlay";
import InterviewTimeDayElements from "./InterviewTimeDayElements";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import EditableGroupName from "./EditableGroupName";
import InterventionGroupStudents from "./InterventionGroupStudents";
import { Student } from "../../../../../../store/onboarding/types";
import AverageFidelity from "./AverageFidelity";
import LatestActivityComp from "./LatestActivityComp";
import {
  changeSelectedInterventionGroup,
  clickedFromAlert,
  openInterventionLibraryModal,
  patchInterventionGroup,
  sendNotSharedWithData,
  sendSharedWithData,
  showInterventionPlanModal,
} from "../../../../../../store/onboarding/cases/actions";
import { Alert, Collapse, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import TierSelector from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/TierSelector";
import InterventionsList from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/InterventionsList";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { changeInterventionPlanOrder } from "../../../../../utils";
import { openEndInterventionModal } from "../../../../../../store/onboarding/meeting-module/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretLeft,  faExclamationTriangle,  faPlusCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { UserInfo } from "../../../../../../store/auth/types";
import { changeIntGroupInnerCollapsedSettings } from "../../../../../../store/onboarding/actions";
import useUserAssignedToGroup from "./hooks/useUserAssignedToGroup";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import AdditionalEducatorsSelector from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/AdditionalEducatorsSelector";

type StateProps = {
  collapsedIntGroupInnerIds: any[];
  isLoading?: {
    loaderIntGroupBehavior?: boolean;
    loaderIntGroupBehaviorArchived?: boolean;
    loaderIntGroupReading?: boolean;
    loaderIntGroupReadingArchived?: boolean;
    loaderIntGroupStudentDetail?: boolean;
    loaderIntGroupStudentDetailArchived?: boolean;
  };
};

type OwnProps = {
  interventionGroup: InterventionGroup;
  isReadonly?: boolean;
  showArchivedGroups?: boolean;
  student?: Student;
  exitedStudentsGroup?: boolean;
  onDragDropStudent: (student: Student, group: GroupRecommendation) => void;
  //shareDataWith?: boolean;
  notShareDataWith?: boolean;
  caseId?: number;
  progressOverviewLoader?: boolean;
  isLoadingPatchInterventionGroup?: boolean;
};

type DispatchProps = {
  changeIntGroupInnerCollapsedSettings: (args: {
    accordianSetting: any;
    loggedInUserId: number;
    interventionGroupId: number;
  }) => any;
};

type Props = OwnProps & StateProps & DispatchProps;

const MAX_ALERT_DISPLAYED_NUM = 1;

const InterventionGroupGridView: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { isDistrictOwner } = useUserRole();

  const {
    student,
    interventionGroup,
    isReadonly,
    showArchivedGroups,
    onDragDropStudent,
    exitedStudentsGroup,
    //shareDataWith,
    notShareDataWith,
    isLoading,
    caseId,
    progressOverviewLoader,
    isLoadingPatchInterventionGroup
  } = props;

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id)

  const {
    hasAlert,
    stage,
    infoByStage,
    onSupportImplFeedbackModalOpen,
    onGroupDetailsShow,
  } = useInterventionGroupItem({
    interventionGroup,
    showArchivedGroups,
    isReadonly,
    hideChartActions: true,
    isIntGrpIDsWithReadAccess,
    progressOverviewLoader
  });

  const isStudentDetailReducer = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  const { isUserAssignedToGroup, isStudentDetail, userIconAssignedToGroup, isCoachOrTeacharAssignedToGroup } = useUserAssignedToGroup(interventionGroup);
  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);

  const onViewInterventionPlan = (e: React.MouseEvent, isClickedFromAlert: boolean) => {
    e.stopPropagation();
    dispatch(showInterventionPlanModal(interventionGroup, caseId));
    dispatch(clickedFromAlert({isClickedFromAlert}))
    if(isStudentDetailReducer) {
      //dispatch(sendSharedWithData(shareDataWith))
      dispatch(sendNotSharedWithData(notShareDataWith))
    }
  };

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );
  const [isEditName, setIsEditName] = useState<boolean>(false);

  const [loadingPatchIntervention, setLoadingPatchIntervention] = useState<
    boolean
  >(false);
  const [loadingChangeTier, setLoadingChangeTier] = useState<boolean>(false);

  const [interventionCollapsed, setInterventionCollapsed] = useState<boolean>(true);
  const [avgCollapsed, setAvgCollapsed] = useState<boolean>(false);
  const [additionalEducatorCollapsed, setAdditionalEducatorCollapsed] = useState<boolean>(false);

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const handleCollapse = (sectionName:string = 'avgFidelity') => {
    let accordianSetting: any = {};
    let accordianGroupSetting: any = {};

    accordianGroupSetting[interventionGroup.id]= {
      ...(sectionName == 'intervention' ? {interventionCollapsed: !interventionCollapsed} : ''),
      ...(sectionName == 'avgFidelity' ? {avgCollapsed: !avgCollapsed} : ''),
      ...(sectionName == 'additionalEducator' ? {additionalEducatorCollapsed: !additionalEducatorCollapsed} : '')
    };
    let loggedInUserId = currentUser!.id;
    accordianSetting[loggedInUserId]= accordianGroupSetting;

    props.changeIntGroupInnerCollapsedSettings({
      accordianSetting: accordianSetting, loggedInUserId: loggedInUserId, interventionGroupId: interventionGroup.id
    });
    if(sectionName == 'intervention') {
      setInterventionCollapsed((pS) => !pS);
    }
    if(sectionName == 'avgFidelity') {
      setAvgCollapsed((pS) => !pS);
    }
    if(sectionName == 'additionalEducator') {
      setAdditionalEducatorCollapsed((pS) => !pS);
    }
  };

  useEffect(() => {
    let interventionCollapsed =  
      (Object.keys(props.collapsedIntGroupInnerIds).length > 0) 
      && (props.collapsedIntGroupInnerIds[currentUser!.id] !== undefined) 
      && (props.collapsedIntGroupInnerIds[currentUser!.id][interventionGroup.id] !== undefined) 
      ? props.collapsedIntGroupInnerIds[currentUser!.id][interventionGroup.id]['interventionCollapsed'] 
      : true; 
    let avgCollapsed = 
      (Object.keys(props.collapsedIntGroupInnerIds).length > 0) 
      && (props.collapsedIntGroupInnerIds[currentUser!.id] !== undefined) 
      && (props.collapsedIntGroupInnerIds[currentUser!.id][interventionGroup.id] !== undefined) 
      ? props.collapsedIntGroupInnerIds[currentUser!.id][interventionGroup.id]['avgCollapsed'] 
      : true;

    setInterventionCollapsed(interventionCollapsed);
    setAvgCollapsed(avgCollapsed);

    let accordianSetting: any = {};
    let accordianGroupSetting: any = {};

    accordianGroupSetting[interventionGroup.id]= {
      interventionCollapsed: interventionCollapsed,
      avgCollapsed: avgCollapsed
    };
    let loggedInUserId = currentUser!.id;
    accordianSetting[loggedInUserId]= accordianGroupSetting;

    props.changeIntGroupInnerCollapsedSettings({
      accordianSetting: accordianSetting, loggedInUserId: loggedInUserId, interventionGroupId: interventionGroup.id
    });
  }, [interventionCollapsed, avgCollapsed, currentUser, interventionGroup]);

  const alertTextArray = useMemo(() => {
    if(hasAlert && !interventionGroup.archived) {
      let alertArray = [];
      for (const key in interventionGroup.alert_reason_text) {
        if (Object.prototype.hasOwnProperty.call(interventionGroup.alert_reason_text, key)) {
            const alertData = {
              'order': key,
              'alert_reason_text': interventionGroup.alert_reason_text[+key][1]
            };
            alertArray.push(alertData);
        }
      }
      alertArray = alertArray.sort((a, b) => a.order > b.order ? -1 : 1)
      return alertArray
    }
  },[hasAlert, interventionGroup]) 

  const handleInterventionGroupMtssTierChange = (newTier: 1 | 2 | 3) => {
    setLoadingChangeTier(true);
    dispatch<any>(
      patchInterventionGroup(interventionGroup.id, { mtss_tier: newTier })
    ).then(() => setLoadingChangeTier(false));
  };

  const [
    interventionPlan,
    setInterventionPlan,
  ] = useState<InterventionPlan | null>(null);

  useEffect(() => {
    setInterventionPlan(interventionGroup.intervention_plan);
  }, [interventionGroup.intervention_plan]);

  const onDragInterventionUpdate = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (destination && destination.droppableId === source.droppableId) {
      const { intervention_plan, interventions } = interventionGroup;
      const changedPlan = changeInterventionPlanOrder(
        intervention_plan,
        interventions,
        source.index,
        destination.index,
        draggableId
      );
      setInterventionPlan(changedPlan);
    }
  };

  const onDragInterventionEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      destination &&
      !(
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
    ) {
      setLoadingPatchIntervention(true);
      dispatch<any>(
        patchInterventionGroup(interventionGroup.id, {
          intervention_plan: interventionPlan,
        })
      ).then(() => {
        setLoadingPatchIntervention(false);
      });
    }
  };

  const onRemoveIntervention = () => {
    dispatch(changeSelectedInterventionGroup(interventionGroup));
    dispatch(openEndInterventionModal());
  };

  const handleAddIntervention = () => {
    dispatch(changeSelectedInterventionGroup(interventionGroup));
    dispatch(
      openInterventionLibraryModal(undefined, undefined, {
        onBack: () => {
          dispatch(showInterventionPlanModal());
        },
      })
    );
  };

  let studentList: Student[] = [];

  interventionGroup.student_goals.map((item) => {
    if (item.student.id == student?.id) {
      studentList.push(item.student);
    }
  });

  studentList = studentList.filter(
    (value, index, self) => self.findIndex((m) => m.id === value.id) === index
  );

  return (
    <div
      id={`group_card_${interventionGroup.id}`}
      data-stage={stage}
      className="intervention-group-grid-item"
    >
      <div
        className={`intervention-group-grid-item-header ${
          interventionGroup.is_sample ? "sample" : ""
        }`}
      >
        <EditableGroupName
          isReadonly={isReadonly || showArchivedGroups || isIntGrpIDsWithReadAccess}
          isEditName={isEditName}
          setIsEditName={setIsEditName}
          interventionGroupId={interventionGroup.id}
          interventionGroupName={interventionGroup.name}
        />
        {userIconAssignedToGroup.icon ? 
          <div className="flex-2">
            <OverlayTrigger
              overlay={ (
                  <Popover id={"loggedInUserAssignedGroupPopover"}>
                    <Popover.Content>
                      <div>{userIconAssignedToGroup.title} </div>
                    </Popover.Content>
                  </Popover>
                )
              }
            >
              <img
                style={{
                  width: userIconAssignedToGroup.imgSize,
                  height: "auto",
                  marginLeft: 10
                }}
                src={`/media/${userIconAssignedToGroup.icon}.png`}
                alt="ReadyCoach"
              />

            </OverlayTrigger>
          </div>
        : ''
        }
        {!isEditName && !isReadonly && !isIntGrpIDsWithReadAccess && (
          <div className="d-flex align-items-center justify-content-between ml-2">
            <TierSelector
              loading={loadingChangeTier}
              className="finished"
              groupTier={interventionGroup.mtss_tier}
              onChangeTier={handleInterventionGroupMtssTierChange}
              interventionGroupId={interventionGroup.id}
            />
            {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && isCoachOrTeacharAssignedToGroup && <div
              className="changeGroupBtn ml-2"
              onClick={(e) => e.stopPropagation()}
            >
              <ChangeGroupOverlay 
                interventionGroup={interventionGroup} 
                isCoachOrTeacharAssignedToGroup={isCoachOrTeacharAssignedToGroup} 
              />
            </div>}
          </div>
        )}
      </div>

      {hasAlert && !interventionGroup.archived && (
          <Alert
            variant="warning"
            style={{marginBottom: ".1rem"}}
            //onClick={onSupportImplFeedbackModalOpen}
          >
            <div  className="d-flex  justify-content-between">
               <div className={alertTextArray!.length > 1 ? "w-75" : "w-100"}>{alertTextArray?.slice(0, MAX_ALERT_DISPLAYED_NUM).map((alertText) => alertText.alert_reason_text)}</div>
                {(alertTextArray!.length > MAX_ALERT_DISPLAYED_NUM) ?
                <span className="font-weight-bold ml-2 float-right pointer" onClick={(e) => onViewInterventionPlan(e, true)}>
                  {`+${alertTextArray!.length - MAX_ALERT_DISPLAYED_NUM} more`}
                </span> 
              : ''} 
             
              {interventionGroup.alert_reason.includes(AlertReason.InsufficientGoalProgress)
              ? 
              <>
                <OverlayTrigger
                  overlay={ (
                      <Popover id={"groupAlertPopover"}>
                        <Popover.Content>
                          <div>Insufficient fidelity data logged for this group.</div>
                        </Popover.Content>
                      </Popover>
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="1x"
                    style={{ color: "#c74b4b", fontSize: "1.25rem" }}
                    className="mx-1"
                  />
                </OverlayTrigger>
              </>
              : 
                <OverlayTrigger
                  overlay={ (
                      <Popover id={"groupAlertPopover"}>
                        <Popover.Content>
                          <div>Alerts are based on previously logged data</div>
                        </Popover.Content>
                      </Popover>
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="1x"
                    style={{ color: "#00265b" }}
                    className="my-1"
                  />
                </OverlayTrigger>
              }
              
            </div>
          </Alert>
      )}

      <div className="intervention-group-grid-item-body intervention-box">
        {!isReadonly && (
          <>
          {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && isCoachOrTeacharAssignedToGroup 
          ?
            <>
              <div
                className="assigmentCollapseHeader"
                aria-controls={"additional-educator-collapse"}
                aria-expanded={additionalEducatorCollapsed}
                onClick={() => handleCollapse('additionalEducator')}
              >
                <div className="interventionAvg">
                  <h5 className="section w-100">
                    ADDITIONAL EDUCATOR(S)
                  </h5>
                    <span className="pointer pr-1 interventionAvgArrow">
                      <FontAwesomeIcon
                        icon={additionalEducatorCollapsed ? faCaretDown : faCaretLeft}
                        size="1x"
                      />
                    </span>
                </div>
              </div>
              <Collapse in={additionalEducatorCollapsed}>
                <div id="additional-educator-collapse" className="finished-group-additional-educator">
                  <AdditionalEducatorsSelector 
                    interventionGroup={interventionGroup}
                  />
                </div>
              </Collapse>
            </>
          : ''
          }
            <div className="interventionTimeOfDayPanel">
              <h5 className="section">Intervention Schedule</h5>
              <InterviewTimeDayElements interventionGroup={interventionGroup} />
            </div>
          </>
        )}

        <h5 className="section">
          {interventionGroup?.finished 
          ? exitedStudentsGroup && student
            ? `EXITED STUDENT WITH ARCHIVED GOAL(S)`
            : `Students With ${(isReadonly || showArchivedGroups) ? 'Archived' : ''} Goal` 
          : `Students `}
        </h5>
        <div
          className="d-flex align-items-center pointer"
          onClick={onGroupDetailsShow}
        >
          {exitedStudentsGroup && student ? (
            <>
              <InterventionGroupStudents
                interventionGroup={interventionGroup}
                onDragDropStudent={onDragDropStudent}
                isReadonly={isReadonly || showArchivedGroups}
                students={studentList.filter((item) => item.id == student?.id)}
                exitedStudentsGroup={exitedStudentsGroup}
              />
            </>
          ) : (
            <InterventionGroupStudents
              interventionGroup={interventionGroup}
              onDragDropStudent={onDragDropStudent}
              isReadonly={isReadonly || showArchivedGroups}
              students={
                (displayGroup == 'archived' || showArchivedGroups || isReadonly) 
                ?
                  interventionGroup?.student_goals
                    .filter((sg: StudentGoal) => sg.archived)
                    .map((sg: StudentGoal) => sg.student) || []
                :
                interventionGroup.students.filter(
                  (item) => !student || item.id === student.id
                  ).filter((student:any) => {
                    return interventionGroup?.student_goals.some(
                      (sg:any) => (student.id == sg.student.id) && (displayGroup == 'archived' || showArchivedGroups || isReadonly) 
                                  ? sg.archived : !sg.archived)
                    } 
                  )
              
              }
              isShowStudentCount={true}
            />
          )}
        </div>
        
        {!isStudentDetail && !isGroupDetail 
        ?
        <>
          <h5 className="section">{interventionGroup?.finished ? `Students Without ${(isReadonly || showArchivedGroups) ? 'Archived' : ''} Goal` : `Students `}</h5>
          <div
            className="d-flex align-items-center pointer"
            onClick={onGroupDetailsShow}
          >
            {exitedStudentsGroup && student ? (
              <>
                <InterventionGroupStudents
                  interventionGroup={interventionGroup}
                  onDragDropStudent={onDragDropStudent}
                  isReadonly={isReadonly || showArchivedGroups}
                  students={studentList.filter((item) => item.id == student?.id)}
                  exitedStudentsGroup={exitedStudentsGroup}
                />
              </>
            ) : (
              <InterventionGroupStudents
                interventionGroup={interventionGroup}
                onDragDropStudent={onDragDropStudent}
                isReadonly={isReadonly || showArchivedGroups}
                students={interventionGroup.students.filter(
                  (item) => !student || item.id === student.id
                ).filter((student:any) => {
                    return !interventionGroup?.student_goals.some(
                      (sg:any) => (student.id == sg.student.id))
                  
                } 
              )}
                isShowStudentCount={false}
              />
            )}
          </div>
        </>      
        : ''
        }
        <div
          className="assigmentCollapseHeader"
          aria-controls={"interventions-collapse"}
          aria-expanded={interventionCollapsed}
          onClick={() => handleCollapse('intervention')}
        >
          <div className="interventionAvg">
            <h5 className="section w-100">
            
              Intervention(s)
              {loadingPatchIntervention ? (
                <Spinner className={"ml-1"} animation="border" size="sm" />
              ) : (
                !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess &&  <span onClick={handleAddIntervention} className="pointer ml-1">
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                </span>
              )}
            </h5>
            <span className="pointer pr-1 interventionAvgArrow">
              <FontAwesomeIcon
                icon={interventionCollapsed ? faCaretDown : faCaretLeft}
                size="1x"
              />
            </span>

          </div>
        </div>
        <Collapse in={interventionCollapsed}>
          <div id="interventions-collapse">    
            <DragDropContext
              onDragUpdate={onDragInterventionUpdate}
              onDragEnd={onDragInterventionEnd}
            >
              <InterventionsList
                showIfNoData
                interventionGroupId={interventionGroup.id!}
                interventions={interventionGroup.interventions}
                interventionPlan={interventionPlan}
                isLoading={loadingPatchIntervention}
                onRemoveIntervention={onRemoveIntervention}
              />
            </DragDropContext>
          </div>
        </Collapse>

        {(!!interventionGroup.progress_overview?.implementation_checks.length ||
          interventionGroup.latest_activity) && (
          <>
            <div
              className="assigmentCollapseHeader"
              aria-controls={"avg-collapse"}
              aria-expanded={avgCollapsed}
              onClick={() => handleCollapse('avgFidelity')}
            >
             <div className="interventionAvg">
                <h5 className="section w-100">
                  {!isDistrictOwner ? `Average Fidelity & Latest Activity` : `Latest Activity` }
                </h5>
                <span className="pointer pr-1 interventionAvgArrow">
                  <FontAwesomeIcon
                    icon={avgCollapsed ? faCaretDown : faCaretLeft}
                    size="1x"
                  />
                </span>
              </div>
            </div>
            <Collapse in={avgCollapsed}>
              <div id="avg-collapse">  
                <div className="d-flex align-items-center justify-content-around">
                  {isStudentDetail ? 
                     (isUserAssignedToGroup ? <AverageFidelity
                      progressOverview={interventionGroup.progress_overview}
                      averageFidelity={interventionGroup.average_fidelity}
                    /> : '')
                   : 
                    <AverageFidelity
                      progressOverview={interventionGroup.progress_overview}
                      averageFidelity={interventionGroup.average_fidelity}
                    />
                  }
                  <LatestActivityComp
                    latestActivity={interventionGroup.latest_activity}
                    isStudentDetail={isStudentDetail}
                    isUserAssignedToGroup={isUserAssignedToGroup}
                  />
                </div>
              </div>
            </Collapse>
          </>
        )}
          <hr className="my-2" />
          <div className="fidelity-chart-area">{infoByStage}</div>
        <div>
          <hr className="my-2" />
          <div className="d-flex align-items-center justify-content-center">
            <button className="blueBtnSm" disabled={progressOverviewLoader} onClick={(e) => onViewInterventionPlan(e, false)}>
              Open Group Workspace
              {progressOverviewLoader 
                ? <Spinner animation="border" size="sm" className="ml-1" /> 
                : ''}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  onboarding, cases
}: ApplicationState): StateProps => {
  return {
    collapsedIntGroupInnerIds: onboarding.collapsedIntGroupInnerIds,
    isLoading: {
      loaderIntGroupBehavior: cases.isLoading.loaderIntGroupBehavior,
      loaderIntGroupBehaviorArchived: cases.isLoading.loaderIntGroupBehaviorArchived,
      loaderIntGroupReading: cases.isLoading.loaderIntGroupReading,
      loaderIntGroupReadingArchived: cases.isLoading.loaderIntGroupReadingArchived,
      loaderIntGroupStudentDetail: cases.isLoading.loaderIntGroupStudentDetail,
      loaderIntGroupStudentDetailArchived: cases.isLoading.loaderIntGroupStudentDetailArchived,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeIntGroupInnerCollapsedSettings: changeIntGroupInnerCollapsedSettings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InterventionGroupGridView);
