import { ActionType, getType } from "typesafe-actions";
import { Reducer } from "redux";
import * as onboardingActions from "./actions";
import {
  BasicUserInfo,
  DataPeriod,
  EvidenceColumnGroup,
  EvidencePeriodData,
  EvidenceType,
  OnboardingActionTypes,
  OnboardingState,
  Student,
  StudentClass,
  StudentsDisplaySettings,
  TeacherClass,
  TeacherInfo,
  TeacherStudentClass,
} from "./types";
import { IS_READY_COACH } from "../../constants";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../utils/LocalStorageUtils";
import { StaffRelationship } from "../auth/types";
import _ from "lodash";

export type OnboardingAction = ActionType<typeof onboardingActions>;

const initialState: OnboardingState = {
  districts: [],
  districtRelationships: [],
  interventionGroupLogging: [],
  teachersClasses: [],
  teachersStudents: [],
  isLoading: {
    checkEmailExist: false,
    resendInviteToTeachers: false,
    resendInviteToUsers: false,
    sendInviteToTeachers: false,
    getTeachers: false,
    getStudents: false,
    addStudents: false,
    updateTeacherInfo: false,
    assignClassToTeacher: false,
    getClassesAssignedToTeacher: false,
    assignStudentsToTeacher: [],
    getStudentsAssignedToTeacher: false,
    updateTeacherClass: false,
    updateStudentDetails: false,
    getSchoolsList: false,
    getParentsList: false,
    addSchool: false,
    updateSchool: false,
    deleteSchool: false,
    mergeTeachers: false,
    mergeSchools: false,
    getReminders: false,
    updateReminder: false,
    deleteReminder: false,
    scheduleReminder: false,
    createAssessment: false,
    detectAssessment: false,
    uploadEvidenceData: false,
    uploadUndefinedAssessmentData: false,
    uploadAssessmentData: false,
    deleteEvidenceColumnsGroup: false,
    getEvidenceInDatePeriod: false,
    getEvidenceAssessments: false,
    addStudentsToDataPeriod: false,
    addAssessmentToDataPeriod: false,
    setValueOfDSEtoDataPeriod: false,
    updateValueOfDSEtoDataPeriod: false,
    setDiagnosticFollowUptoDataPeriod: false,
    deleteDiagnosticFollowUptoDataPeriod: false,
    detectNonExistingStudentIds: false,
    getUpdatedAreaOfNeed: false,
    getDataPeriods: false,
    addDataPeriod: false,
    updateDataPeriod: false,
    deleteDataPeriod: false,
    getCustomColorCriteria: false,
    updateCustomColorCriteria: false,
    restoreDefaultBenchmarks: false,
    getStaticDataCategoryDomains: false,
    addCategoryBehavior: false,
    addTargetBehaviorToDataPeriod: false,
    changeStudentDSEGrade: false,
    addStudentSkills: false,
    deleteDataStudentEntry: false,
    getDataSharingTableData: false,
    updateDataSharingTableData: false,
    deleteDataSharingTableData: false,
    shareCoachTeacherRelationData: false,
    sendManagerKeyRequest: false,
    getInterviews: false,
    getInterviewsStudents: false,
    createInterview: false,
    createInterviewStudent: false,
    addStudentBehaviorsToDataPeriod: false,
    getDefaultProgressMonitoring: false,
    addUserToDistrict: false,
    addEducatorsToSupervisors: false,
    getDistrictRelationships: false,
    getInterventionGroupLogging: false,
    addTeachersToCoaches: false,
    addExistingBehaviorEcgToDataPeriod: false,
    addBehaviorScreenerEcgToDataPeriod: false,
    addDistrictAssessment: false,
    updateDistrictAssessment: false,
    assignTeachersToStudent: false,
    getClassesLinkedToStudent: false,
    getAllClasses: false,
    updateClassesLinkedToStudent: false,
    getDistrictSummary: false,
    getTutorialVideos: false,
    isLoadingIndicator: false,
    getDataPeriodSchools: false,
    getRaceInfo: false,
    getMasterRaceInfo: false,
    getInterventionsOverview: false,
    getCaseLoadUsers: false,
  },
  modalsState: {
    uploadTeachersRosterModal: false,
    teachersIndividuallyInviteModal: false,
    uploadStudentsRosterModal: false,
    schoolModal: false,
    mergeSchoolsModal: false,
    createClassModal: false,
    uploadStudentsToTeachersModal: false,
    teacherDetailModal: false,
    assignEducatorsToEmployee: false,
    studentsIndividuallyInviteModal: false,
    editStudentForm: false,
    showFailedInvitationsAlert: false,
    scheduleReminderModal: false,
    editReminderModal: false,
    mergeTeachersModal: false,
    saveKeyModal: false,
    shareDataModal: false,
    coachTeacherRelationModal: false,
    loadKeyModal: false,
    editMeasureColorCriteriaModal: false,
    viewBenchmarksModal: false,
    pickDataSourceModal: false,
    confirmRecommendModal: false,
    confirmSecondaryGoalCreateModal: false,
    hasBehavior: false,
    hasAcademic: false,
    uploadStudentDataModal: false,
    enterDataManuallyModal: false,
    searchForYourAssessmentModal: false,
    createAssessmentModal: false,
    conductInterviewModal: false,
    dataSharingEditModal: false,
    generatePDFModal: false,
    inviteTeachersModal: false,
    upsertSchoolModal: false,
    manageDataPeriodsModal: false,
    inviteParentModal: false,
    isNewProgressMonitoring: false,
    isRedirectToSetGoal: false,
  },
  errors: {
    assignStudentsToTeacher: [],
  },
  teachersRoster: [],
  teachersRosterWithDummyUser: [],
  studentsByInterventionGroupRoster: [],
  coachesRoster: [],
  coachesRosterWithDummyUser: [],
  studentsRoster: [],
  schools: [],
  parents: [],
  reminders: [],
  invites: {
    successful_invites: [],
    failed_invites: [],
  },
  assessments: [],
  evidenceAssessments: [],
  nonExistingStudentIds: [],
  dataPeriods: [],
  measurementCustomColorCriteria: [],
  staticDataCategoryDomains: [],
  customBehaviors: [],
  dataSharingTableData: [],
  interviews: [],
  interviewsStudents: [],
  allClasses: [],
  classesLinkedToStudent: [],
  studentsFilter: "",
  teachersFilter: "",
  messageParts: [],
  studentsDisplaySettings: StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS,
  tutorialVideos: [],
  collapsedIntGroupAssignmentsIds: JSON.parse(
    localStorage.getItem(LocalStorageKeys.CollapsedAssignments) || "[]"
  ),
  collapsedIntGroupInnerIds: JSON.parse(
    localStorage.getItem(LocalStorageKeys.CollapsedInnerAssignments) || "{}"
  ),

  districtSummaryOptions: {
    collapsedAreas: JSON.parse(
      localStorage.getItem(LocalStorageKeys.DistrictSummaryCollapsedAreas) ||
        "null"
    ) || {
      "assignments-in-district": true,
      "goals-in-district": true,
      "interventions-in-district": true,
      "interventions-stats": true,
      "goal-progress-in-district": true,
      "decisions-in-district": true,
      "goal-attainment-in-district": true,
      "intervention-fidelity-in-district": true,
      "fidelity-by-teacher-in-district": true,
      "fidelity-by-intervention-in-district": true,
      "group-progress-in-district": true,
      "alerts-per-coach-in-district": true,
    },
    displayCharts: getBooleanFromLocalStorage(
      LocalStorageKeys.DistrictSummaryDisplayGraphs,
      true
    ),
  },
  districtAccessDataSettings: [],

  uploadedAssessment: {},
  isUploadedAssessment: false,
  teacherClassStudent: {},
  selectedUsers:[],
  callFrom: '',
  isUrlBlocked: false,
  timeElipse: false,
  finishedInterventionGroup: undefined,
  dataPeriodSchools: [],
  raceInfo: {
    races: [],
    race_other_values: []
  },
  masterRaceInfo: {
    races: []
  },
  isStudentRosterUploaded: false,
  existingEmails: {
    emails_already_exist: [],
    new_emails: []
  },
  interventionsOverview: {
    interventions: [],
    other_common_stats: []
  },
  caseLoadUsers: [],
};

const reducer: Reducer<OnboardingState> = (
  state: OnboardingState = initialState,
  action: OnboardingAction
): OnboardingState => {
  switch (action.type) {
    case OnboardingActionTypes.UPLOAD_CSV_FILE:
      return {
        ...state,
        loadableFile: {
          originalFileName: action.payload.fileName,
          columnsNames: action.payload.columnsNames,
          rawData: action.payload.rawData,
        },
      };
    case OnboardingActionTypes.UPLOAD_MULTI_CSV_FILE: {
      let fileName:string = '';
      let rawData:any = [];
      let columnsNames:any = [];
      for (const key in action.payload.uploadCSVArray) {
        fileName = action.payload.uploadCSVArray[key].fileName;
        rawData = action.payload.uploadCSVArray[key].rawData;
        columnsNames = action.payload.uploadCSVArray[key].headers;
      }
      return {
        ...state,
        loadableFile: {
          ...state.loadableFile!,
          originalFileName: fileName,
          rawData: rawData,
          columnsNames: columnsNames,
          uploadCSVArray: action.payload.uploadCSVArray,
        },  
      };
    }
    case OnboardingActionTypes.CLEAR_STORED_CSV_FILE:
      return { ...state, loadableFile: undefined };
    case OnboardingActionTypes.UPDATE_CSV_FILE_RAW_DATA:
      return {
        ...state,
        loadableFile: {
          ...state.loadableFile!,
          rawData: action.payload.rawData,
        },
      };

    case OnboardingActionTypes.SELECTED_TEACHER_CHANGED:
      return { ...state, selectedTeacher: action.payload.selectedTeacher };
    case OnboardingActionTypes.SELECTED_STUDENT_CHANGED:
      return { ...state, selectedStudent: action.payload.selectedStudent };
    case OnboardingActionTypes.SELECTED_CLASS_CHANGED:
      return { ...state, selectedClass: action.payload.selectedClass };
    case OnboardingActionTypes.SELECTED_REMINDER_CHANGED:
      return { ...state, selectedReminder: action.payload.selectedReminder };

    case OnboardingActionTypes.OPEN_UPLOAD_TEACHERS_ROSTER_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, uploadTeachersRosterModal: true },
        loadableFile: undefined,
      };
    case OnboardingActionTypes.HIDE_UPLOAD_TEACHERS_ROSTER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          uploadTeachersRosterModal: false,
          showFailedInvitationsAlert: false,
        },
      };

    case OnboardingActionTypes.OPEN_TEACHERS_INVITE_INDIVIDUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          teachersIndividuallyInviteModal: true,
          teachersIndividuallyInviteModalCallback: action.payload.callback,
        },
        newTeacherForCreatable: action.payload.newTeacher,
      };
    case OnboardingActionTypes.HIDE_TEACHERS_INVITE_INDIVIDUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          teachersIndividuallyInviteModal: false,
          teachersIndividuallyInviteModalCallback: undefined,
          showFailedInvitationsAlert: false,
        },
      };

    case OnboardingActionTypes.OPEN_UPLOAD_STUDENTS_ROSTER_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, uploadStudentsRosterModal: true },
        loadableFile: undefined,
      };
    case OnboardingActionTypes.HIDE_UPLOAD_STUDENTS_ROSTER_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, uploadStudentsRosterModal: false },
      };

    case OnboardingActionTypes.OPEN_SCHOOL_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, schoolModal: true },
        selectedSchool: action.payload.school,
      };
    case OnboardingActionTypes.HIDE_SCHOOL_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, schoolModal: false },
        selectedSchool: undefined,
      };

    case OnboardingActionTypes.OPEN_MERGE_SCHOOLS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, mergeSchoolsModal: true },
        selectedSchool: action.payload.school,
      };
    case OnboardingActionTypes.HIDE_MERGE_SCHOOLS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, mergeSchoolsModal: false },
        selectedSchool: undefined,
      };

    case getType(onboardingActions.changeSchoolsList):
      return {
        ...state,
        schools: action.payload,
      };

    case OnboardingActionTypes.OPEN_STUDENTS_INVITE_INDIVIDUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          studentsIndividuallyInviteModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_STUDENTS_INVITE_INDIVIDUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          studentsIndividuallyInviteModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_TEACHER_DETAIL_MODAL:
      return {
        ...state,
        selectedClass: undefined,
        selectedStudent: undefined,
        modalsState: {
          ...state.modalsState,
          teacherDetailModal: true,
          uploadStudentsToTeachersModal: false,
          createClassModal: false,
        },
      };
    case OnboardingActionTypes.HIDE_TEACHER_DETAIL_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          teacherDetailModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          assignEducatorsToEmployee: true,
        },
      };
    case OnboardingActionTypes.HIDE_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          assignEducatorsToEmployee: false,
        },
      };

    case OnboardingActionTypes.OPEN_UPLOAD_STUDENTS_TO_TEACHER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          uploadStudentsToTeachersModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_UPLOAD_STUDENTS_TO_TEACHER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          uploadStudentsToTeachersModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_SCHEDULE_REMINDER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          scheduleReminderModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_SCHEDULE_REMINDER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          scheduleReminderModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_EDIT_REMINDER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editReminderModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_EDIT_REMINDER_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editReminderModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_MERGE_TEACHERS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          mergeTeachersModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_MERGE_TEACHERS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          mergeTeachersModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_EDIT_STUDENT_FORM:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editStudentForm: true,
        },
      };
    case OnboardingActionTypes.HIDE_EDIT_STUDENT_FORM:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editStudentForm: false,
        },
      };

    case OnboardingActionTypes.OPEN_CREATE_CLASS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createClassModal: true,
          teacherDetailModal: false,
        },
      };
    case OnboardingActionTypes.HIDE_CREATE_CLASS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createClassModal: false,
        },
      };

    case OnboardingActionTypes.HIDE_FAILED_INVITATIONS_ALERT:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showFailedInvitationsAlert: false,
        }
      };

    case OnboardingActionTypes.HIDE_CHECK_EMAIL_EXIST_ALERT:
      return {
        ...state,
        existingEmails: { 
          emails_already_exist: [],
          new_emails: []
        }
      };

    case OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendInviteToTeachers: true },
      };
    case OnboardingActionTypes.RESEND_INVITE_TO_TEACHERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, resendInviteToTeachers: true },
      };
    case OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendInviteToTeachers: false, resendInviteToTeachers: false },
        errors: { ...state.errors, sendInviteToTeachers: undefined, resendInviteToTeachers: undefined },
        invites: action.payload.invitesResponse,
        modalsState: {
          ...state.modalsState,
          showFailedInvitationsAlert:
            action.payload.invitesResponse.failed_invites.length > 0,
        },
      };
    case OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendInviteToTeachers: false,  resendInviteToTeachers: false },
        errors: { ...state.errors, sendInviteToTeachers: action.payload.error, resendInviteToTeachers: action.payload.error },
      };

    case OnboardingActionTypes.GET_REMINDERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReminders: true },
      };
    case OnboardingActionTypes.GET_REMINDERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReminders: false },
        errors: { ...state.errors, getReminders: undefined },
        reminders: action.payload.reminders,
      };
    case OnboardingActionTypes.GET_REMINDERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReminders: false },
        errors: { ...state.errors, getReminders: action.payload.error },
      };

    case OnboardingActionTypes.UPDATE_REMINDER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateReminder: true },
      };
    case OnboardingActionTypes.UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateReminder: false },
        errors: { ...state.errors, updateReminder: undefined },
        reminders: state.reminders.map((reminder) =>
          reminder.id === action.payload.reminder.id
            ? action.payload.reminder
            : reminder
        ),
      };
    case OnboardingActionTypes.UPDATE_REMINDER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateReminder: false },
        errors: { ...state.errors, updateReminder: action.payload.error },
      };

    case OnboardingActionTypes.DELETE_REMINDER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteReminder: true },
      };
    case OnboardingActionTypes.DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteReminder: false },
        errors: { ...state.errors, deleteReminder: undefined },
        reminders: state.reminders.filter(
          (reminder) => reminder.id !== action.payload.reminderId
        ),
      };
    case OnboardingActionTypes.DELETE_REMINDER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteReminder: false },
        errors: { ...state.errors, deleteReminder: action.payload.error },
      };

    case OnboardingActionTypes.SCHEDULE_REMINDER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, scheduleReminder: true },
      };
    case OnboardingActionTypes.SCHEDULE_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, scheduleReminder: false },
        errors: { ...state.errors, scheduleReminder: undefined },
        reminders: [action.payload.reminder, ...state.reminders],
      };
    case OnboardingActionTypes.SCHEDULE_REMINDER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, scheduleReminder: false },
        errors: { ...state.errors, scheduleReminder: action.payload.error },
      };

    case OnboardingActionTypes.MERGE_TEACHERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeTeachers: true },
      };
    case OnboardingActionTypes.MERGE_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeTeachers: false },
        errors: { ...state.errors, mergeTeachers: undefined },
        teachersRoster: state.teachersRoster
          .filter((t) => t.user.id !== action.payload.mergedTeacherId)
          .map((t) =>
            t.user.id === action.payload.keptTeacher.user.id
              ? action.payload.keptTeacher
              : t
          ),
      };
    case OnboardingActionTypes.MERGE_TEACHERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeTeachers: false },
        errors: { ...state.errors, mergeTeachers: action.payload.error },
      };

    case OnboardingActionTypes.MERGE_SCHOOLS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeSchools: true },
      };
    case OnboardingActionTypes.MERGE_SCHOOLS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeSchools: false },
        errors: { ...state.errors, mergeSchools: undefined },
        schools: state.schools
          .filter((school) => school.id !== action.payload.mergedSchoolId)
          .map((school) =>
            school.id === action.payload.keptSchool.id
              ? action.payload.keptSchool
              : school
          ),
      };
    case OnboardingActionTypes.MERGE_SCHOOLS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, mergeSchools: false },
        errors: { ...state.errors, mergeSchools: action.payload.error },
      };

    case OnboardingActionTypes.GET_TEACHERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getTeachers: true },
      };
    case OnboardingActionTypes.GET_TEACHERS_SUCCESS: {
      let teachersRoster = _.cloneDeep(action.payload.teachersRoster);
      let teachersRosterWithDummyUser = _.cloneDeep(action.payload.teachersRoster);
      return {
        ...state,
        isLoading: { ...state.isLoading, getTeachers: false },
        errors: { ...state.errors, getTeachers: undefined },
        teachersRoster: teachersRoster.filter((teacherData) => !teacherData.user.email?.includes('no_coach') && !teacherData.user.email?.includes('self_interventionist')),
        teachersRosterWithDummyUser: teachersRosterWithDummyUser
      };
    }
    case OnboardingActionTypes.GET_TEACHERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getTeachers: false },
        errors: { ...state.errors, getTeachers: action.payload.error },
      };

    case OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: { ...state.isLoading },
      };
    case OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors},
        studentsByInterventionGroupRoster: action.payload.studentsByInterventionGroupRoster,
      };
    case OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading},
        errors: { ...state.errors},
      };

    case getType(onboardingActions.getCoachesAction.request): {
      return {
        ...state,
        isLoading: { ...state.isLoading, getCoaches: false },
        errors: { ...state.errors, getCoaches: undefined },
      };
    }
    case getType(onboardingActions.getCoachesAction.success): {
      let coachesRoster = _.cloneDeep(action.payload);
      let coachesRosterWithDummyUser = _.cloneDeep(action.payload);
      return {
        ...state,
        isLoading: { ...state.isLoading, getCoaches: false },
        errors: { ...state.errors, getCoaches: undefined },
        coachesRoster: coachesRoster.filter((coachData) => !coachData.user.email?.includes('no_coach') && !coachData.user.email?.includes('self_interventionist')),
        coachesRosterWithDummyUser: coachesRosterWithDummyUser,
        // teachersRoster: action.payload.teachersRoster,
      };
    }
    case getType(onboardingActions.getCoachesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, getCoaches: false },
        errors: { ...state.errors, getCoaches: action.payload },
      };

    case OnboardingActionTypes.GET_STUDENTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudents: true },
      };
    case OnboardingActionTypes.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudents: false },
        errors: { ...state.errors, getStudents: undefined },
        studentsRoster: action.payload.studentsRoster,
      };
    case OnboardingActionTypes.GET_STUDENTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudents: false },
        errors: { ...state.errors, getStudents: action.payload.error },
      };

    case getType(onboardingActions.uploadStudentDemographicsActions.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadStudentDemographics: true },
        errors: { ...state.errors, uploadStudentDemographics: undefined },
      };
    case getType(onboardingActions.uploadStudentDemographicsActions.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadStudentDemographics: false },
        studentsRoster: state.studentsRoster.map((sInfo) => {
          const updatedStudent: any = action.payload.find(
            (s) => s.id === sInfo.id
          );
          return updatedStudent ? { ...sInfo, ...updatedStudent } : sInfo;
        }),
      };
    case getType(onboardingActions.uploadStudentDemographicsActions.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadStudentDemographics: false },
        errors: { ...state.errors, uploadStudentDemographics: action.payload },
      };

    case OnboardingActionTypes.ADD_STUDENTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudents: true },
      };
    case OnboardingActionTypes.ADD_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudents: false },
        errors: { ...state.errors, addStudents: undefined },
        isStudentRosterUploaded: action.payload.isStudentRosterUploaded
      };
    case OnboardingActionTypes.RESET_STUDENT_ROSTER_UPLOAD:
      return {
        ...state,
        isStudentRosterUploaded: false
      }
    case OnboardingActionTypes.ADD_STUDENTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudents: false },
        errors: { ...state.errors, addStudents: action.payload.error },
      };

    case OnboardingActionTypes.UPDATE_TEACHER_INFO_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherInfo: true },
      };
    case OnboardingActionTypes.UPDATE_TEACHER_INFO_SUCCESS: {
      state.teachersRoster = [...state.teachersRoster.map((teacher)=> {
        if(teacher.user.id == action.payload.id) {
          teacher.user.email = action.payload.updatedInfo.email;
          teacher.user.first_name = action.payload.updatedInfo.first_name;
          teacher.user.last_name = action.payload.updatedInfo.last_name;
        }
        return teacher;
      })]

      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherInfo: false },
        errors: { ...state.errors, updateTeacherInfo: undefined },
      };
    }
    case OnboardingActionTypes.UPDATE_TEACHER_INFO_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherInfo: false },
        errors: { ...state.errors, updateTeacherInfo: action.payload.error },
      };

    case OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, assignClassToTeacher: true },
      };
    case OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_SUCCESS: {
      const teacher = state.teachersRoster.find(
        (t) => t.user.id === action.payload.id
      );

      const newClass: TeacherStudentClass | undefined = teacher
        ? {
            teacher: teacher.user,
            class: action.payload.teacherClass as StudentClass, // todo
          }
        : undefined;

      return {
        ...state,
        allClasses: newClass
          ? [...state.allClasses, newClass]
          : state.allClasses,
        isLoading: { ...state.isLoading, assignClassToTeacher: false },
        errors: {
          ...state.errors,
          updateTeacherClass: undefined,
          assignClassToTeacher: undefined,
        },
      };
    }
    case OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, assignClassToTeacher: false },
        errors: { ...state.errors, assignClassToTeacher: action.payload.error },
      };

    case getType(onboardingActions.reassignClassToTeacherAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, reassignClassToTeacher: true },
        errors: {
          ...state.errors,
          reassignClassToTeacher: undefined,
        },
      };
    case getType(onboardingActions.reassignClassToTeacherAction.success): {
      return {
        ...state,
        allClasses: state.allClasses.map((c) =>
          c.class.id === action.payload.classId
            ? { ...c, teacher: action.payload.teacher }
            : c
        ),
        isLoading: { ...state.isLoading, reassignClassToTeacher: false },
      };
    }
    case getType(onboardingActions.reassignClassToTeacherAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, reassignClassToTeacher: false },
        errors: { ...state.errors, reassignClassToTeacher: action.payload },
      };

    case OnboardingActionTypes.UPDATE_TEACHER_CLASS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherClass: true },
      };
    case OnboardingActionTypes.UPDATE_TEACHER_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherClass: false },
        errors: {
          ...state.errors,
          updateTeacherClass: undefined,
          assignClassToTeacher: undefined,
        },
      };
    case OnboardingActionTypes.UPDATE_TEACHER_CLASS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateTeacherClass: false },
        errors: { ...state.errors, updateTeacherClass: action.payload.error },
      };

    case OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getClassesAssignedToTeacher: true },
        teachersClasses: [],
      };
    case OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getClassesAssignedToTeacher: false },
        errors: { ...state.errors, getClassesAssignedToTeacher: undefined },
        teachersClasses: action.payload.teachersClasses,
      };
    case OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getClassesAssignedToTeacher: false },
        errors: {
          ...state.errors,
          getClassesAssignedToTeacher: action.payload.error,
        },
        teachersClasses: [],
      };

    case OnboardingActionTypes.CLEAR_CLASSES_ASSIGNED_TO_TEACHER:
      return {
        ...state,
        teachersClasses: [],
      };

    case OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          assignStudentsToTeacher: [
            ...state.isLoading.assignStudentsToTeacher,
            action.payload.teacherId,
          ],
        },
      };
    case OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_SUCCESS: 
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          assignStudentsToTeacher: state.isLoading.assignStudentsToTeacher.filter(
            (id) => id !== action.payload.teacherId
          ),
        },
        errors: {
          ...state.errors,
          assignStudentsToTeacher: state.errors.assignStudentsToTeacher.filter(
            (x) => x.teacherId !== action.payload.teacherId
          ),
        },
        studentsRoster: action.payload.isNewStudentAdd ? [
          ...state.studentsRoster.map((sr) => {
            const updatedStudent = action.payload.students.find(
              (s) => s.id === sr.id
            );
            if (updatedStudent && action.payload.teacherInfo) {
              return {
                ...sr,
                teachers: sr.teachers.some(
                  (t) => t.id === action.payload.teacherId
                )
                  ? sr.teachers
                  : [...sr.teachers, action.payload.teacherInfo],
              };
            }
            return sr;
          })
          , 
          ...action.payload.students.map( 
            (s) =>
              ({
                ...s,
                teachers: [action.payload.teacherInfo],
              } as any)
          ),
        ] : [
          ...state.studentsRoster.map((sr) => {
            const updatedStudent = action.payload.students.find(
              (s) => s.id === sr.id
            );
            if (updatedStudent && action.payload.teacherInfo) {
              return {
                ...sr,
                teachers: sr.teachers.some(
                  (t) => t.id === action.payload.teacherId
                )
                  ? sr.teachers
                  : [...sr.teachers, action.payload.teacherInfo],
              };
            }
            return sr;
          })],
        teachersStudents: state.selectedTeacher
          ? [
              ...state.teachersStudents,
              ...action.payload.students.map((student) => {
                return {
                  id: student.id,
                  first_name: student.first_name,
                  last_name: student.last_name,
                  unique_id: student.unique_id,
                  grade: student.grade,
                  focuses: student.focuses,
                  teachers: [
                    {
                      id: state.selectedTeacher!.user.id,
                      first_name: state.selectedTeacher!.user.first_name,
                      last_name: state.selectedTeacher!.user.last_name,
                      email: state.selectedTeacher!.user.email,
                    },
                  ],
                };
              }),
            ]
          : [],
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              student_rows: [
                ...state.evidencePeriodData.student_rows.map((studentRow) => {
                  if (action.payload.students.some((student) => student.id == studentRow.student.id)) {
                    studentRow?.student?.teachers!.push({
                      id: action.payload.teacherInfo?.id,
                      first_name:  action.payload.teacherInfo?.first_name!,
                      last_name:  action.payload.teacherInfo?.last_name!,
                      email:  action.payload.teacherInfo?.email!,
                      classes: null,
                      schools:  action.payload.teacherInfo?.profile.current_assignment?.schools?.map((school) => school.id)
                    })
                  }
                  return studentRow;
                }),
              ],
            }
          : undefined
    };
    case OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          assignStudentsToTeacher: state.isLoading.assignStudentsToTeacher.filter(
            (id) => id !== action.payload.teacherId
          ),
        },
        errors: {
          ...state.errors,
          assignStudentsToTeacher: [
            ...state.errors.assignStudentsToTeacher,
            {
              teacherId: action.payload.teacherId,
              message: action.payload.error,
            },
          ],
        },
      };

    case OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudentsAssignedToTeacher: true },
        teachersStudents: [],
      };
    case OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudentsAssignedToTeacher: false },
        errors: { ...state.errors, getStudentsAssignedToTeacher: undefined },
        teachersStudents: action.payload.teachersStudents,
      };
    case OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStudentsAssignedToTeacher: false },
        errors: {
          ...state.errors,
          getStudentsAssignedToTeacher: action.payload.error,
        },
        teachersStudents: [],
      };

    case OnboardingActionTypes.UPDATE_STUDENT_DETAILS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateStudentDetails: true },
      };
    case OnboardingActionTypes.UPDATE_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateStudentDetails: false },
        errors: { ...state.errors, updateStudentDetails: undefined },
        studentsRoster: state.studentsRoster.map((s) =>
          s.id === action.payload.id
            ? { ...s, ...(action.payload.student as any) }
            : s
        ),
        teachersStudents: [
          ...state.teachersStudents.map((teacherStudent) => {
            if (teacherStudent.id === action.payload.id) {
              return {
                id: teacherStudent.id,
                first_name: action.payload.student.first_name,
                last_name: action.payload.student.last_name,
                unique_id: action.payload.student.unique_id,
                grade: teacherStudent.grade,
                focuses: teacherStudent.focuses,
                teachers: teacherStudent.teachers,
              };
            } else {
              return teacherStudent;
            }
          }),
        ],
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              student_rows: [
                ...state.evidencePeriodData.student_rows.map((studentRow) => {
                  if (studentRow.student.id === action.payload.id) {
                    return {
                      id: studentRow.id,
                      data_period: studentRow.data_period,
                      grade: action.payload.studentGrade,
                      student: {
                        ...studentRow.student,
                        ...action.payload.student
                      },
                    };
                  } else {
                    return studentRow;
                  }
                }),
              ],
            }
          : undefined,
      };
    case OnboardingActionTypes.UPDATE_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateStudentDetails: false },
        errors: {
          ...state.errors,
          updateStudentDetails: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_SCHOOLS_LIST_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getSchoolsList: true },
      };
    case OnboardingActionTypes.GET_SCHOOLS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getSchoolsList: false },
        errors: { ...state.errors, getSchoolsList: undefined },
        schools: action.payload.schools,
      };
    case OnboardingActionTypes.GET_SCHOOLS_LIST_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getSchoolsList: false },
        errors: {
          ...state.errors,
          getSchoolsList: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriodSchools: true },
      };
    case OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriodSchools: false },
        errors: { ...state.errors, getDataPeriodSchools: undefined },
        dataPeriodSchools: action.payload.dataPeriodSchools,
      };
    case OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriodSchools: false },
        errors: {
          ...state.errors,
          getSchoolsList: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_PARENTS_LIST_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getParentsList: true },
      };
    case OnboardingActionTypes.GET_PARENTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getParentsList: false },
        errors: { ...state.errors, getParentsList: undefined },
        parents: action.payload.parents,
      };
    case OnboardingActionTypes.GET_PARENTS_LIST_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getParentsList: false },
        errors: {
          ...state.errors,
          getParentsList: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_SCHOOL_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addSchool: true },
      };
    case OnboardingActionTypes.ADD_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addSchool: false },
        errors: { ...state.errors, addSchool: undefined },
        schools: [...state.schools, action.payload.school],
      };
    case OnboardingActionTypes.ADD_SCHOOL_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addSchool: false },
        errors: {
          ...state.errors,
          addSchool: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_SCHOOL_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateSchool: true },
      };
    case OnboardingActionTypes.UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateSchool: false },
        errors: { ...state.errors, updateSchool: undefined },
        schools: [
          ...state.schools.map((school) => {
            if (school.id === action.payload.school.id) {
              return { ...school, name: action.payload.school.name };
            } else {
              return school;
            }
          }),
        ],
      };
    case OnboardingActionTypes.UPDATE_SCHOOL_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateSchool: false },
        errors: {
          ...state.errors,
          updateSchool: action.payload.error,
        },
      };

    case OnboardingActionTypes.DELETE_SCHOOL_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteSchool: true },
      };
    case OnboardingActionTypes.DELETE_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteSchool: false },
        schools: state.schools.filter(
          (school) => school.id !== action.payload.schoolId
        ),
      };
    case OnboardingActionTypes.DELETE_SCHOOL_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteSchool: false },
      };

    case OnboardingActionTypes.CHANGE_STUDENTS_FILTER:
      return {
        ...state,
        studentsFilter: action.payload.studentFilter,
      };

    case OnboardingActionTypes.SEND_DATA_FILTER_MESSAGE:
      return {
        ...state,
        messageParts: action.payload.messageParts,
      };
    case OnboardingActionTypes.CHANGE_TEACHER_SEARCH_FILTER:
      return {
        ...state,
        teachersFilter: action.payload.teachersFilter,
      };
    case OnboardingActionTypes.CHANGE_STUDENTS_DISPLAY_SETTINGS:
      return {
        ...state,
        studentsDisplaySettings: action.payload.studentsDisplaySettings,
      };

    case OnboardingActionTypes.UPDATE_SELECTED_USER_DATA:
      return {
        ...state,
        selectedUsers: action.payload.selectedUsers.map((data:any)=> data.id),
      };

    case OnboardingActionTypes.CLEAR_NON_EXISTING_STUDENTS:
      return {
        ...state,
        nonExistingStudentIds: [],
      };

    case OnboardingActionTypes.OPEN_SAVE_KEY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          saveKeyModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_SAVE_KEY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          saveKeyModal: false,
        },
      };
    case OnboardingActionTypes.OPEN_LOAD_KEY_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, loadKeyModal: true },
      };
    case OnboardingActionTypes.HIDE_LOAD_KEY_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, loadKeyModal: false },
      };

    case OnboardingActionTypes.OPEN_CONFIRM_RECOMMEND_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, confirmRecommendModal: true, hasAcademic: action.payload.hasAcademic! ,hasBehavior: action.payload.hasBehavior! },
      };
    case OnboardingActionTypes.HIDE_CONFIRM_RECOMMEND_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, confirmRecommendModal: false, hasAcademic: false ,hasBehavior: false },
      };

    case OnboardingActionTypes.OPEN_CONFIRM_SECONDARY_GOAL_CREATE_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, confirmSecondaryGoalCreateModal: true},
        finishedInterventionGroup: action.payload.group
      };
    case OnboardingActionTypes.HIDE_CONFIRM_SECONDARY_GOAL_CREATE_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, confirmSecondaryGoalCreateModal: false},
      };

    case OnboardingActionTypes.OPEN_VIEW_BENCHMARKS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, viewBenchmarksModal: true },
      };
    case OnboardingActionTypes.HIDE_VIEW_BENCHMARKS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, viewBenchmarksModal: false },
      };

    case OnboardingActionTypes.OPEN_EDIT_MEASURE_COLOR_CRITERIA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          viewBenchmarksModal: false,
          editMeasureColorCriteriaModal: true,
        },
      };
    case OnboardingActionTypes.HIDE_EDIT_MEASURE_COLOR_CRITERIA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          viewBenchmarksModal: true,
          editMeasureColorCriteriaModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_PICK_DATA_SOURCE_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, pickDataSourceModal: true },
      };
    case OnboardingActionTypes.HIDE_PICK_DATA_SOURCE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          pickDataSourceModal: false,
          uploadStudentDataModal: false,
          enterDataManuallyModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_UPLOAD_STUDENT_DATA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          uploadStudentDataModal: true,
          pickDataSourceModal: false,
        },
      };
    case OnboardingActionTypes.HIDE_UPLOAD_STUDENT_DATA_MODAL:
      return {
        ...state,
        nonExistingStudentIds: [],
        modalsState: {
          ...state.modalsState,
          uploadStudentDataModal: false,
          pickDataSourceModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_ENTER_DATA_MANUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          enterDataManuallyModal: true,
          enterDataManuallyType: action.payload,
          pickDataSourceModal: false,
        },
      };
    case OnboardingActionTypes.HIDE_ENTER_DATA_MANUALLY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          enterDataManuallyModal: false,
          enterDataManuallyType: undefined,
          pickDataSourceModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_SEARCH_FOR_YOUR_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          searchForYourAssessmentModal: true,
          uploadStudentDataModal: false,
        },
      };
    case OnboardingActionTypes.HIDE_SEARCH_FOR_YOUR_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          searchForYourAssessmentModal: false,
        },
      };

    case OnboardingActionTypes.OPEN_ADD_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createAssessmentModal: true,
          enterDataManuallyModal: false,
          isNewProgressMonitoring: 
              action.payload.isNewProgressMonitoring 
              ? true : state.modalsState.isNewProgressMonitoring,
        },
      };
    case OnboardingActionTypes.HIDE_ADD_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createAssessmentModal: false,
          enterDataManuallyModal: state.modalsState.isNewProgressMonitoring ?  false : true,
        },
      };
    case OnboardingActionTypes.HIDE_ADD_PROGRESS_MONITOR_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createAssessmentModal: false,
          enterDataManuallyModal: false ,
          isNewProgressMonitoring: false,
          isRedirectToSetGoal: false
        },
      };
    case OnboardingActionTypes.RESET_REDIRECT_TO_SET_GOAL: 
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          enterDataManuallyModal: false ,
          isRedirectToSetGoal: false
        },
      };
    case OnboardingActionTypes.OPEN_CONDUCT_INTERVIEW_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          conductInterviewModal: true,
        },
        selectedInterview: action.payload.selectedInterview,
      };
    case OnboardingActionTypes.HIDE_CONDUCT_INTERVIEW_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          conductInterviewModal: false,
        },
        selectedInterview: undefined,
      };

    case OnboardingActionTypes.DETECT_ASSESSMENT_START:
      return {
        ...state,
        errors: { ...state.errors, detectAssessment: undefined },
        isLoading: { ...state.isLoading, detectAssessment: true },
      };
    case OnboardingActionTypes.DETECT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, detectAssessment: false },
        assessments: action.payload.assessmentResponse.assessments,
      };
    case OnboardingActionTypes.DETECT_ASSESSMENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, detectAssessment: false },
        errors: { ...state.errors, detectAssessment: action.payload.error },
      };

    case OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_START:
      return {
        ...state,
        errors: { ...state.errors, uploadEvidenceData: undefined },
        isLoading: { ...state.isLoading, uploadEvidenceData: true },
      };
    case OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadEvidenceData: false }
      };
    case OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadEvidenceData: false },
        errors: { ...state.errors, uploadEvidenceData: action.payload.error },
      };

    case OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_START:
      return {
        ...state,
        errors: { ...state.errors, uploadAssessmentData: undefined },
        isLoading: { ...state.isLoading, uploadAssessmentData: true },
      };
    case OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadAssessmentData: false },
        uploadedAssessment: action.payload.data,
        isUploadedAssessment: true
      };
    case OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadAssessmentData: false },
        errors: { ...state.errors, uploadAssessmentData: action.payload.error },
      };

    case OnboardingActionTypes.RESET_IS_NEW_UPLOAD_ASSESSMENT:
      return {
        ...state,
        isUploadedAssessment: false
      };

    case OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_START:
      return {
        ...state,
        errors: { ...state.errors, uploadAssessmentData: undefined },
        isLoading: { ...state.isLoading, uploadUndefinedAssessmentData: true },
      };
    case OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_SET_STUDENT_ID:
      return {
        ...state,
        loadableFile: {
          ...state.loadableFile!,
          generatedStudentIdColumnName: action.payload.column,
        },
      };
    case OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadUndefinedAssessmentData: false },
      };
    case OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadUndefinedAssessmentData: false },
        errors: {
          ...state.errors,
          uploadUndefinedAssessmentData: action.payload.error,
        },
      };

    case OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteEvidenceColumnsGroup: true },
      };
    case OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_SUCCESS:{
      let filteredDataPeriod = [
        ...state.dataPeriods
            .map((dataPeriod) => {
              return {
                ...dataPeriod,
                evidence_column_groups: dataPeriod.evidence_column_groups?.filter((ecg) => ecg.id !== action.payload.evidenceColumnGroupId)
              }
            })
      ];

      const evidencePeriodData =  {
          ...state.evidencePeriodData,
          evidence_column_groups: [
            ...state.evidencePeriodData!.evidence_column_groups.filter((ecg) => ecg.id !== action.payload.evidenceColumnGroupId)
          ],
          student_rows: [...state.evidencePeriodData!.student_rows]
        }

      return {
        ...state,
        errors: { ...state.errors, deleteEvidenceColumnsGroup: undefined },
        isLoading: { ...state.isLoading, deleteEvidenceColumnsGroup: false },
        dataPeriods: filteredDataPeriod,
        evidencePeriodData: evidencePeriodData
      }
    }
    case OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteEvidenceColumnsGroup: false },
        errors: {
          ...state.errors,
          deleteEvidenceColumnsGroup: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: true },
        // evidencePeriodData: undefined
      };
    case OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_SUCCESS:
      return {
        ...state,
        evidencePeriodData: action.payload.evidencePeriodData,
        errors: { ...state.errors, getEvidenceInDatePeriod: undefined },
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: false },
      };
    case OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: false },
        errors: {
          ...state.errors,
          getEvidenceInDatePeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceAssessments: true },
      };
    case OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        evidenceAssessments: action.payload.evidenceAssessments,
        errors: { ...state.errors, getEvidenceAssessments: undefined },
        isLoading: { ...state.isLoading, getEvidenceAssessments: false },
      };
    case OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceAssessments: false },
        errors: {
          ...state.errors,
          getEvidenceAssessments: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudentsToDataPeriod: true },
      };
    case OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_SUCCESS: {
      let studentRows:any = [...state.evidencePeriodData!.student_rows];
      action.payload.studentRows.forEach((actionStdRow) => {
        if(!studentRows.some((sr:any) => actionStdRow.student.unique_id == sr.student.unique_id)){
            studentRows = [ actionStdRow, ...studentRows ]
          }
      })
      return {
        ...state,
        evidencePeriodData: {
          ...state.evidencePeriodData!,
          student_rows: studentRows,
        },
        errors: { ...state.errors, addStudentsToDataPeriod: undefined },
        isLoading: { ...state.isLoading, addStudentsToDataPeriod: false },
      };
    }
    case OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudentsToDataPeriod: false },
        errors: {
          ...state.errors,
          addStudentsToDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.CREATE_ASSESSMENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, createAssessment: true },
        modalsState: {
          ...state.modalsState, 
          enterDataManuallyModal: false,
          isRedirectToSetGoal: false
        },
      };
    case OnboardingActionTypes.CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, createAssessment: undefined },
        isLoading: { ...state.isLoading, createAssessment: false },
        modalsState: {
          ...state.modalsState, 
          enterDataManuallyModal: state.modalsState.isNewProgressMonitoring ?  false : true,
          isRedirectToSetGoal: true
        },
        evidenceAssessment: action.payload.assessment,
        evidenceAssessments: [...state.evidenceAssessments, action.payload.assessment!]
      };
    case OnboardingActionTypes.CREATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createAssessment: false },
        errors: {
          ...state.errors,
          createAssessment: action.payload.error,
        },
        modalsState: {
          ...state.modalsState, 
          enterDataManuallyModal: false,
          isRedirectToSetGoal: false
        },
      };

    case OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addAssessmentToDataPeriod: true },
      };
    case OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_SUCCESS: {
      const evidencePeriodData = state.evidencePeriodData
        ? {
            ...state.evidencePeriodData,
            evidence_column_groups: [
              ...state.evidencePeriodData.evidence_column_groups,
              ...action.payload.newEvidence.evidence_column_groups,
            ],
          }
        : {
            evidence_column_groups:
              action.payload.newEvidence.evidence_column_groups,
            student_rows: [],
          };

      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        errors: { ...state.errors, addAssessmentToDataPeriod: undefined },
        isLoading: { ...state.isLoading, addAssessmentToDataPeriod: false },
      };
    }
    case OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addAssessmentToDataPeriod: false },
        errors: {
          ...state.errors,
          addAssessmentToDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, setValueOfDSEtoDataPeriod: true },
      };
    case OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS: {
      const {
        data_student_entry,
        changed_groups,
        changed_columns,
        extra_info
      } = action.payload.dseAffectsResponse;

      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (evidenceColumnGroup) => {
            if (changed_groups) {
              const changedGroupIndex = changed_groups.findIndex(
                (eg) => eg.id === evidenceColumnGroup.id
              );
              if (changedGroupIndex >= 0) {
                return changed_groups[changedGroupIndex];
              } else {
                return evidenceColumnGroup;
              }
            }

            if (
              evidenceColumnGroup.id === action.payload.evidenceColumnGroupId
            ) {
              return {
                ...evidenceColumnGroup,
                evidence_columns: evidenceColumnGroup.evidence_columns.map(
                  (evidenceColumn) => {
                    if (changed_columns) {
                      const changedColumnIndex = changed_columns.findIndex(
                        (eg) => eg.id === evidenceColumnGroup.id
                      );
                      if (changedColumnIndex >= 0) {
                        return changed_columns[changedColumnIndex];
                      }
                    }

                    if (
                      evidenceColumn.id ===
                      data_student_entry.evidence_column.id
                    ) {
                      return {
                        ...evidenceColumn,
                        student_entries: [
                          ...evidenceColumn.student_entries,
                          {
                            student_id: data_student_entry.student.id!,
                            entry_id: data_student_entry.id,
                            value: data_student_entry.value,
                            grade: data_student_entry.grade,
                            ...(extra_info 
                              ? {extra_info: extra_info}
                              : ''
                            )
                          },
                        ],
                      };
                    }
                    return evidenceColumn;
                  }
                ),
              };
            }
            return evidenceColumnGroup;
          }
        ),
      };

      if (changed_groups) {
        const notFound = [];
        const existingGroups = new Set(
          evidencePeriodData.evidence_column_groups.map((g) => g.id)
        );

        for (const group of changed_groups) {
          if (!existingGroups.has(group.id)) {
            evidencePeriodData.evidence_column_groups.push(group);
          }
        }
      }
      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        errors: { ...state.errors, setValueOfDSEtoDataPeriod: undefined },
        isLoading: { ...state.isLoading, setValueOfDSEtoDataPeriod: false },
      };
    }
    case OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, setValueOfDSEtoDataPeriod: false },
        errors: {
          ...state.errors,
          setValueOfDSEtoDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: true },
      };
    case OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS: {
      const {
        data_student_entry,
        changed_groups,
        changed_columns,
        extra_info
      } = action.payload.dseAffectsResponse;

      state.evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: [...state.evidencePeriodData!.evidence_column_groups.map(
          (evidenceColumnGroup) => {
            if (changed_groups) {
              const changedGroupIndex = changed_groups.findIndex(
                (eg) => eg.id === evidenceColumnGroup.id
              );
              if (changedGroupIndex >= 0) {
                return changed_groups[changedGroupIndex];
              }
            }

            if (
              evidenceColumnGroup.id === action.payload.evidenceColumnGroupId
            ) {
              evidenceColumnGroup =  {
                ...evidenceColumnGroup,
                evidence_columns: [...evidenceColumnGroup.evidence_columns.map(
                  (evidenceColumn) => {
                    if (changed_columns) {
                      const changedColumnIndex = changed_columns.findIndex(
                        (eg) => eg.id === evidenceColumnGroup.id
                      );
                      if (changedColumnIndex >= 0) {
                        return changed_columns[changedColumnIndex];
                      }
                    }

                    if (
                      evidenceColumn.id ===
                      data_student_entry.evidence_column.id
                    ) {
                      evidenceColumn =  {
                        ...evidenceColumn,
                        student_entries: [...evidenceColumn.student_entries.map(
                          (studentEntry) => {
                            if (
                              studentEntry.entry_id === data_student_entry.id
                            ) {
                              studentEntry =  {
                                ...studentEntry,
                                value: data_student_entry.value,
                                ...(extra_info 
                                      ? {extra_info: extra_info}
                                      : ''
                                    )
                              };
                            }
                            return studentEntry;
                          }
                          )],
                        };
                      }
                    return evidenceColumn;
                  }
                )],
              };
            }
            return evidenceColumnGroup;
          }
        )],
      };

      if (changed_groups) {
        const notFound = [];
        const existingGroups = new Set(
          [...state.evidencePeriodData.evidence_column_groups.map((g) => g.id)]
        );

        for (const group of changed_groups) {
          if (!existingGroups.has(group.id)) {
            state.evidencePeriodData.evidence_column_groups.push(group);
          }
        }
      }
      return {
        ...state,
        evidencePeriodData: {...state.evidencePeriodData},
        errors: { ...state.errors, updateValueOfDSEtoDataPeriod: undefined },
        isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: false },
      };
    }
    case OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: false },
        errors: {
          ...state.errors,
          updateValueOfDSEtoDataPeriod: action.payload.error,
        },
      };
    case OnboardingActionTypes.SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, setDiagnosticFollowUptoDataPeriod: true },
      };
    case OnboardingActionTypes.SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS: {
      const {
      entry_id,
      evidence_column_id,
      student_id,
      value
      } = action.payload.diagnosticFollowUpResponse;

      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (evidenceColumnGroup) => {
            if (
              evidenceColumnGroup.id === action.payload.evidenceColumnGroupId
            ) {
              return {
                ...evidenceColumnGroup,
                evidence_columns: evidenceColumnGroup.evidence_columns.map(
                  (evidenceColumn) => {
                    if (
                      evidenceColumn.id === evidence_column_id
                    ) {
                      return {
                        ...evidenceColumn,
                        student_entries: [
                          ...evidenceColumn.student_entries,
                          {
                            student_id: student_id!,
                            entry_id: entry_id,
                            value: value,
                            //grade: data_student_entry.grade,
                            // ...(extra_info 
                            //   ? {extra_info: extra_info}
                            //   : ''
                            // )
                          },
                        ],
                      };
                    }
                    return evidenceColumn;
                  }
                ),
              };
            }
            return evidenceColumnGroup;
          }
        ),
      };

      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        errors: { ...state.errors, setDiagnosticFollowUptoDataPeriod: undefined },
        isLoading: { ...state.isLoading, setDiagnosticFollowUptoDataPeriod: false },
      };
    }
    case OnboardingActionTypes.SET_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, setDiagnosticFollowUptoDataPeriod: false },
        errors: {
          ...state.errors,
          setDiagnosticFollowUptoDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDiagnosticFollowUptoDataPeriod: true },
      };
    case OnboardingActionTypes.DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_SUCCESS: {
      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (ecg) => {
            return {
              ...ecg,
              evidence_columns: ecg.evidence_columns.map((ec) => {
                if (ec.evidence_type === EvidenceType.DiagnosticFollowUp) {
                  return {
                    ...ec,
                    student_entries: ec.student_entries.filter(
                      (se) => se.entry_id !== action.payload.studentEntryId
                    ),
                  };
                } 
                return ec;
              }),
            };
          }
        ),
      };
      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        errors: { ...state.errors, deleteDiagnosticFollowUptoDataPeriod: undefined },
        isLoading: { ...state.isLoading, deleteDiagnosticFollowUptoDataPeriod: false },
      };
    }
    case OnboardingActionTypes.DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDiagnosticFollowUptoDataPeriod: false },
        errors: {
          ...state.errors,
          deleteDiagnosticFollowUptoDataPeriod: action.payload.error,
        },
      };  

    case OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_START:
      return {
        ...state,
        nonExistingStudentIds: [],
        isLoading: { ...state.isLoading, detectNonExistingStudentIds: true },
      };
    case OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_SUCCESS:
      return {
        ...state,
        nonExistingStudentIds: action.payload.nonExistingIds,
        errors: { ...state.errors, detectNonExistingStudentIds: undefined },
        isLoading: { ...state.isLoading, detectNonExistingStudentIds: false },
      };
    case OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, detectNonExistingStudentIds: false },
        errors: {
          ...state.errors,
          detectNonExistingStudentIds: action.payload.error,
        },
      };

    case OnboardingActionTypes.CHANGE_TEACHERS_FILTER:
      return {
        ...state,
        teacherIdFilter: action.payload.teacherIdFilter,
      };

    case OnboardingActionTypes.CHANGE_SELECTED_STUDENT_ENTRY:
      return {
        ...state,
        selectedStudentEntry: action.payload.studentEntry,
      };
      case OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_START:
        return {
          ...state,
          isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: true },
        };
    case OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_SUCCESS: {
      const { evidenceGroup } = action.payload;
      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (evidenceColumnGroup) => {
            if (evidenceGroup) {
              const changedGroupIndex = evidenceGroup.id === evidenceColumnGroup.id
              if (changedGroupIndex) {
                return evidenceGroup;
              }
            }
            return evidenceColumnGroup;
          }
        ),
      };
  
      if (evidenceGroup) {
        const notFound = [];
        const existingGroups = new Set(
          evidencePeriodData.evidence_column_groups.map((g) => g.id)
        );
        
        if (!existingGroups) {
            evidencePeriodData.evidence_column_groups.push(evidenceGroup);
          }
      }
      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        errors: { ...state.errors, updateValueOfDSEtoDataPeriod: undefined },
        isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: false },
      };
    }

    case OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateValueOfDSEtoDataPeriod: false },
        errors: {
          ...state.errors,
          updateValueOfDSEtoDataPeriod: action.payload.error,
        },
      };
    case OnboardingActionTypes.GET_DATA_PERIODS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriods: true },
      };
    case OnboardingActionTypes.GET_DATA_PERIODS_SUCCESS: {
      let dataPeriods: DataPeriod[] = [...action.payload.dataPeriods];
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriods: false },
        errors: { ...state.errors, getDataPeriods: undefined },
        dataPeriods: action.payload.dataPeriods,
        currentDataPeriod: IS_READY_COACH
          ? dataPeriods.find(
              (dataPeriod) => dataPeriod.is_selected
            )
          : state.currentDataPeriod,
      }
    }
    case OnboardingActionTypes.GET_DATA_PERIODS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataPeriods: false },
        errors: { ...state.errors, getDataPeriods: action.payload.error },
      };

    case OnboardingActionTypes.ADD_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addDataPeriod: true },
      };
    case OnboardingActionTypes.ADD_DATA_PERIOD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addDataPeriod: false },
        errors: { ...state.errors, addDataPeriod: undefined },
        dataPeriods: [...state.dataPeriods, action.payload.dataPeriod],
      };
    case OnboardingActionTypes.ADD_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addDataPeriod: false },
        errors: { ...state.errors, addDataPeriod: action.payload.error },
      };

    case OnboardingActionTypes.UPDATE_DATA_PERIOD_START: {
      let currentDataPeriod = state.currentDataPeriod;
      let dataPeriods = state.dataPeriods;
      if (action.payload.dataPeriod.is_selected) {
        if (currentDataPeriod) {
          if (currentDataPeriod.id !== action.payload.dataPeriodId) {
            currentDataPeriod = state.dataPeriods.find(
              (dp) => dp.id === action.payload.dataPeriodId
            );
          }
        } else {
          currentDataPeriod = state.dataPeriods.find(
            (dp) => dp.id === action.payload.dataPeriodId
          );
        }

        if (currentDataPeriod) {
          dataPeriods = dataPeriods.map((dp) => {
            return {
              ...dp,
              is_selected: dp.id === currentDataPeriod?.id,
            };
          });
        }
      }

      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataPeriod: true },
        currentDataPeriod: currentDataPeriod,
        dataPeriods: dataPeriods,
        evidencePeriodData:
          state.currentDataPeriod?.id === currentDataPeriod?.id
            ? state.evidencePeriodData
            : {
                evidence_column_groups: [],
                student_rows: [],
              },
      };
    }
    case OnboardingActionTypes.UPDATE_DATA_PERIOD_SUCCESS: {
      let dataPeriods = state.dataPeriods.map((dp) =>
        dp.id === action.payload.dataPeriod.id ? action.payload.dataPeriod : dp
      );

      const currentDataPeriod = dataPeriods.find(
        (dataPeriod) => dataPeriod.is_selected
      );

      // console.log("Resetting all dps: ", currentDataPeriod!.id);

      if (currentDataPeriod) {
        dataPeriods = dataPeriods.map((dp) => {
          return {
            ...dp,
            is_selected: dp.id === currentDataPeriod.id,
          };
        });
      }

      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataPeriod: false },
        errors: { ...state.errors, updateDataPeriod: undefined },
        dataPeriods: dataPeriods,
        currentDataPeriod: currentDataPeriod,
      };
    }
    case OnboardingActionTypes.UPDATE_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataPeriod: false },
        errors: { ...state.errors, updateDataPeriod: action.payload.error },
      };

    case OnboardingActionTypes.DELETE_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataPeriod: true },
      };
    case OnboardingActionTypes.DELETE_DATA_PERIOD_SUCCESS: {
      const dataPeriods = [...state.dataPeriods.filter(
        (dp) => dp.id !== action.payload.dataPeriodId
      )];

      //RC-1339
      const displayedGroupsData = localStorage.getItem(
        LocalStorageKeys.DisplayedGroups
      );
      let storageGroups = displayedGroupsData
        ? JSON.parse(displayedGroupsData)
        : [];

      storageGroups = storageGroups?.filter(
        (dp: any) => dp.dataPeriodId !== action.payload.dataPeriodId
      );

      localStorage.setItem(
        LocalStorageKeys.DisplayedGroups,
        JSON.stringify(storageGroups)
      )

      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataPeriod: false },
        errors: { ...state.errors, deleteDataPeriod: undefined },
        dataPeriods: dataPeriods,
        currentDataPeriod: dataPeriods.find(
          (dataPeriod) => dataPeriod.is_selected
        ),
      };
    }
    case OnboardingActionTypes.DELETE_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataPeriod: false },
        errors: { ...state.errors, deleteDataPeriod: action.payload.error },
      };

    case OnboardingActionTypes.CHANGE_DATA_PERIOD:
      return {
        ...state,
        currentDataPeriod: action.payload.dataPeriod,
      };

    case OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomColorCriteria: true },
      };
    case OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomColorCriteria: false },
        errors: { ...state.errors, getCustomColorCriteria: undefined },
        measurementCustomColorCriteria: action.payload.colorCriteria,
      };
    case OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomColorCriteria: false },
        measurementCustomColorCriteria: [],
        errors: {
          ...state.errors,
          getCustomColorCriteria: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateCustomColorCriteria: true },
      };
    case OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateCustomColorCriteria: false },
        errors: { ...state.errors, updateCustomColorCriteria: undefined },
      };
    case OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateCustomColorCriteria: false },
        errors: {
          ...state.errors,
          updateCustomColorCriteria: action.payload.error,
        },
      };

    case OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, restoreDefaultBenchmarks: true },
      };
    case OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, restoreDefaultBenchmarks: false },
        errors: { ...state.errors, restoreDefaultBenchmarks: undefined },
      };
    case OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, restoreDefaultBenchmarks: false },
        errors: {
          ...state.errors,
          restoreDefaultBenchmarks: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStaticDataCategoryDomains: true },
      };
    case OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStaticDataCategoryDomains: false },
        errors: { ...state.errors, getStaticDataCategoryDomains: undefined },
        staticDataCategoryDomains: action.payload.staticDataCategoryDomains,
      };
    case OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getStaticDataCategoryDomains: false },
        errors: {
          ...state.errors,
          getStaticDataCategoryDomains: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomBehaviors: true },
      };
    case OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomBehaviors: false },
        customBehaviors: action.payload.customBehaviors,
      };
    case OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCustomBehaviors: false },
      };

    case OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCustomBehavior: true },
      };
    case OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCustomBehavior: false },
        customBehaviors: [
          action.payload.customBehavior,
          ...state.customBehaviors,
        ],
      };
    case OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCustomBehavior: false },
      };

    case OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCategoryBehavior: true },
      };
    case OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCategoryBehavior: false },
        errors: { ...state.errors, addCategoryBehavior: undefined },
      };
    case OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addCategoryBehavior: false },
        errors: {
          ...state.errors,
          addCategoryBehavior: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addTargetBehaviorToDataPeriod: true },
      };
    case OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_SUCCESS: {
      const changedEvidenceColumnGroup: EvidenceColumnGroup | undefined = action
        .payload.evidencePeriodDataChanges.evidence_column_groups.length
        ? action.payload.evidencePeriodDataChanges.evidence_column_groups[0]
        : undefined;
      return {
        ...state,
        evidencePeriodData: changedEvidenceColumnGroup
          ? {
              ...state.evidencePeriodData!,
              evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
                (ecg) => {
                  if (ecg.id === changedEvidenceColumnGroup.id) {
                    return changedEvidenceColumnGroup;
                  }
                  return ecg;
                }
              ),
            }
          : state.evidencePeriodData!,
        isLoading: { ...state.isLoading, addTargetBehaviorToDataPeriod: false },
        errors: { ...state.errors, addTargetBehaviorToDataPeriod: undefined },
      };
    }
    case OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addTargetBehaviorToDataPeriod: false },
        errors: {
          ...state.errors,
          addTargetBehaviorToDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStudentDSEGrade: true },
      };
    case OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_SUCCESS: {
      const evidencePeriodData = state.evidencePeriodData;
      if (evidencePeriodData) {
        for (const row of evidencePeriodData.student_rows) {
          if (row.student.id === action.payload.studentId) {
            row.grade = action.payload.grade;
            break;
          }
        }

        for (const ecg of evidencePeriodData.evidence_column_groups) {
          for (const ec of ecg.evidence_columns) {
            for (let i = 0; i < ec.student_entries.length; i++) {
              const se = ec.student_entries[i];
              for (const dse of action.payload.DSEResponse) {
                if (dse.entry_id === se.entry_id) {
                  ec.student_entries[i] = { ...se, ...dse };
                  break;
                }
              }
            }
          }
        }
      }
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStudentDSEGrade: false },
        errors: { ...state.errors, changeStudentDSEGrade: undefined },
        evidencePeriodData: evidencePeriodData,
      };
    }
    case OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStudentDSEGrade: false },
        errors: {
          ...state.errors,
          changeStudentDSEGrade: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_STUDENT_SKILLS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudentSkills: true },
      };
    case OnboardingActionTypes.ADD_STUDENT_SKILLS_SUCCESS: {
      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (ecg) => {
            return {
              ...ecg,
              evidence_columns: ecg.evidence_columns.map((ec) => {
                if (ec.evidence_type === EvidenceType.DiagnosticInfo) {
                  return {
                    ...ec,
                    student_entries: ec.student_entries.some(
                      (se) => se.student_id === action.payload.studentId
                    )
                      ? ec.student_entries.map((se) => {
                          if (se.student_id === action.payload.studentId) {
                            if (action.payload.diagnosticTags.length) {
                              return {
                                ...se,
                                diagnostic_tags: action.payload.diagnosticTags,
                              };
                            }
                          }
                          return se;
                        })
                      : [
                          ...ec.student_entries,
                          {
                            student_id: action.payload.diagnosticTags[0].student,
                            entry_id: action.payload.diagnosticTags[0].entry,
                            diagnostic_tags: action.payload.diagnosticTags,
                            behavior_tags: [],
                            grade: action.payload.grade,
                            needs_area_tags: []
                          },
                        ],
                  };
                }
                return ec;
              }),
            };
          }
        ),
      };
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudentSkills: false },
        errors: { ...state.errors, addStudentSkills: undefined },
        evidencePeriodData: evidencePeriodData,
      };
    }

    case OnboardingActionTypes.ADD_STUDENT_SKILLS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addStudentSkills: false },
        errors: { ...state.errors, addStudentSkills: action.payload.error },
      };

    case OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataStudentEntry: true },
      };
    case OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_SUCCESS: {
      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (ecg) => {
            return {
              ...ecg,
              evidence_columns: ecg.evidence_columns.map((ec) => {
                if (ec.evidence_type === EvidenceType.DiagnosticInfo) {
                  return {
                    ...ec,
                    student_entries: ec.student_entries.filter(
                      (se) => se.entry_id !== action.payload.studentEntryId
                    ),
                  };
                } 
                 if(ec.evidence_type === EvidenceType.BehaviorScreener) {
                  return {
                    ...ec,
                    student_entries: ec.student_entries.filter(
                      (se) => se.entry_id !== action.payload.studentEntryId
                    ),
                  };
                } 
                if(ec.measurement != null) {
                  if(ec.measurement.column_name == 'SRSS External') {
                   return {
                     ...ec,
                     student_entries: ec.student_entries.filter(
                       (se) => se.entry_id !== action.payload.studentEntryId
                     ),
                   };
                 } 
                  if(ec.measurement.column_name == 'SRSS Internal') {
                   return {
                     ...ec,
                     student_entries: ec.student_entries.filter(
                       (se) => se.entry_id !== action.payload.studentEntryId
                     ),
                   };
                 }
                }
                return ec;
              }),
            };
          }
        ),
      };
      return {
        ...state,
        errors: { ...state.errors, deleteEvidenceColumnsGroup: undefined },
        isLoading: { ...state.isLoading, deleteDataStudentEntry: false },
        evidencePeriodData: evidencePeriodData,
      };
    }
    case OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataStudentEntry: false },
        errors: {
          ...state.errors,
          deleteDataStudentEntry: action.payload.error,
        },
      };
    case OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataSharingTableData: true },
      };
    case OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataSharingTableData: false },
        errors: { ...state.errors, getDataSharingTableData: undefined },
        dataSharingTableData: action.payload.dataSharingData,
      };
    case OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDataSharingTableData: false },
        errors: {
          ...state.errors,
          getDataSharingTableData: action.payload.error,
        },
      };
    case OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataSharingTableData: true },
      };
    case OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_SUCCESS:{
      state.districtRelationships = [...state.districtRelationships.map((districtRelationShip: any, index) => {
        if(districtRelationShip.id == action.payload.dataSharingData.id){
          districtRelationShip =  action.payload.dataSharingData;
        }
        return districtRelationShip;
      })];

      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataSharingTableData: false },
        errors: { ...state.errors, updateDataSharingTableData: undefined },
        dataSharingTableData: state.dataSharingTableData.map((tableRow) => {
          if (tableRow.id === action.payload.dataSharingData.id) {
            return action.payload.dataSharingData;
          } else {
            return tableRow;
          }
        }),
      };
    }
    case OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateDataSharingTableData: false },
        errors: {
          ...state.errors,
          updateDataSharingTableData: action.payload.error,
        },
      };

    case OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataSharingTableData: true },
      };
    case OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, deleteDataSharingTableData: undefined },
        isLoading: { ...state.isLoading, deleteDataSharingTableData: false },
        dataSharingTableData: state.dataSharingTableData.filter(
          (tableRow) => tableRow.id !== action.payload.id
        ),
        teachersRoster: state.teachersRoster.filter(
          (t) => t.user.id !== action.payload.teacherId
        ),
      };
    case OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteDataSharingTableData: false },
        errors: {
          ...state.errors,
          deleteDataSharingTableData: action.payload.error,
        },
      };

    case OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendManagerKeyRequest: true },
      };
    case OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendManagerKeyRequest: false },
        errors: { ...state.errors, sendManagerKeyRequest: undefined },
      };
    case OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendManagerKeyRequest: false },
        errors: {
          ...state.errors,
          sendManagerKeyRequest: action.payload.error,
        },
      };
    
    case OnboardingActionTypes.OPEN_COACH_TEACHER_RELATION_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, coachTeacherRelationModal: true },
      };
    case OnboardingActionTypes.HIDE_COACH_TEACHER_RELATION_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, coachTeacherRelationModal: false },
      };

    case OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, shareCoachTeacherRelationData: true },
      };
    case OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, shareCoachTeacherRelationData: false },
        errors: { ...state.errors, shareCoachTeacherRelationData: undefined },
      };
    case OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, shareCoachTeacherRelationData: false },
        errors: {
          ...state.errors,
          shareCoachTeacherRelationData: action.payload.error,
        },
      };

    case OnboardingActionTypes.OPEN_SHARE_DATA_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, shareDataModal: true },
        callFrom: action.payload.callFrom,
      };
    case OnboardingActionTypes.HIDE_SHARE_DATA_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, shareDataModal: false },
        newInviteData: undefined,
      };

    case OnboardingActionTypes.OPEN_DATA_SHARING_EDIT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, dataSharingEditModal: true },
      };
    case OnboardingActionTypes.HIDE_DATA_SHARING_EDIT_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, dataSharingEditModal: false },
      };
    case OnboardingActionTypes.OPEN_GENERATE_PDF_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, generatePDFModal: true },
      };
    case OnboardingActionTypes.HIDE_GENERATE_PDF_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, generatePDFModal: false },
      };
    case OnboardingActionTypes.OPEN_INVITE_TEACHERS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, inviteTeachersModal: true },
      };
    case OnboardingActionTypes.HIDE_INVITE_TEACHERS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, inviteTeachersModal: false },
      };

    case OnboardingActionTypes.SET_NEW_INVITE_DATA:
      return {
        ...state,
        newInviteData: action.payload.newInviteData,
      };

    case OnboardingActionTypes.GET_INTERVIEWS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterviews: true },
      };
    case OnboardingActionTypes.GET_INTERVIEWS_SUCCESS:
      return {
        ...state,
        interviews: action.payload.interviews,
        isLoading: { ...state.isLoading, getInterviews: false },
        errors: { ...state.errors, getInterviews: undefined },
      };
    case OnboardingActionTypes.GET_INTERVIEWS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterviews: false },
        errors: {
          ...state.errors,
          getInterviews: action.payload.error,
        },
      };

    case OnboardingActionTypes.CREATE_INTERVIEW_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterview: true },
      };
    case OnboardingActionTypes.CREATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        selectedInterview: action.payload.interview,
        interviews: [...state.interviews, action.payload.interview],
        isLoading: { ...state.isLoading, createInterview: false },
        errors: { ...state.errors, createInterview: undefined },
      };
    case OnboardingActionTypes.CREATE_INTERVIEW_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterview: false },
        errors: {
          ...state.errors,
          createInterview: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_INTERVIEW_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateInterview: true },
      };
    case OnboardingActionTypes.UPDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        selectedInterview: action.payload.interview,
        interviews: [...state.interviews, action.payload.interview],
        isLoading: { ...state.isLoading, updateInterview: false },
      };
    case OnboardingActionTypes.UPDATE_INTERVIEW_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateInterview: false },
      };

    case OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterviewsStudents: true },
      };
    case OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_SUCCESS:
      return {
        ...state,
        interviewsStudents: action.payload.interviewsStudents,
        isLoading: { ...state.isLoading, getInterviewsStudents: false },
        errors: { ...state.errors, getInterviewsStudents: undefined },
      };
    case OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterviewsStudents: false },
        errors: {
          ...state.errors,
          getInterviewsStudents: action.payload.error,
        },
      };

    case OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterviewStudent: true },
      };
    case OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_SUCCESS:
      return {
        ...state,
        interviewsStudents: [
          ...state.interviewsStudents,
          action.payload.interviewStudent,
        ],
        isLoading: { ...state.isLoading, createInterviewStudent: false },
        errors: { ...state.errors, createInterviewStudent: undefined },
      };
    case OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterviewStudent: false },
        errors: {
          ...state.errors,
          createInterviewStudent: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateInterviewStudent: true },
      };
    case OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_SUCCESS:
      return {
        ...state,
        interviewsStudents: state.interviewsStudents.map((is) =>
          is.id === action.payload.interviewStudent.id
            ? action.payload.interviewStudent
            : is
        ),
        isLoading: { ...state.isLoading, updateInterviewStudent: false },
      };
    case OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateInterviewStudent: false },
      };

    case OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addStudentBehaviorsToDataPeriod: true,
        },
      };
    case OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_SUCCESS: {
      const evidencePeriodData = {
        ...state.evidencePeriodData!,
        evidence_column_groups: state.evidencePeriodData!.evidence_column_groups.map(
          (ecg) => {
            return {
              ...ecg,
              evidence_columns: ecg.evidence_columns.map((ec) => {
                if (ec.evidence_type === EvidenceType.ExistingBehavior) {
                  return {
                    ...ec,
                    student_entries: ec.student_entries.some(
                      (se) => se.student_id === action.payload.studentId //todo remove stub
                    )
                      ? ec.student_entries.map((se) => {
                          if (se.student_id === action.payload.studentId) {
                            return {
                              ...se,
                              behavior_tags: action.payload.behaviors,
                            };
                          }
                          return se;
                        })
                      : [
                          ...ec.student_entries,
                          {
                            entry_id: -1 * action.payload.studentId,
                            student_id: action.payload.studentId,
                            behavior_tags: action.payload.behaviors,
                          },
                        ],
                  };
                }
                return ec;
              }),
            };
          }
        ),
      };
      return {
        ...state,
        evidencePeriodData: evidencePeriodData,
        selectedStudentEntry: state.selectedStudentEntry //todo
          ? {
              ...state.selectedStudentEntry,
              behavior_tags: action.payload.behaviors,
            }
          : {
              entry_id: -1 * action.payload.studentId,
              student_id: action.payload.studentId,
              behavior_tags: action.payload.behaviors,
            },
        isLoading: {
          ...state.isLoading,
          addStudentBehaviorsToDataPeriod: false,
        },
        errors: { ...state.errors, addStudentBehaviorsToDataPeriod: undefined },
      };
    }
    case OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addStudentBehaviorsToDataPeriod: false,
        },
        errors: {
          ...state.errors,
          addStudentBehaviorsToDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDefaultProgressMonitoring: true },
      };
    case OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDefaultProgressMonitoring: false },
        errors: { ...state.errors, getDefaultProgressMonitoring: undefined },
        defaultProgressMonitoring: action.payload.defaultProgressMonitoring,
      };
    case OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDefaultProgressMonitoring: false },
        errors: {
          ...state.errors,
          getDefaultProgressMonitoring: action.payload.error,
        },
      };
    case OnboardingActionTypes.RESEND_INVITE_TO_USERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, resendInviteToUsers: true },
      };  
    case OnboardingActionTypes.ADD_USER_TO_DISTRICT_REQUEST:
      return {
        ...state,
        isLoading: { ...state.isLoading, addUserToDistrict: true },
      };
    case OnboardingActionTypes.ADD_USER_TO_DISTRICT_SUCCESS: {
      let updatedTeacherRoster: any[] = [];
      if(action.payload.users.length == 1) {
        let updatedUser = action.payload.users[0];
        updatedTeacherRoster = [...state.teachersRoster.map((teacher) => {
          if(updatedUser?.user.id == teacher?.user.id) {
            let updateTeacher =  {
              ...teacher,
              invite: {
                ...teacher?.invite,
                status: updatedUser?.invite.status
              },
              user: {
                ...teacher?.user,
                first_name: updatedUser?.user.first_name,
                last_name: updatedUser?.user.last_name,
                email: updatedUser?.user.email
              }
            }
            return updateTeacher;
          } else {
            return teacher;
          }
        })]
      } else {
        updatedTeacherRoster = [...action.payload.users.filter((user) => !user.resend_invite), ...state.teachersRoster]
      }
      return {
        ...state,
        teachersRoster: updatedTeacherRoster,
        isLoading: { ...state.isLoading, addUserToDistrict: false, resendInviteToUsers: false },
        errors: { ...state.errors, addUserToDistrict: undefined, resendInviteToUsers: undefined },
      };
    }
    case OnboardingActionTypes.ADD_USER_TO_DISTRICT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addUserToDistrict: false, resendInviteToUsers: false },
        errors: { ...state.errors, addUserToDistrict: action.payload.error, resendInviteToUsers: action.payload.error },
      };

    case OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDistrictRelationships: true },
      };
    case OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDistrictRelationships: false },
        errors: { ...state.errors, getDistrictRelationships: undefined },
        districtRelationships: action.payload,
      };
    case OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDistrictRelationships: false },
        errors: {
          ...state.errors,
          getDistrictRelationships: action.payload.error,
        },
      };

    case OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDistrictRelationships: true },
      };
    case OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_SUCCESS: {
      
      const districtRelation = [...state.districtRelationships.filter(
        (relationship) => relationship.child.user.id == action.payload.userId)];
        return {
          ...state,
          isLoading: { ...state.isLoading, getDistrictRelationships: false },
          errors: { ...state.errors, getDistrictRelationships: undefined },
          districtRelationships: [...state.districtRelationships.filter(
                                  (relationship) => relationship.child.user.id !== action.payload.userId)],
        };
    }
    case OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getDistrictRelationships: false },
        errors: {
          ...state.errors,
          getDistrictRelationships: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroupLogging: true },
      };
    case OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroupLogging: false },
        errors: { ...state.errors, getInterventionGroupLogging: undefined },
        interventionGroupLogging: action.payload,
      };
    case OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroupLogging: false },
        errors: {
          ...state.errors,
          getInterventionGroupLogging: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addTeachersToCoaches: true },
      };
    case OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addTeachersToCoaches: false },
        errors: { ...state.errors, addTeachersToCoaches: undefined },
      };
    case OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addTeachersToCoaches: false },
        errors: { ...state.errors, addTeachersToCoaches: action.payload.error },
      };

    case OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addEducatorsToSupervisors: true },
      };
    case OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addEducatorsToSupervisors: false },
        errors: { ...state.errors, addEducatorsToSupervisors: undefined },
      };
    case OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addEducatorsToSupervisors: false },
        errors: {
          ...state.errors,
          addEducatorsToSupervisors: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addExistingBehaviorEcgToDataPeriod: true,
        },
      };
    case OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_SUCCESS: {
      return {
        ...state,
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              evidence_column_groups: state.evidencePeriodData.evidence_column_groups.some(
                (ecg) => ecg.id === action.payload.existingBehaviorEcg.id
              )
                ? state.evidencePeriodData.evidence_column_groups.map((ecg) =>
                    ecg.id === action.payload.existingBehaviorEcg.id
                      ? action.payload.existingBehaviorEcg
                      : ecg
                  )
                : [
                    ...state.evidencePeriodData.evidence_column_groups,
                    action.payload.existingBehaviorEcg,
                  ],
            }
          : undefined,
        isLoading: {
          ...state.isLoading,
          addExistingBehaviorEcgToDataPeriod: false,
        },
        errors: {
          ...state.errors,
          addExistingBehaviorEcgToDataPeriod: undefined,
        },
      };
    }
    case OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addExistingBehaviorEcgToDataPeriod: false,
        },
        errors: {
          ...state.errors,
          addExistingBehaviorEcgToDataPeriod: action.payload.error,
        },
      };

    case OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_START:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            addBehaviorScreenerEcgToDataPeriod: true,
          },
        };
    case OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_SUCCESS: {
        return {
          ...state,
          evidencePeriodData: state.evidencePeriodData
            ? {
                ...state.evidencePeriodData,
                evidence_column_groups: state.evidencePeriodData.evidence_column_groups.some(
                  (ecg: EvidenceColumnGroup) => ecg.id === action.payload.behaviorScreenerEcg.id
                )
                  ? [...state.evidencePeriodData.evidence_column_groups.map((ecg: EvidenceColumnGroup) =>
                      ecg.id === action.payload.behaviorScreenerEcg.id
                        ? action.payload.behaviorScreenerEcg
                        : ecg
                    )]
                  : [
                      ...state.evidencePeriodData.evidence_column_groups,
                      action.payload.behaviorScreenerEcg,
                    ],
              }
            : undefined,
          isLoading: {
            ...state.isLoading,
            addBehaviorScreenerEcgToDataPeriod: false,
          },
          errors: {
            ...state.errors,
            addBehaviorScreenerEcgToDataPeriod: undefined,
          },
        };
      }
    case OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_FAILURE:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            addBehaviorScreenerEcgToDataPeriod: false,
          },
          errors: {
            ...state.errors,
            addBehaviorScreenerEcgToDataPeriod: action.payload.error,
          },
        };
  
    case OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addDistrictAssessment: true },
      };
    case OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        evidenceAssessments: [
          ...state.evidenceAssessments,
          action.payload.evidenceAssessment,
        ],
        isLoading: { ...state.isLoading, addDistrictAssessment: false },
        errors: { ...state.errors, addDistrictAssessment: undefined },
      };
    case OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addDistrictAssessment: false },
        errors: {
          ...state.errors,
          addDistrictAssessment: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateDistrictAssessment: true },
      };
    case OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        evidenceAssessments: state.evidenceAssessments.map((ea) =>
          ea.id == action.payload.evidenceAssessment.id
            ? action.payload.evidenceAssessment
            : ea
        ),
        isLoading: { ...state.isLoading, updateDistrictAssessment: false },
        errors: { ...state.errors, updateDistrictAssessment: undefined },
      };
    case OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateDistrictAssessment: false },
        errors: {
          ...state.errors,
          updateDistrictAssessment: action.payload.error,
        },
      };

    case OnboardingActionTypes.OPEN_UPSERT_SCHOOL_MODAL:
      return {
        ...state,
        selectedSchool: action.payload.school,
        modalsState: {
          ...state.modalsState,
          upsertSchoolModal: true,
          upsertSchoolModalConfig: {
            districtId: action.payload.districtId,
          },
        },
      };
    case OnboardingActionTypes.HIDE_UPSERT_SCHOOL_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          upsertSchoolModal: false,
          upsertSchoolModalConfig: undefined,
        },
      };

    case OnboardingActionTypes.OPEN_MANAGE_DATA_PERIODS_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, manageDataPeriodsModal: true },
      };
    case OnboardingActionTypes.HIDE_MANAGE_DATA_PERIODS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          manageDataPeriodsModal: false,
        },
      };

    case OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, assignTeachersToStudent: true },
      };
    case OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_SUCCESS: {
      let selectedStudent:any;
      const studentRow = state.evidencePeriodData?.student_rows.filter((studentRow) => studentRow.student.id == action.payload.student.id);
      if(studentRow?.length) {
        studentRow[0].student.teachers?.map((teacher) => {
          selectedStudent = {
            ...action.payload.student.teachers,
            classes: teacher.classes
          }
        })
      }
      return {
        ...state,
        selectedStudent: selectedStudent,
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              student_rows: [
                ...state.evidencePeriodData.student_rows.map((studentRow) => {
                  if (studentRow.student.id === action.payload.student.id) {
                    const prevTeachers = studentRow.student.teachers
                    let prevTeachersIdss:any[] = []
                    prevTeachers?.map((t) => {
                      prevTeachersIdss.push(t.id)
                    })
                    const newTeachers = action.payload.student.teachers
                    let resultTeachers: BasicUserInfo[] = []
                    newTeachers?.map((nt) => {
                      if (prevTeachersIdss.includes(nt.id)) {
                        const prev = prevTeachers?.find((pt) => {
                          return pt.id === nt.id
                        })
                        resultTeachers.push(prev!)
                      }
                      else {
                        resultTeachers.push(nt)
                      }
                    })
                    action.payload.student['teachers'] = resultTeachers
                    return {
                      ...studentRow,
                      student: {
                        // ...studentRow.student,
                        ...action.payload.student,
                      },
                    };
                  } else {
                    return studentRow;
                  }
                }),
              ],
            }
          : undefined,
        isLoading: { ...state.isLoading, assignTeachersToStudent: false },
        errors: { ...state.errors, assignTeachersToStudent: undefined },
      }

    }
    case OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, assignTeachersToStudent: false },
        errors: {
          ...state.errors,
          assignTeachersToStudent: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getClassesLinkedToStudent: true },
      };
    case OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_SUCCESS:
      return {
        ...state,
        classesLinkedToStudent: action.payload.classesLinkedToStudent,
        isLoading: { ...state.isLoading, getClassesLinkedToStudent: false },
        errors: { ...state.errors, getClassesLinkedToStudent: undefined },
      };
    case OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getClassesLinkedToStudent: false },
        errors: {
          ...state.errors,
          getClassesLinkedToStudent: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_ALL_CLASSES_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllClasses: true },
      };
    case OnboardingActionTypes.GET_ALL_CLASSES_SUCCESS:
      return {
        ...state,
        allClasses: action.payload.allClasses,
        isLoading: { ...state.isLoading, getAllClasses: false },
        errors: { ...state.errors, getAllClasses: undefined },
      };
    case OnboardingActionTypes.GET_ALL_CLASSES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllClasses: false },
        errors: {
          ...state.errors,
          getAllClasses: action.payload.error,
        },
      };

    case OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateClassesLinkedToStudent: true },
      };
    case OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_SUCCESS: {
      const classes: TeacherStudentClass[] = action.payload.studentClasses.map(
        (sc) => ({
          teacher: state.allClasses.find((c) => c.class.id == sc.id)
            ?.teacher! as any,
          class: sc,
        })
      );
      action.payload.studentClasses.map(
        (sc) => {
          state = {
            ...state,
            teacherClassStudent: {
              id: sc.id,
              name: sc.name,
              students: action.payload.students as Student[]
            } 
          }
          
        }
      )
      const teacherClassFromStudents: any[] = action.payload.students.flatMap((std) => std.teachers!);
      return {
        ...state,
        classesLinkedToStudent: classes,
        teacherClassStudent: state.teacherClassStudent,
        isLoading: { ...state.isLoading, updateClassesLinkedToStudent: false },
        errors: { ...state.errors, updateClassesLinkedToStudent: undefined },
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              student_rows: [
                ...state.evidencePeriodData?.student_rows.map((studentRow) => {
                  if (action.payload.students.some((student) => student.id == studentRow.student.id)) {
                    studentRow = {
                      ...studentRow,
                      student: {
                        ...studentRow.student,
                        teachers: [...studentRow.student.teachers!.map((teacher) => {
                          for (let i = 0; i < teacherClassFromStudents.length; i++) {
                            const teacherElement = teacherClassFromStudents[i];
                            if(teacherElement.id == teacher.id) {
                              teacher = {
                                ...teacher,
                                classes: teacherElement.classes
                              }
                              break;
                            }
                          }
                          return teacher;
                        })]
                      }
                    }
                  }
                  return studentRow;
                }),
              ],
            }
          : undefined
      };
    }
    case OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateClassesLinkedToStudent: false },
        errors: {
          ...state.errors,
          updateClassesLinkedToStudent: action.payload.error,
        },
      };

    case getType(onboardingActions.getDistrictSummaryAction.request):
      return {
        ...state,
        district_summary: undefined,
        isLoading: {
          ...state.isLoading,
          getDistrictSummary: true,
        },
        errors: {
          ...state.errors,
          getDistrictSummary: undefined,
        },
      };

    case getType(onboardingActions.getDistrictSummaryAction.success):
      return {
        ...state,
        district_summary: action.payload,
        isLoading: {
          ...state.isLoading,
          getDistrictSummary: false,
        },
        errors: {
          ...state.errors,
          getDistrictSummary: undefined,
        },
      };

    case getType(onboardingActions.getDistrictSummaryAction.failure):
      return {
        ...state,
        district_summary: undefined,
        isLoading: {
          ...state.isLoading,
          getDistrictSummary: false,
        },
        errors: {
          ...state.errors,
          getDistrictSummary: action.payload.message,
        },
      };

    case getType(onboardingActions.getDistrictSummaryReportAction.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getDistrictSummaryReport: true,
        },
        errors: {
          ...state.errors,
          getDistrictSummaryReport: undefined,
        },
      };

    case getType(onboardingActions.getDistrictSummaryReportAction.success):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getDistrictSummaryReport: false,
        },
      };

    case getType(onboardingActions.getDistrictSummaryReportAction.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getDistrictSummaryReport: false,
        },
        errors: {
          ...state.errors,
          getDistrictSummaryReport: action.payload.message,
        },
      };

    case getType(onboardingActions.getDistrictsAction.request):
      return {
        ...state,
        districts: [],
        isLoading: {
          ...state.isLoading,
          getDistricts: true,
        },
        errors: {
          ...state.errors,
          getDistricts: undefined,
        },
      };

    case getType(onboardingActions.getDistrictsAction.success):
      return {
        ...state,
        districts: action.payload,
        isLoading: {
          ...state.isLoading,
          getDistricts: false,
        },
        errors: {
          ...state.errors,
          getDistricts: undefined,
        },
      };

    case getType(onboardingActions.getDistrictsAction.failure):
      return {
        ...state,
        districts: [],
        isLoading: {
          ...state.isLoading,
          getDistricts: false,
        },
        errors: {
          ...state.errors,
          getDistricts: action.payload.message,
        },
      };
    case OnboardingActionTypes.CHANGE_CUSTOM_BEHAVIOR_NAME:
      return {
        ...state,
        customBehaviorName: action.payload.name,
      };

    case OnboardingActionTypes.INTERVENTION_GROUP_ACCESS:
      return {
        ...state,
        intGrpAccessObj: action.payload.intGrpAccessObj,
      };

    case OnboardingActionTypes.SHOW_LOADING_INDICATOR:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          isLoadingIndicator: true,
        },
      };

    case OnboardingActionTypes.HIDE_LOADING_INDICATOR:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          isLoadingIndicator: false,
        },
      };
  

    /*
     * GET
     * Tutorial Videos
     */
    case getType(onboardingActions.getTutorialVideos.request):
      return {
        ...state,
        errors: {
          ...state.errors,
          getTutorialVideos: undefined,
        },
        isLoading: {
          ...state.isLoading,
          getTutorialVideos: true,
        },
      };

    case getType(onboardingActions.getTutorialVideos.success):
      return {
        ...state,
        tutorialVideos: action.payload,
        errors: {
          ...state.errors,
          getTutorialVideos: undefined,
        },
        isLoading: {
          ...state.isLoading,
          getTutorialVideos: false,
        },
      };

    case getType(onboardingActions.getTutorialVideos.failure):
      return {
        ...state,
        errors: {
          ...state.errors,
          getTutorialVideos: action.payload.message,
        },
        isLoading: {
          ...state.isLoading,
          getTutorialVideos: false,
        },
      };

    case OnboardingActionTypes.GET_STUDENTS_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: true },
      };
    case OnboardingActionTypes.GET_STUDENTS_DATA_SUCCESS:
      return {
        ...state,
        evidencePeriodData: {
          evidence_column_groups:
            action.payload.studentsDataResponse.evidence_column_groups,
          student_rows: action.payload.studentsDataResponse.student_rows,
        },
        interviews: action.payload.studentsDataResponse.interviews,
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: false },
        errors: { ...state.errors, getEvidenceInDatePeriod: undefined },
      };
    case OnboardingActionTypes.GET_STUDENTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getEvidenceInDatePeriod: false },
        errors: {
          ...state.errors,
          getEvidenceInDatePeriod: action.payload.error,
        },
      };

    /*
     * Parent Invitation features
     */

    case getType(onboardingActions.inviteParent.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          inviteParent: true,
        },
      };

    case getType(onboardingActions.inviteParent.success):
      return {
        ...state,
        parents: [...state.parents, action.payload],
        isLoading: {
          ...state.isLoading,
          inviteParent: false,
        },
      };

    case getType(onboardingActions.inviteParent.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          inviteParent: false,
        },
      };

    case getType(onboardingActions.addStudentToParent.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addStudentToParent: true,
        },
      };
    case getType(onboardingActions.addStudentToParent.success):
      return {
        ...state,
        parents: state.parents.some((p) => p.id === action.payload.id)
          ? state.parents.map((p) =>
              p.id === action.payload.id ? action.payload : p
            )
          : [action.payload, ...state.parents],
        isLoading: {
          ...state.isLoading,
          addStudentToParent: false,
        },
      };
    case getType(onboardingActions.addStudentToParent.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addStudentToParent: false,
        },
      };

    case getType(onboardingActions.setSelectedParent):
      return {
        ...state,
        selectedParent: action.payload,
      };

    case getType(onboardingActions.openInviteParentModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          inviteParentModal: true,
        },
      };
    case getType(onboardingActions.hideInviteParentModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          inviteParentModal: false,
        },
      };

    case getType(onboardingActions.sendAssessmentSuggestions.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sendAssessmentSuggestions: true,
        },
      };
    case getType(onboardingActions.sendAssessmentSuggestions.success):
    case getType(onboardingActions.sendAssessmentSuggestions.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sendAssessmentSuggestions: false,
        },
      };

    case getType(onboardingActions.openEvidenceConfirmDeletionModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          evidenceConfirmDeletionModal: true,
        },
        selectedEvidenceColumnGroup: action.payload.evidenceColumnGroup,
        timeElipse: action.payload.timeElipse,
      };
    case getType(onboardingActions.hideEvidenceConfirmDeletionModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          evidenceConfirmDeletionModal: false,
        },
        selectedEvidenceColumnGroup: undefined,
        timeElipse: false,
      };

    case getType(onboardingActions.openPreClassWideNeedsModal): {
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showPreClassWideNeedsModal: true,
        },
      };
    }
    case getType(onboardingActions.hidePreClassWideNeedsModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showPreClassWideNeedsModal: false,
        }
      };

    case getType(onboardingActions.openClassWideNeedsModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showClassWideNeedsModal: true,
        },
      };
    case getType(onboardingActions.hideClassWideNeedsModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showClassWideNeedsModal: false,
        },
        selectedEvidenceColumnGroup: undefined,
      };


    case getType(onboardingActions.openEquityBenchmarksModal):
      return {
        ...state,
        selectedEvidenceColumnGroupId: action.payload,
        modalsState: {
          ...state.modalsState,
          showEquityBenchmarksModal: true,
        },
      };
    case getType(onboardingActions.hideEquityBenchmarksModal):
      return {
        ...state,
        selectedEvidenceColumnGroupId: undefined,
        modalsState: {
          ...state.modalsState,
          showEquityBenchmarksModal: false,
        },
      };

    case getType(onboardingActions.createDiagnosticInfoColumn.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createDiagnosticInfoColumn: true,
        },
      };
    case getType(onboardingActions.createDiagnosticInfoColumn.success):
      return {
        ...state,
        evidencePeriodData: state.evidencePeriodData
          ? {
              ...state.evidencePeriodData,
              evidence_column_groups: state.evidencePeriodData.evidence_column_groups.some(
                (ecg) => ecg.id === action.payload.id
              )
                ? state.evidencePeriodData.evidence_column_groups.map((ecg) =>
                    ecg.id === action.payload.id ? action.payload : ecg
                  )
                : [
                    ...state.evidencePeriodData.evidence_column_groups,
                    action.payload,
                  ],
            }
          : undefined,
        isLoading: {
          ...state.isLoading,
          createDiagnosticInfoColumn: false,
        },
      };
    case getType(onboardingActions.createDiagnosticInfoColumn.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createDiagnosticInfoColumn: false,
        },
      };

    case getType(onboardingActions.getDistrictAccessDataSettingsAction.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getDistrictAccessDataSettings: true,
        },
        errors: {
          ...state.errors,
          getDistrictAccessDataSettings: undefined,
        },
      };
    case getType(onboardingActions.getDistrictAccessDataSettingsAction.success):
      return {
        ...state,
        districtAccessDataSettings: action.payload,
        isLoading: {
          ...state.isLoading,
          getDistrictAccessDataSettings: false,
        },
      };
    case getType(onboardingActions.getDistrictAccessDataSettingsAction.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getDistrictAccessDataSettings: false,
        },
        errors: {
          ...state.errors,
          getDistrictAccessDataSettings: action.payload,
        },
      };

    case getType(onboardingActions.addDistrictAccessDataSettingAction.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addDistrictAccessDataSetting: true,
        },
        errors: {
          ...state.errors,
          addDistrictAccessDataSetting: undefined,
        },
      };
    case getType(onboardingActions.addDistrictAccessDataSettingAction.success):
      return {
        ...state,
        districtAccessDataSettings: [
          ...state.districtAccessDataSettings,
          action.payload,
        ],
        isLoading: {
          ...state.isLoading,
          addDistrictAccessDataSetting: false,
        },
      };
    case getType(onboardingActions.addDistrictAccessDataSettingAction.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addDistrictAccessDataSetting: false,
        },
        errors: {
          ...state.errors,
          addDistrictAccessDataSetting: action.payload,
        },
      };

    case getType(
      onboardingActions.updateDistrictAccessDataSettingAction.request
    ):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateDistrictAccessDataSetting: true,
        },
        errors: {
          ...state.errors,
          updateDistrictAccessDataSetting: undefined,
        },
      };
    case getType(
      onboardingActions.updateDistrictAccessDataSettingAction.success
    ):
      return {
        ...state,
        districtAccessDataSettings: state.districtAccessDataSettings.map(
          (setting) =>
            setting.id === action.payload.id ? action.payload : setting
        ),
        isLoading: {
          ...state.isLoading,
          updateDistrictAccessDataSetting: false,
        },
      };
    case getType(
      onboardingActions.updateDistrictAccessDataSettingAction.failure
    ):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateDistrictAccessDataSetting: false,
        },
        errors: {
          ...state.errors,
          updateDistrictAccessDataSetting: action.payload,
        },
      };

    case getType(
      onboardingActions.deleteDistrictAccessDataSettingAction.request
    ):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteDistrictAccessDataSetting: true,
        },
        errors: {
          ...state.errors,
          deleteDistrictAccessDataSetting: undefined,
        },
      };
    case getType(
      onboardingActions.deleteDistrictAccessDataSettingAction.success
    ):
      return {
        ...state,
        districtAccessDataSettings: state.districtAccessDataSettings.filter(
          (setting) => setting.id !== action.payload
        ),
        isLoading: {
          ...state.isLoading,
          deleteDistrictAccessDataSetting: false,
        },
      };
    case getType(
      onboardingActions.deleteDistrictAccessDataSettingAction.failure
    ):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteDistrictAccessDataSetting: false,
        },
        errors: {
          ...state.errors,
          deleteDistrictAccessDataSetting: action.payload,
        },
      };

    case getType(onboardingActions.openDistrictAccessDataSettingUpsertModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          districtAccessDataSettingUpsertModal: true,
        },
        selectedDistrictAccessDataSetting: action.payload
          ? action.payload
          : undefined,
      };
    case getType(onboardingActions.hideDistrictAccessDataSettingUpsertModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          districtAccessDataSettingUpsertModal: false,
        },
        selectedDistrictAccessDataSetting: undefined,
      };

    case getType(onboardingActions.openDistrictDataPeriodUpsertModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          districtDataPeriodUpsertModal: true,
        },
        selectedDistrictDataPeriod: action.payload,
      };
    case getType(onboardingActions.hideDistrictDataPeriodUpsertModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          districtDataPeriodUpsertModal: false,
        },
        selectedDistrictDataPeriod: undefined,
      };

    case getType(onboardingActions.changeIntGroupAssigmentCollapsedSettings): {
      const ids = action.payload.collapsed
        ? [...state.collapsedIntGroupAssignmentsIds, action.payload.id]
        : action.payload.id ? state.collapsedIntGroupAssignmentsIds.filter(
            (id) => id !== action.payload.id
          ) : [];
      localStorage.setItem(
        LocalStorageKeys.CollapsedAssignments,
        JSON.stringify(ids)
      );

      return {
        ...state,
        collapsedIntGroupAssignmentsIds: ids,
      };
    }

    case getType(onboardingActions.changeIntGroupInnerCollapsedSettings): {
      let accordianSetting = action.payload.accordianSetting;
      let loggedInUserId = action.payload.loggedInUserId;
      let interventionGroupId = action.payload.interventionGroupId;

      let accordianSettingData:any = {...state.collapsedIntGroupInnerIds};
      if(Object.keys(state.collapsedIntGroupInnerIds).length > 0) {
        for (const loggedInUserIdKey in state.collapsedIntGroupInnerIds) {
          if(+loggedInUserIdKey == loggedInUserId) {
            for (const interventionGroupIdKey in state.collapsedIntGroupInnerIds[loggedInUserIdKey]) {
              if(+interventionGroupIdKey == interventionGroupId) {
                accordianSettingData = {
                  ...state.collapsedIntGroupInnerIds,
                  [loggedInUserId]: {
                    ...state.collapsedIntGroupInnerIds[loggedInUserId],
                    [interventionGroupId]: {
                      ...state.collapsedIntGroupInnerIds[loggedInUserId][interventionGroupId],
                      interventionCollapsed: accordianSetting[loggedInUserId][interventionGroupId].interventionCollapsed,
                      avgCollapsed: accordianSetting[loggedInUserId][interventionGroupId].avgCollapsed,
                      additionalEducatorCollapsed:
                          accordianSetting[loggedInUserId][interventionGroupId].additionalEducatorCollapsed
                    }
                  }
                }
              } else {
                accordianSettingData = {...state.collapsedIntGroupInnerIds,
                  [loggedInUserId]: {
                    ...state.collapsedIntGroupInnerIds[loggedInUserId],...accordianSetting[loggedInUserId]
                  }
                };
              }
            }
          } else {
            accordianSettingData = {...state.collapsedIntGroupInnerIds,...accordianSetting};
          }
        }
      } else {
        accordianSettingData = {...state.collapsedIntGroupInnerIds,...accordianSetting};
      }

      localStorage.setItem(
        LocalStorageKeys.CollapsedInnerAssignments,
        JSON.stringify(accordianSettingData)
      );

      return {
        ...state,
        collapsedIntGroupInnerIds: accordianSettingData,
      };
    }

    case getType(onboardingActions.changeCollapsedAreas): {
      const collapsedAreas = {
        ...state.districtSummaryOptions.collapsedAreas,
        [action.payload.key]: action.payload.value,
      };

      localStorage.setItem(
        LocalStorageKeys.DistrictSummaryCollapsedAreas,
        JSON.stringify(collapsedAreas)
      );

      return {
        ...state,
        districtSummaryOptions: {
          ...state.districtSummaryOptions,
          collapsedAreas: collapsedAreas,
        },
      };
    }

    case getType(onboardingActions.changeGraphsDisplayedAreas): {
      localStorage.setItem(
        LocalStorageKeys.DistrictSummaryDisplayGraphs,
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        districtSummaryOptions: {
          ...state.districtSummaryOptions,
          displayCharts: action.payload,
        },
      };
    }

    case getType(onboardingActions.changeUserStatusAction.request):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          changeUserStatusAction: true,
        },
        errors: {
          ...state.errors,
          changeUserStatusAction: undefined,
        },
      };
    case getType(onboardingActions.changeUserStatusAction.success): {
      const showDisabledUsers = state.showDisabledUsers;
      const accountDisabled = !action.payload.is_active;

      return {
        ...state,
        teachersRoster:
          showDisabledUsers && !accountDisabled
            ? state.teachersRoster.filter(
                (t) => t.user.id !== action.payload.id
              )
            : state.teachersRoster.map((t) =>
                t.user.id === action.payload.id
                  ? {
                      ...t,
                      user: {
                        ...t.user,
                        account_disabled: accountDisabled,
                        profile: {
                          ...t.user.profile,
                          account_disabled: accountDisabled
                        }
                      },
                    }
                  : t
              ).filter( //RC-1457 real time remove user from active to disable
                (t) => t.user.id !== action.payload.id
              ),
        selectedTeacher:
          state.selectedTeacher?.user.id === action.payload.id
            ? {
                ...state.selectedTeacher,
                user: {
                  ...state.selectedTeacher.user,
                  account_disabled: accountDisabled,
                  profile: {
                    ...state.selectedTeacher.user.profile,
                    account_disabled: accountDisabled
                  }
                },
              }
            : state.selectedTeacher,
        isLoading: {
          ...state.isLoading,
          changeUserStatusAction: false,
        },
      };
    }
    case getType(onboardingActions.changeUserStatusAction.failure):
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          changeUserStatusAction: false,
        },
        errors: {
          ...state.errors,
          changeUserStatusAction: action.payload,
        },
      };

    case getType(onboardingActions.setShowDisabledUsers):
      return {
        ...state,
        showDisabledUsers: action.payload,
      };

    case getType(onboardingActions.setNoClassWideNeeds):
      return {
        ...state,
        noClassWideNeeds: action.payload,
      };

    case getType(onboardingActions.openStudentEditModal):
      return {
        ...state,
        selectedStudent: action.payload,
        modalsState: {
          ...state.modalsState,
          showStudentEditModal: true,
        },
      };
    case getType(onboardingActions.hideStudentEditModal):
      return {
        ...state,
        selectedStudent: undefined,
        modalsState: {
          ...state.modalsState,
          showStudentEditModal: false,
        },
      };

    case getType(onboardingActions.openInterventionStatsModal):
      return {
        ...state,
        selectedEducator: action.payload,
        modalsState: {
          ...state.modalsState,
          showInterventionStatsModal: true,
        },
      };
    case getType(onboardingActions.hideInterventionStatsModal):
      return {
        ...state,
        selectedEducator: undefined,
        modalsState: {
          ...state.modalsState,
          showInterventionStatsModal: false,
        },
      };

    case getType(onboardingActions.openUploadStudentDemographicModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showUploadStudentDemographicModal: true,
        },
      };
    case getType(onboardingActions.hideUploadStudentDemographicModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          showUploadStudentDemographicModal: false,
        },
      };

    case OnboardingActionTypes.IS_URL_BLOCKED_PAGE:
      return {
        ...state,
        isUrlBlocked: action.payload.isBlock,
      };
    
    case OnboardingActionTypes.GET_RACE_INFO_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getRaceInfo: true },
      };
    case OnboardingActionTypes.GET_RACE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getRaceInfo: false },
        errors: { ...state.errors, getRaceInfo: undefined },
        raceInfo: action.payload.raceInfo,
      };
    case OnboardingActionTypes.GET_RACE_INFO_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getRaceInfo: false },
        errors: {
          ...state.errors,
          getRaceInfo: action.payload.error,
        },
      };

    case OnboardingActionTypes.GET_MASTER_RACE_INFO_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMasterRaceInfo: true },
      };
    case OnboardingActionTypes.GET_MASTER_RACE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMasterRaceInfo: false },
        errors: { ...state.errors, getMasterRaceInfo: undefined },
        masterRaceInfo: action.payload.raceInfo,
      };
    case OnboardingActionTypes.GET_MASTER_RACE_INFO_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMasterRaceInfo: false },
        errors: {
          ...state.errors,
          getMasterRaceInfo: action.payload.error,
        },
      };

    case OnboardingActionTypes.CHECK_EMAIL_EXIST_REQUEST:
      return {
        ...state,
        isLoading: { ...state.isLoading, checkEmailExist: true },
      };
    case OnboardingActionTypes.CHECK_EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, checkEmailExist: false },
        errors: { ...state.errors, checkEmailExist: undefined },
        existingEmails: action.payload.existingEmails,
      };
    case OnboardingActionTypes.CHECK_EMAIL_EXIST_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, checkEmailExist: false },
        errors: { ...state.errors, checkEmailExist: action.payload.error },
      };
    case OnboardingActionTypes.GET_INTERVENTION_STATS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionsOverview: true },
      };
    case OnboardingActionTypes.GET_INTERVENTION_STATS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionsOverview: false },
        errors: { ...state.errors, getInterventionsOverview: undefined },
        interventionsOverview: action.payload.interventionsOverview,
      };
    case OnboardingActionTypes.GET_INTERVENTION_STATS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionsOverview: false },
        errors: {
          ...state.errors,
          getInterventionsOverview: action.payload.error,
        },
      };
    case OnboardingActionTypes.GET_CASE_LOAD_USERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCaseLoadUsers: true },
      };
    case OnboardingActionTypes.GET_CASE_LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCaseLoadUsers: false },
        errors: { ...state.errors, getCaseLoadUsers: undefined },
        caseLoadUsers: action.payload.caseLoadUsers,
      };
    case OnboardingActionTypes.GET_CASE_LOAD_USERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getCaseLoadUsers: false },
        errors: {
          ...state.errors,
          getCaseLoadUsers: action.payload.error,
        },
      };

    default:
      return state;
  }
};

export { reducer as onboardingReducer };
