import {
  BasicUserInfo,
  Race,
  genderList,
  ReactSelectOption,
  Student,
  SuccessfulInvite,
  TeacherInfo,
  RaceInfo,
} from "../../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  assignTeachersToStudent,
  changeSelectedStudent,
  getAllClasses,
  getClassesLinkedToStudent,
  getTeachers,
  openInviteParentModal,
  openTeacherInviteIndividuallyModal,
  updateClassesLinkedToStudent,
  updateTeacherStudentDetails,
} from "../../../../../../../store/onboarding/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  FormControl,
  ListGroup,
  Overlay,
  Popover,
  Spinner,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { toastr } from "react-redux-toastr";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { ValueType } from "react-select/src/types";
import { push } from "connected-react-router";
import Select from "react-select";
import { Grade } from "../../../../../../../store/groups/types";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";

enum DropdownSteps {
  MAIN,
  EDIT_STUDENT,
  ASSIGN_TEACHER,
  ASSIGN_CLASS,
}

type OwnProps = {
  editStudentRef: React.MutableRefObject<any>;
  student: Student;
  showDropdown: boolean;
  grade?: Grade;
  setShowDropdown: (value: boolean) => any;
};

type PropsFromState = {
  teachersRoster: Array<TeacherInfo>;
  modalsState: {
    teachersIndividuallyInviteModal: boolean;
  };
  raceInfo: RaceInfo;
  isLoading: {
    updateStudentDetails: boolean;
    getTeachers: boolean;
    assignTeachersToStudent: boolean;
    getRaceInfo: boolean;
  };
  errors: {
    updateStudentDetails?: string;
    assignTeachersToStudent?: string;
    getRaceInfo?: string;
  };
};

type DispatchProps = {
  updateTeacherStudentDetails: (student: Student, studentGrade: Grade) => any;
  openTeacherInviteIndividuallyModal: (
    newTeacher?: BasicUserInfo,
    callback?: Function
  ) => any;
  assignTeachersToStudent: (
    studentId: number,
    teachersIds: Array<number>
  ) => any;
  getAllClasses: () => any;
  getClassesLinkedToStudent: (studentId: number) => any;
  getTeachers: () => any;
  openInviteParentModal: () => any;
  changeSelectedStudent: (selectedStudent?: Student) => any;
  push: (location: string) => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const EditStudentDropdown: FunctionComponent<Props> = ({
  modalsState,
  editStudentRef,
  showDropdown,
  setShowDropdown,
  student,
  teachersRoster,
  isLoading,
  errors,
  updateTeacherStudentDetails,
  assignTeachersToStudent,
  getAllClasses,
  getClassesLinkedToStudent,
  getTeachers,
  openTeacherInviteIndividuallyModal,
  openInviteParentModal,
  changeSelectedStudent,
  push,
  raceInfo,
  grade,
}) => {
  const [showAsBackdrop, setShowAsBackdrop] = useState(false);
  const [dropdownStep, setDropdownStep] = useState(0);
  const [selectedTeachers, setSelectedTeachers] = useState<
    Array<ReactSelectOption<number>>
  >([]);
  const [isUpdateStudentID, setUpdateStudentID] = useState<boolean>();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [uniqueId, setUniqueId] = useState<string | undefined>();
  const [race, setRace] = useState<number | undefined>();
  const [gender, setGender] = useState<string | undefined>();
  const [freeOrReducedLunch, setFreeOrReducedLunch] = useState<boolean | undefined>(false);
  const [raceOtherValue, setRaceOtherValue] = useState<string | undefined>();
  const [specialEducation, setSpecialEducation] = useState<boolean | undefined>(false);
  const [ell, setELL] = useState<boolean | undefined>(false);
  const [sld, setSLD] = useState<boolean | undefined>(false);
  const [studentGrade, setStudentGrade] = useState<Grade | undefined>();

  const hasUpdatedStudentID = useRef(false);

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const { isCoach, isTeacher } = useUserRole();

  useEffect(() => {
    setUpdateStudentID(false)
  },[])

  useEffect(() => {
    if (!modalsState.teachersIndividuallyInviteModal) {
      setShowAsBackdrop(false);
    }
  }, [modalsState.teachersIndividuallyInviteModal]);

  useEffect(() => {
    if (!isLoading.updateStudentDetails) {
      if (errors.updateStudentDetails) {
        toastr.error("Error", errors.updateStudentDetails);
      } else {
        setShowDropdown(false);
      }
    }
  }, [isLoading.updateStudentDetails]);

  const teachersRosterAsOptions: Array<ReactSelectOption<number>> = useMemo(
    () =>
      teachersRoster.map((teacher) => {
        return {
          value: teacher.user.id,
          label: teacher.user.first_name
            ? teacher.user.first_name + " " + teacher.user.last_name
            : teacher.user.last_name,
        };
      }),
    [teachersRoster]
  );
  useEffect(() => {
    if(!hasUpdatedStudentID.current) {
      if(((student.unique_id != uniqueId) || (uniqueId == '')) && !isUpdateStudentID) {
        setUpdateStudentID(true);
        hasUpdatedStudentID.current = true;
      }
    }
  },[uniqueId, student.unique_id, isUpdateStudentID])

  const handleShow = () => {
    setDropdownStep(0);
    setSelectedTeachers(
      student.teachers!.map((teacher) => {
        return {
          value: teacher.id!,
          label: teacher.first_name
            ? teacher.first_name + " " + teacher.last_name
            : teacher.last_name,
        };
      })
    );
    setFirstName(student.first_name);
    setLastName(student.last_name);
    setUniqueId(student.unique_id);
    setRace(student.race);
    setRaceOtherValue(student.race_other_value);
    setGender(student.gender);
    setSpecialEducation(student.special_education);
    setELL(student.ell);
    setSLD(student.sld);
    setFreeOrReducedLunch(student.free_or_reduced_lunch);
    setStudentGrade(grade);
  };

  const handleUpdateStudent = () => {
    const updatedStudent: Student = {
      id: student.id,
      unique_id: uniqueId,
      first_name: firstName!,
      last_name: lastName!,
      race: race,
      race_other_value: raceOtherValue,
      gender: gender,
      special_education: specialEducation!,
      ell: ell!,
      sld: sld!,
      free_or_reduced_lunch: freeOrReducedLunch
    };
    updateTeacherStudentDetails(updatedStudent, studentGrade!);
  };

  const handleChangeTeachers = (
    options: ValueType<ReactSelectOption<number>, true>
  ) => {
     let difference = [...selectedTeachers
      .filter(((teacher:any) => !options?.some((option) => option.value == teacher.value)))];
    let isNotAllowed = (difference.length) && (currentUser.allowed_assigned_teachers_ids !== undefined) && currentUser.allowed_assigned_teachers_ids.length ? !currentUser.allowed_assigned_teachers_ids.includes(difference[0].value) : false;
    if(isNotAllowed){
      toastr.error('Alert!!','You can only remove the teacher(s) to which you have access.');
      return;
    }
    setSelectedTeachers((options as Array<ReactSelectOption<number>>) ?? []);
  };

  const handleSaveTeachers = () => {
    const selectedTeachersIds = selectedTeachers.map((t) => t.value);
    assignTeachersToStudent(student.id!, selectedTeachersIds);
    setShowDropdown(false);
  };

  const handleCreateTeacher = (value: string) => {
    const teacherNames = value.split(" ");
    const last_name = teacherNames.pop();
    const first_name = teacherNames.pop();
    const newTeacher = {
      first_name: first_name ? first_name : "",
      last_name: last_name ? last_name : "",
      email: "",
    };
   
    openTeacherInviteIndividuallyModal(
      newTeacher,
      (successfulInvites: SuccessfulInvite[]) => {
        const selectedTeachersIds = [
          ...selectedTeachers.map((t) => t.value),
          ...successfulInvites.map((invite) => invite.user.id),
        ];
        assignTeachersToStudent(student.id!, selectedTeachersIds);
      }
    );
  };

  const handleInviteParent = () => {
    setShowDropdown(false);
    changeSelectedStudent(student);
    push("/parents");
    openInviteParentModal();
  };

  const options: ReactSelectOption<number>[] = useMemo(() => {
    if(raceInfo?.races.length) {
      return [...raceInfo.races.map((race, index) => ({
        value: index, label: race 
     })), {value: 7, label: 'Other'}]
    } else {
      return [];
    }
  },[raceInfo]);
  const genderOptions: ReactSelectOption<string>[] = useMemo(() => genderList, []);

  const getDropdownBody = () => {
    switch (dropdownStep) {
      case DropdownSteps.MAIN: {
        const handleAssignToTeacher = () => {
          setDropdownStep(DropdownSteps.ASSIGN_TEACHER);
          getTeachers();
        };

        const handleAssignToClass = () => {
          setDropdownStep(DropdownSteps.ASSIGN_CLASS);
          getAllClasses();
          getClassesLinkedToStudent(student.id!);
        };

        return (
          <div className="defaultListGroup">
            <ListGroup.Item action>
              <div
                className="pointer"
                onClick={() => {
                  setDropdownStep(DropdownSteps.EDIT_STUDENT);
                  setUniqueId(student.unique_id);
                  setUpdateStudentID(false);
                  hasUpdatedStudentID.current = false;
                }}
              >
                Edit Student
              </div>
            </ListGroup.Item>
            <ListGroup.Item action>
              <div className="pointer" onClick={handleAssignToTeacher}>
                Assign to Teacher
              </div>
            </ListGroup.Item>
            {/* <ListGroup.Item action>
              <div className="pointer" onClick={handleAssignToClass}>
                Assign to Class
              </div>
            </ListGroup.Item> */}
            {/* <ListGroup.Item action>
              <div className="pointer" onClick={handleInviteParent}>
                Invite a Parent/Guardian
              </div>
            </ListGroup.Item> */}
          </div>
        );
      }
      case DropdownSteps.EDIT_STUDENT:
        return (
          <ListGroup.Item>
            {isUpdateStudentID 
            ? 
            <>
              <p className="text-center">
                You are changing the ID of <span className="font-weight-bold">{firstName+' '+lastName}</span>, 
                and this update will be applied anywhere that <span className="font-weight-bold">{firstName+' '+lastName}</span> appears in the platform 
                (*e.g., data period, intervention groups). Are you sure you want to continue?
              </p>
              <div className="btnActions">
                <button className="whiteBtnSm" onClick={() => {
                  setUniqueId(student.unique_id);
                  setUpdateStudentID(false);
                }}>Cancel</button>
                <button className="blueBtnSm" onClick={() => setUpdateStudentID(false)}>
                Continue</button>
              </div>
            </>

            :
            <>
              <div className="d-flex mb-2">
                <div
                  className="mr-3"
                  onClick={() => setDropdownStep(DropdownSteps.MAIN)}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"lg"}
                    className="pointer"
                  />
                </div>
                <span className="font-weight-bold">Edit Student:</span>
              </div>
              <div>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text justify-content-center"
                      style={{ width: "95px" }}
                    >
                      Name
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    value={firstName}
                    onChange={(e) =>
                      setFirstName((e.target as HTMLInputElement).value)
                    }
                    disabled={isCoach || isTeacher}
                  />
                  <input
                    className="form-control"
                    type="text"
                    value={lastName}
                    onChange={(e) =>
                      setLastName((e.target as HTMLInputElement).value)
                    }
                    disabled={isCoach || isTeacher}
                  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text justify-content-center"
                      style={{ width: "95px" }}
                    >
                      Student ID
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    value={uniqueId}
                    onChange={(e) =>
                      setUniqueId((e.target as HTMLInputElement).value)
                    }
                    disabled={isCoach || isTeacher}
                  />
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text justify-content-center"
                        style={{ width: "95px" }}
                      >
                        Race
                      </span>
                    </div>
                    <Select
                      isClearable
                      className="flex-1"
                      placeholder="Not Set"
                      isLoading={isLoading.getRaceInfo}
                      options={options}
                      value={options.find((r) => r.value === race)}
                      onChange={(value: any) => {
                        setRace(value ? value.value : undefined);
                        setRaceOtherValue(undefined);
                      }}
                    />
                  </div>
                  {race === Race.Other && (
                    <FormControl
                      className="mt-1"
                      placeholder="Enter Race Name..."
                      value={raceOtherValue ?? ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        setRaceOtherValue(value);
                      }}
                    />
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text justify-content-center"
                        style={{ width: "95px" }}
                      >
                        Gender
                      </span>
                    </div>
                    <Select
                      isClearable
                      className="flex-1"
                      placeholder="Not Set"
                      options={genderOptions}
                      value={genderOptions.find((g) => g.value === gender)}
                      onChange={(value: any) => {
                        setGender(value ? value.value : undefined);
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-6 pr-0">
                    <Form.Check 
                      type="switch"
                      id="special_education"
                      label="Special Education"
                      checked={specialEducation ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSpecialEducation(checked);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Form.Check 
                      type="switch"
                      id="ell"
                      label="ELL"
                      checked={ell ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setELL(checked);
                      }}
                    />
                    
                  </div>
                  <div className="col-3">
                    <Form.Check 
                      type="switch"
                      id="sld"
                      label="SLD"
                      checked={sld ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSLD(checked);
                      }}
                    />
                    
                  </div>
                  <div className="col-8">
                    <Form.Check 
                      type="switch"
                      id="freeOrReducedLunch"
                      label="Free or Reduced Lunch"
                      checked={freeOrReducedLunch ?? false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFreeOrReducedLunch(checked);
                      }}
                    />
                    
                  </div>
                
                
                </div>
                <div className="displayCentered">
                  <div className="blueBtnMd" onClick={handleUpdateStudent}>
                    Save
                    {isLoading.updateStudentDetails && (
                      <Spinner animation="border" size="sm" className="ml-3" />
                    )}
                  </div>
                </div>
              </div>
            </>
            }
          </ListGroup.Item>
        );
      case DropdownSteps.ASSIGN_TEACHER:
        return (
          <ListGroup.Item>
            <div className="d-flex mb-2">
              <div
                className="mr-3"
                onClick={() => setDropdownStep(DropdownSteps.MAIN)}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size={"lg"}
                  className="pointer"
                />
              </div>
              <span className="font-weight-bold">Assign to Teachers:</span>
            </div>
            <div className="row col-12">
              <div className="alert alert-warning px-2 py-1">
              If a teacher is removed, then student will be removed from all the classes of that Teacher.
              </div>
            </div>
            <div style={{ width: "330px" }}>
              <CreatableSelect
                isLoading={isLoading.getTeachers}
                isClearable
                isMulti
                placeholder="Select or type teacher..."
                value={selectedTeachers}
                onChange={handleChangeTeachers}
                onCreateOption={handleCreateTeacher}
                options={teachersRosterAsOptions.filter((teacher) => {
                  if(currentUser.allowed_assigned_teachers_ids !== undefined) {
                    if(currentUser.allowed_assigned_teachers_ids.length) {
                      return currentUser.allowed_assigned_teachers_ids.includes(teacher.value) 
                    } else {
                      return true;
                    }
                  } else {
                    return false;
                  }
                })}
                noOptionsMessage={() =>
                  (currentUser.allowed_assigned_teachers_ids !== undefined) ? ( 
                    <button
                      className="blueBtnSm"
                      onClick={() => {
                        setShowAsBackdrop(true);
                        openTeacherInviteIndividuallyModal(
                          undefined,
                          (teachers: any[]) => {
                            setShowDropdown(true);
                            setSelectedTeachers(
                              teachers.map((t) => t.user?.id)
                            );
                          }
                        );
                      }}
                    >
                      Invite Educator
                    </button>
                  ) as any
                  :
                  ('No teacher available')
                }
              />
            </div>
            <div className="displayCentered mt-2">
              <button className="blueBtnMd" onClick={handleSaveTeachers}>
                Save
                {isLoading.assignTeachersToStudent && (
                  <Spinner animation="border" size="sm" className="ml-3" />
                )}
              </button>
            </div>
          </ListGroup.Item>
        );
      // case DropdownSteps.ASSIGN_CLASS:
      //   return (
      //     <StudentClassesTab
      //       student={student}
      //       onBackClick={() => setDropdownStep(DropdownSteps.MAIN)}
      //     />
      //   );

      default:
        return null;
    }
  };

  return (
    <Overlay
      rootClose={true}
      onEnter={handleShow}
      onHide={() => setShowDropdown(false)}
      show={showDropdown || showAsBackdrop}
      target={editStudentRef.current}
      placement={"bottom"}
    >
      <Popover
        id="popover-basic"
        style={{ width: "100%", zIndex: showAsBackdrop ? 100 : undefined }}
      >
        <Popover.Content>
          <div
            className="d-flex justify-content-center"
            onClick={() => setShowDropdown(false)}
          >
            <FontAwesomeIcon
              icon={faCaretUp}
              className="pointer"
              size="lg"
              style={{ color: "#333333" }}
            />
          </div>
          {getDropdownBody()}
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    teachersRoster: onboarding.teachersRoster,
    modalsState: {
      teachersIndividuallyInviteModal:
        onboarding.modalsState.teachersIndividuallyInviteModal,
    },
    raceInfo: onboarding.raceInfo,
    isLoading: {
      updateStudentDetails: onboarding.isLoading.updateStudentDetails,
      assignTeachersToStudent: onboarding.isLoading.assignTeachersToStudent,
      getTeachers: onboarding.isLoading.getTeachers,
      getRaceInfo: onboarding.isLoading.getRaceInfo
    },
    errors: {
      updateStudentDetails: onboarding.errors.updateStudentDetails,
      assignTeachersToStudent: onboarding.errors.assignTeachersToStudent,
      getRaceInfo: onboarding.errors.getRaceInfo
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      updateTeacherStudentDetails: updateTeacherStudentDetails,
      assignTeachersToStudent: assignTeachersToStudent,
      updateClassesLinkedToStudent: updateClassesLinkedToStudent,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
      getAllClasses: getAllClasses,
      getClassesLinkedToStudent: getClassesLinkedToStudent,
      getTeachers: getTeachers,
      changeSelectedStudent: changeSelectedStudent,
      openInviteParentModal: openInviteParentModal,
      push: push
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStudentDropdown);