import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import {
  ElementaryGrade,
  EvidencePeriodData,
  EvidenceType,
  GradeLevels,
  MiddleHighGrade,
  Student,
} from "../../../../../../../store/onboarding/types";
import Select from "react-select";
import { Grade } from "../../../../../../../store/groups/types";
import StudentName from "../StudentName";
import TeacherNamesInline from "../TeacherNamesInline";
import { IS_READY_COACH } from "../../../../../../../constants";
import StudentGroupIconOverlay from "../StudentGroupIconOverlay";
import { InterventionGroup } from "../../../../../../../store/onboarding/cases/types";
import { getStudentGradeFromEcg } from "../Helpers";
import StudentClassTeacherOverlay from "../StudentClassTeacherOverlay";
import { UserInfo } from "../../../../../../../store/auth/types";

type OwnProps = {
  student: Student;
  rowGrade?: Grade;
  isReadonly?: boolean;
  draggable?: boolean;
  studentTeacherNames: string[];
  evidencePeriodData: EvidencePeriodData;
  userInfo?: UserInfo;
  handleGradeValueChange: (studentId: number, grade: Grade) => any;
  onDragStart: (event: any) => any;
  onDragEnd: () => any;
  showConfirmDialog: (confirmObj:any) => any;
  deleteStudentEntry: (studentEntryId: number) => any;
  isLoadingDeleteStdEntry?: boolean;
};

type Props = OwnProps;

type State = {
  isEditable: boolean;
};

class StudentCell extends Component<Props, State> {
  state: Readonly<State> = {
    isEditable: false,
  };

  handleOnClick = () => {
    if (!this.props.isReadonly) {
      this.setState({ isEditable: true });
    }
  };

  checkGradeLevelChangeForSRSS = (value: any) => {
    const { evidencePeriodData, student } = this.props;
    const screenerData = 
      evidencePeriodData.evidence_column_groups
      .find((ecg) => ecg.assessment?.special_case == 'srss');
    if(screenerData) {
      let screenerButton = screenerData.evidence_columns
          .find((ec) => ec.evidence_type == EvidenceType.BehaviorScreener);
      let screenerButtonData = screenerButton?.student_entries
                    .find((se) => se.student_id === student.id);
      let filteredScreenerData = screenerData.evidence_columns
          .filter((ec) => ec.evidence_type != EvidenceType.BehaviorScreener);
      let externalScreener = 
          filteredScreenerData.find((ec) => ec.measurement.column_name == 'SRSS External');
      let externalData = externalScreener?.student_entries.find((se) => se.student_id === student.id);
      
      let internalScreener = 
          filteredScreenerData.find((ec) => ec.measurement.column_name == 'SRSS Internal');
      let internalData = internalScreener?.student_entries.find((se) => se.student_id === student.id);
      if(externalData && internalData) {
          this.props.showConfirmDialog({
            onConfirm: async () => {
              await this.props.deleteStudentEntry(screenerButtonData?.entry_id!);
              await this.props.deleteStudentEntry(externalData?.entry_id!)
              await this.props.deleteStudentEntry(internalData?.entry_id!)
              this.props.handleGradeValueChange(this.props.student.id!, value);
              this.setState({ isEditable: false });
            },
            centered: true,
            size:'',
            title: "Confirm Alert",
            timerElipseLeft: true,
            countDownTimer: 3,
            text: (
              <>
                You are changing the grade for the student. Behavior screening data (if any ) for this student will be discarded.
              </>
            ),
            confirmButtonText: "Yes",
          });
      } else {
        this.props.handleGradeValueChange(this.props.student.id!, value);
        this.setState({ isEditable: false });
      }
    } else {
      this.props.handleGradeValueChange(this.props.student.id!, value);
      this.setState({ isEditable: false });
    }
  }

  handleGradeLevelChange = ({ value }: any) => {
    if(ElementaryGrade.includes(this.props.student.grade!)) {
      if(!ElementaryGrade.includes(value)) {
        this.checkGradeLevelChangeForSRSS(value);
      } else {
        this.props.handleGradeValueChange(this.props.student.id!, value);
        this.setState({ isEditable: false });
      }
    } else if(MiddleHighGrade.includes(this.props.student.grade!)) {
      if(!MiddleHighGrade.includes(value)) {
        this.checkGradeLevelChangeForSRSS(value);
      } else {
        this.props.handleGradeValueChange(this.props.student.id!, value);
        this.setState({ isEditable: false });
      }
    } else {
        this.props.handleGradeValueChange(this.props.student.id!, value);
        this.setState({ isEditable: false });
    }
  };
  

  getStudentGrade = (): Grade | undefined => {
    const { evidencePeriodData, student } = this.props;
    return getStudentGradeFromEcg(
      evidencePeriodData.evidence_column_groups,
      student.id!
    );
  };

  render() {
    const { isEditable } = this.state;
    const {
      draggable,
      student,
      rowGrade,
      studentTeacherNames,
    } = this.props;
    const grade = this.getStudentGrade();
    return (
      <Cell
        data-cy="student-cell"
        className="w-100"
        draggable={draggable}
        onDragStart={this.props.onDragStart}
        onDragEnd={this.props.onDragEnd}
      >
        <div className="d-flex align-items-center text-left ml-1">
          <div className="student-teacher-info" style={{width: '85px'}}>
            <StudentName student={student} canEdit={!this.props.isReadonly} grade={grade ?? rowGrade} />
            <TeacherNamesInline names={studentTeacherNames} />
          </div>
          <div className="student-teacher-info"  style={{width: '90px'}}>
            <StudentClassTeacherOverlay
              student={student}
            />
          </div>
          {isEditable ? (
            <div className="studentGradeSelect">
              <Select
                value={GradeLevels.filter((grade) => this.props.userInfo?.allowed_grades.length ? this.props.userInfo?.allowed_grades.includes(grade.value): true).find((gl) => gl.value === grade)}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                options={GradeLevels.filter((grade) => this.props.userInfo?.allowed_grades.length ? this.props.userInfo?.allowed_grades.includes(grade.value) : true)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menu: (styles) => ({ ...styles, width: 80 }),
                  menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                }}
                autoFocus
                openMenuOnFocus
                onChange={this.handleGradeLevelChange}
                onMenuClose={() => this.setState({ isEditable: false })}
                getOptionLabel={(option) => option.value}
              />
            </div>
          ) : (
            <p onClick={this.handleOnClick} className="studentGrade">
              {grade ? grade : rowGrade || "?"}
            </p>
          )}
        </div>
      </Cell>
    );
  }
}

export default StudentCell;
