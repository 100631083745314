import React, { FunctionComponent, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  getInterventionGroups,
  getInterventionGroupsByParent,
  activeTabUrl
} from "../../../../../store/onboarding/cases/actions";
import InterventionGroupPage from "../../../intervention-group-page/InterventionGroupPage";
import { RouteComponentProps, withRouter } from "react-router";
import MeetingEntryPage from "../../../intervention-group-page/MeetingEntryPage";
import { InterventionGroupTabs } from "../../../../../store/onboarding/meeting-module/types";
import useUserRole from "../../../../../utils/hooks/useUserRole";
// import InterventionGroupList from "./intervention-group-list/InterventionGroupList";
import InterventionPlanModal from "./assigment/intervention-plan-modal/InterventionPlanModal";
import { CoachDashboardTabKeys } from "../../CoachDashboard";
import { ApplicationState } from "../../../../../store";
import CaseLoadUserList from "./intervention-group-list/CaseLoadUserList";

type DispatchProps = {
  getInterventionGroups: () => any;
  getInterventionGroupsByParent: () => any;
  activeTabUrl: (tabName:string) => any;
};

type MatchParams = {
  groupId?: string;
  subPage?: InterventionGroupTabs;
  tabName: string;
  id?: string;
};

type Props = RouteComponentProps<MatchParams> & DispatchProps;

const YourAssignmentsTab: FunctionComponent<Props> = ({
  match: {
    params: { groupId, subPage, tabName, id },
  },
  //getInterventionGroups,
  getInterventionGroupsByParent,
}) => {

  const { isParent } = useUserRole();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isParent) {
      getInterventionGroupsByParent();
    } 
    else {
      if(tabName == CoachDashboardTabKeys.YOUR_BEHAVIOUR_ASSIGNMENTS
      || tabName == CoachDashboardTabKeys.YOUR_READING_ASSIGNMENTS) {
        dispatch(activeTabUrl(tabName))
        //getInterventionGroups();
      }
    }
  }, []);

  const isURLBlocked = useSelector((s: ApplicationState) => s.onboarding.isUrlBlocked);

  useEffect(() => {
    if(isURLBlocked && (window.location.pathname.includes('groups'))) {
      window.addEventListener("beforeunload", (ev) => {  
          ev.preventDefault();
          ev.returnValue = '';
      });
      return () => {
        window.removeEventListener("beforeunload", (ev) => {  
            ev.preventDefault();
            ev.returnValue = '';
        });
      } 
    }
  },[isURLBlocked])

   return (
    <div>
      {groupId ? (
        <div>
          {subPage === InterventionGroupTabs.MEETING ? (
            <MeetingEntryPage />
          ) : (
            <InterventionGroupPage groupId={+groupId} subPage={subPage} />
          )}
        </div>
      ) : (
         <CaseLoadUserList  isYourAssignments activeTab={tabName} />
        //  <InterventionGroupList isYourAssignments activeTab={tabName}/>
      )}
      <InterventionPlanModal studentUniqueId={id} />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      getInterventionGroups: getInterventionGroups,
      getInterventionGroupsByParent: getInterventionGroupsByParent,
      activeTabUrl: activeTabUrl
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(YourAssignmentsTab));
