import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import Select from "react-select";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { CoachInfo, TeacherInfo } from "../../../../../../store/onboarding/types";
import {  UserInfo } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { changeRecommendedGroup, patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { ValueType } from "react-select";
import { toastr } from "react-redux-toastr";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import { GroupRecommendation, InterventionGroup, InterventionGroupRequest } from "../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  interventionGroup?: InterventionGroup;
  groupRecommendation?: GroupRecommendation;
  manualGroup?: InterventionGroupRequest;
  setManualGroup?: (newValue: any) => any
  fromRecommendation?: boolean;
  fromManualGroup?: boolean;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
};

type Props = OwnProps;

const AdditionalEducatorsSelector: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { 
    interventionGroup, 
    fromRecommendation, 
    groupRecommendation, 
    fromManualGroup,  
    manualGroup, 
    setManualGroup, 
    isStudentDetail, 
    isGroupDetail } = props;
  const { isCoach, isTeacher } = useUserRole();
  let [additionalEducatorPlan, setAdditionalEducatorPlan] = useState<any>([]);
  const [showAdditionalEducatorsSelector, setShowAdditionalEducatorsSelector] = useState<boolean>(false);

  const intGrpAccessObj =  useSelector(
    (s: ApplicationState) => s.onboarding.intGrpAccessObj
  );

  let isReadOnlyAccessForGroup = useMemo(() => {
    if(intGrpAccessObj && interventionGroup && intGrpAccessObj.intGrpIDsWithReadAccess?.length) {
     let isReadonlyFlag = false;
      for (let i = 0; i < intGrpAccessObj.intGrpIDsWithReadAccess?.length; i++) {
        const intGrpRead = intGrpAccessObj.intGrpIDsWithReadAccess[i];
        if(intGrpRead == interventionGroup.id) {
          isReadonlyFlag = true;
          break;
        }
      }
      return isReadonlyFlag;
    }
  },[intGrpAccessObj, interventionGroup])
  
  const teachersRoaster = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRoster
  );

  const coachesRoster = useSelector<ApplicationState, CoachInfo[]>(
    (s) => s.onboarding.coachesRoster
  );

  const coaches = useMemo(() => {
    return coachesRoster.map((t) => t.user);
  }, [coachesRoster]);

  const teachers = useMemo(() => {
    return teachersRoaster.map((t) => t.user);
  }, [teachersRoaster]);

  const loadingGetTeachers = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getTeachers
  );
  const loadingGetCoaches = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.getCoaches
  );
  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const allEducators = useMemo(() => { //only use for additional educators
      const usersArray = [...coaches, ...teachers];
      const filteredUserArr = usersArray
                                .filter((user) => 
                                  interventionGroup 
                                    ? interventionGroup?.coach_assignment?.user.id != user.id
                                    : currentUser.id != user.id)

      const uniqueUsers: UserInfo[] = Object.values(
        filteredUserArr.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );
      let modifiedUsers = uniqueUsers.map((user) => ({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        profile: user.profile
      }));
      let modifiedAssoicate:any = [];
      if(interventionGroup?.associated_assignments.length) {
        modifiedAssoicate = [...interventionGroup?.associated_assignments.map((associate) => ({
           id: associate.user.id,
          first_name: associate.user.first_name,
          last_name: associate.user.last_name,
          profile: associate.user.profile
        }))]
      }
      return Object.values(
        [...modifiedUsers, ...modifiedAssoicate].reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      ) as any[]
  }, [coaches, teachers, interventionGroup?.associated_assignments]);

  useEffect(() => {
    if(!fromRecommendation && !fromManualGroup && interventionGroup!.additional_educator_plan != null) {
      setAdditionalEducatorPlan(interventionGroup!.additional_educator_plan);
    }
  },[])

  const onConfirmAdditionalEducator = (value: ValueType<UserInfo, true>, selectedOption:any) => {
    if(selectedOption.action == 'select-option') {
      let addEduName = 'yourself';
      if(currentUser?.id != selectedOption.option.id) {
        let userName = selectedOption.option.first_name+' '+selectedOption.option.last_name;
        addEduName = userName.indexOf(',') != -1 ? userName.replace(',', '') : userName;
      }
      dispatch(
        showConfirmDialog({
          title: "Confirmation",
          text: `You are adding ${addEduName} as an additional educator. Do you want to add ${addEduName} as an additional educator with edit rights or read-only rights? Please confirm.`
          ,
          onConfirm: (buttonType) => {
            handleAdditionalEducatorsChange(value, selectedOption, buttonType);
          },
          size:"lg",
          confirmButtonText: "Add with read-only rights",
          additionalButtonText: "Add with edit rights",
          isAdditionalButton: true
        })
      );
    } else {
      handleAdditionalEducatorsChange(value, selectedOption, false);
    }

  }
  

  const handleAdditionalEducatorsChange = (value: ValueType<UserInfo, true>, selectedOption:any, buttonType: any) => {
    if(!fromRecommendation && !fromManualGroup) {
      let difference = [...interventionGroup!.associated_assignments
      .filter(((associateAssign:any) => !value?.some((val) => val.id == associateAssign.user.id)))];
      let isNotAllowed = (difference.length) && (currentUser.allowed_assigned_teachers_ids !== undefined) && currentUser.allowed_assigned_teachers_ids.length ? !currentUser.allowed_assigned_teachers_ids.includes(difference[0].user.id) : false;
      if(isNotAllowed){
        toastr.error('Alert!!','You can only remove the teacher(s) to which you have access.');
        return;
      }
    }

     if(selectedOption.action == 'select-option') {
          let educatorPlan = [
            ...additionalEducatorPlan,
            {
              current_assignment_id: typeof selectedOption.option.profile.current_assignment === "number"
                                        ? selectedOption.option.profile.current_assignment
                                        : selectedOption.option.profile.current_assignment?.id,
              user_id: selectedOption.option.id,
              can_edit: buttonType
            }
          ]
          setAdditionalEducatorPlan(educatorPlan);
          
          if(fromRecommendation) {
            dispatch(
              changeRecommendedGroup(groupRecommendation!, {
                ...groupRecommendation!,
                associated_assignments: (value?.map((t) => 
                    typeof t.profile.current_assignment === "number"
                    ? t.profile.current_assignment
                    : t.profile.current_assignment?.id
                    ) || []) as any,
                additional_educator_plan: educatorPlan
              })
            );
          } else if(fromManualGroup) {
            setManualGroup!((prevState:any) => ({
              ...prevState,
              associated_assignments: (value?.map((t) => 
              typeof t.profile.current_assignment === "number"
              ? t.profile.current_assignment
              : t.profile.current_assignment?.id
              ) || []) as any,
              additional_educator_plan: educatorPlan
            }));
          } else {
            dispatch(
              patchInterventionGroup(interventionGroup!.id, {
                associated_assignments: (value?.map((t) => 
                    typeof t.profile.current_assignment === "number"
                    ? t.profile.current_assignment
                    : t.profile.current_assignment?.id
                    ) || []) as any,
                additional_educator_plan: educatorPlan
              })
            );
          }

      }


      if(selectedOption.action == 'remove-value') {
        let additionalEdu = additionalEducatorPlan ?  additionalEducatorPlan.filter((addEducationPlan:any) => addEducationPlan.user_id !== selectedOption.removedValue.id) : [];
        setAdditionalEducatorPlan(additionalEdu)
        if(fromRecommendation) {
          dispatch(
            changeRecommendedGroup(groupRecommendation!, {
              ...groupRecommendation!,
              associated_assignments: (value?.map((t) => 
                  typeof t.profile.current_assignment === "number"
                  ? t.profile.current_assignment
                  : t.profile.current_assignment?.id
                  ) || []) as any,
              additional_educator_plan: additionalEdu
            })
          );
        } else if(fromManualGroup) {
          setManualGroup!((prevState:any) => ({
            ...prevState,
            associated_assignments: (value?.map((t) => 
            typeof t.profile.current_assignment === "number"
            ? t.profile.current_assignment
            : t.profile.current_assignment?.id
            ) || []) as any,
            additional_educator_plan: additionalEdu
          }));
        } else {
          dispatch(
            showConfirmDialog({
              title: "Confirmation",
              text: 'Are you sure you want to remove this additional educator?',
              onConfirm: () => {
                dispatch(
                  patchInterventionGroup(interventionGroup!.id, {
                    associated_assignments: (value?.map((t) =>
                      typeof t.profile.current_assignment === "number"
                        ? t.profile.current_assignment
                        : t.profile.current_assignment?.id
                    ) || []) as any, // todo
                    additional_educator_plan: additionalEdu
                  })
                );
              },
              size:"sm",
              confirmButtonText: "Continue"
            })
          );
          
        }    
      }
  };
  //console.log(allEducators)
  return (
    <div className="position-relative">
      {showAdditionalEducatorsSelector || fromRecommendation || fromManualGroup ? (
      <Select
        autoFocus
        isMulti
        isLoading={loadingGetTeachers || loadingGetCoaches}
        onMenuClose={() => setShowAdditionalEducatorsSelector(false)}
        closeMenuOnSelect={false}
        styles={{
          container: (styles) => ({
            ...styles,
            minWidth: 120,
            margin: "5px",
          }),
          menu: (styles) => ({ ...styles, zIndex: 100 }),
        }}
        options={!loadingGetCoaches && !loadingGetTeachers ? 
                  allEducators.filter((teacher) => {
                  if(currentUser.allowed_assigned_teachers_ids !== undefined) {
                    if(currentUser.allowed_assigned_teachers_ids.length) {
                      return currentUser.allowed_assigned_teachers_ids.includes(teacher.id) 
                    } else {
                      return true;
                    }
                  } else {
                    return false;
                  }
                }): []}
        value={allEducators.filter((teacher) => {
          if(fromRecommendation) {
            return groupRecommendation!.associated_assignments?.includes(
              teacher.profile.current_assignment!.id
              )
          } else if(fromManualGroup) {
            return manualGroup!.associated_assignments?.includes(
              teacher.profile.current_assignment!.id
              )
          } else {
          return interventionGroup ? interventionGroup!.associated_assignments?.map((associate) => associate.id).includes(
            Object.prototype.hasOwnProperty.call(teacher.profile.current_assignment, 'id') 
              ? teacher.profile.current_assignment!.id : teacher.profile.current_assignment!
          ) : false;
          }
        }
        )}
        getOptionValue={(t) => t.id.toString()}
        getOptionLabel={(t) => getFullName(t)}
        onChange={(options: any, selectedOption) => 
          onConfirmAdditionalEducator(options, selectedOption)}
        placeholder="Additional Educator(s)..."
      />
      ) : (
        <>
          {interventionGroup ? interventionGroup!.associated_assignments
            .map((aa) => getFullName(aa.user))
            .join(", ") || "N/A" : ''}
          {!isStudentDetail && !isGroupDetail  && !isReadOnlyAccessForGroup 
          ? 
            <span
              className="pointer"
              onClick={() => setShowAdditionalEducatorsSelector(true)}
            >
              <FontAwesomeIcon icon={faEdit} className="ml-2" />
            </span> 
          : ''}
        </>
      )}
    </div>
  );
};

export default AdditionalEducatorsSelector;
