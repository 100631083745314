import React, { useEffect, useState } from "react";
import { StudentInfo } from "../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { callFromStudentDetail, getInterventionGroups, getStudentFromEductor } from "../../../../../store/onboarding/cases/actions";
// import InterventionGroupList from "../../../../pages/coach-dashboard/tabs/your-assigments/intervention-group-list/InterventionGroupList";
import { DataFilterState } from "../../../../../store/dataFilter/types";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { changeExitedStudentsGroup, changeShowArchivedGroups } from "../../../../../store/dataFilter/actions";
import CaseLoadUserList from "../../../../pages/coach-dashboard/tabs/your-assigments/intervention-group-list/CaseLoadUserList";

type Props = {
  student: StudentInfo;
};
enum DisplayedGroupsFilterValues {
  ACTIVE_GROUPS = "active",
  ARCHIVED_GROUPS = "archived",
  EXITED_GROUPS = "exited_groups",
}
const StudentsDetailsPage = ({ student }: Props) => {
  const { showArchivedGroups } = useSelector<ApplicationState, DataFilterState>(
    (s) => s.dataFilter
  );

  const { isDistrictOwner } = useUserRole();

  const displayGroup = useSelector((s: ApplicationState) => s.cases.displayGroupName);  

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if(displayGroup == DisplayedGroupsFilterValues.ARCHIVED_GROUPS) {
      dispatch(changeShowArchivedGroups(true));
      dispatch(changeExitedStudentsGroup(false))
    } else if(displayGroup == DisplayedGroupsFilterValues.EXITED_GROUPS) {
      dispatch(changeShowArchivedGroups(false));
      dispatch(changeExitedStudentsGroup(true))
    } else {
      dispatch(changeShowArchivedGroups(false));
      dispatch(changeExitedStudentsGroup(false))
    }
  },[displayGroup])

  useEffect(() => {
    dispatch(getStudentFromEductor(student));
    dispatch(callFromStudentDetail(true));
   // isStudentDetail ? dispatch(getInterventionGroups(showArchivedGroups, undefined, +student.id!)) : '';
  }, []);

  return (
    <div>
      <h4 className="mt-3">
        <strong>First Name:</strong> {student.first_name}
      </h4>
      <h4 className="mt-3">
        <strong>Last Name:</strong> {student.last_name}
      </h4>
      <hr />
      <div className="alert alert-warning">Student data is read-only. {!isDistrictOwner ? 'To edit student data access groups from Your Caseload in the sidebar' : ''}</div>
      <h4 className="mt-3 mb-2">
        <strong>Student Intervention Groups</strong>
      </h4>
     {/* <InterventionGroupList  student={student} />  */}
     <CaseLoadUserList  student={student} />
    </div>
  );
};

export default StudentsDetailsPage;
