import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCaretDown,
//   faCaretRight,
//   faPlusCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import { Collapse } from "react-bootstrap";
// import { InterventionGroupMap } from "../../../../../../utils/Mappers";
// import UserAvatarSmall from "../../../../../common/UserAvatarSmall";
import { push } from "connected-react-router";
// import { getFullName } from "../../../../../../utils/NamesUtils";
import { ApplicationState } from "../../../../../../store";
// import SvgCircle from "../common/SvgCircle";
import {
  // AlertReason,
  // GroupRecommendation,
  InterventionGroup,
} from "../../../../../../store/onboarding/cases/types";
// import useUserRole from "../../../../../../utils/hooks/useUserRole";
// import { useIntl } from "react-intl";
import {
  changeIntGroupAssigmentCollapsedSettings,
  interventionGroupAccess,
  //openInterventionStatsModal
} from "../../../../../../store/onboarding/actions";
import { Student } from "../../../../../../store/onboarding/types";
import InterventionGroupGridView from "./InterventionGroupGridView";
import GroupCard from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/GroupCard";
import { DataFilterState } from "../../../../../../store/dataFilter/types";
// import { openOverviewModel } from "../../../../../../store/groups/actions";
// import useUserAssignedToGroup from "./hooks/useUserAssignedToGroup";
import { useLocation } from "react-router";
//import { changeSelectedInterventionGroupId } from "../../../../../../store/tour/actions";
import { hideGroupRecommendationFromData } from "../../../../../../store/onboarding/cases/actions";
import useUnsavedGroupCards from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useUnsavedGroupCards";
import SetTimeModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/SetTimeModal";
import UnsaveConfirmModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/UnsaveConfirmModal";
//import useInterventionGroupsMap from "../intervention-group-list/hooks/useInterventionGroupsMap";

type StateProps = {
  showArchivedGroups?: boolean;
  selectedInterventionGroupId?: number;
  collapsedIntGroupAssignmentsIds: number[];
};

type DispatchProps = {
  push: (location: string) => any;
  changeIntGroupAssigmentCollapsedSettings: (args: {
    id: number;
    collapsed?: boolean;
  }) => any;
};

type OwnProps = {
  isReadonly?: boolean;
  isReadonlyCaseUser?: boolean;
  //interventionGroupMap: InterventionGroupMap;
  student?: Student;
  interventionGroups: InterventionGroup[];
  // normalizedNotFinishedGroups: GroupRecommendation[];
  // notFinishedGroups: InterventionGroup[];
  // onFinishGroup: any;
  // onUnsaveGroup: any;
  // onSaveName: any;
  // onChangeGoalType: any;
  // onChangeTier: any;
  // onChangeTeacher: any;
  // onChangeCoach: any;
  // onAddStudent: any;
  // onRemoveStudent: any;
  // onRemoveFocus: any;
  // onRemoveIntervention: any;
  // onDragDropStudent: any;
  // onClickSelectTime: any;
  // onInterventionLibraryModal: any;
  // onAddInterventionModal: any;
  // onDragInterventionEnd: any;
  // onDragInterventionUpdate: any;

  onDragStart: any;
  onDragEnd: any;
  teachers: any;
  coaches: any;
  //isLoadingPatchInterventionGroup: any;
  isTeacher: boolean;
  isCoach: boolean;
  isDistrictOwner: boolean;
  //shareDataWith?: boolean;
  notShareDataWith?: boolean;
  isYourAssignments?: boolean;
  caseId?: number;
  intGrpIDsWithReadAccess?: any[];
  onIntGrpIDsWithReadAccess?: (interventionGroupId: number) => any;
  intGrpIDsWithEditAccess?: any[];
  onIntGrpIDsWithEditAccess?: (interventionGroupId: number) => any;
  progressOverviewLoader?: boolean;
};

type Props = OwnProps & StateProps & DispatchProps;

const InterventionsGroup: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const {
    // normalizedNotFinishedGroups,
    // notFinishedGroups,
    // onFinishGroup,
    // onUnsaveGroup,
    // onSaveName,
    // onChangeGoalType,
    // onChangeTier,
    // onChangeTeacher,
    // onChangeCoach,
    // onAddStudent,
    // onRemoveStudent,
    // onRemoveFocus,
    // onRemoveIntervention,
    // onDragDropStudent,
    // onClickSelectTime,
    // onInterventionLibraryModal,
    // onAddInterventionModal,
    // onDragInterventionEnd,
    // onDragInterventionUpdate,
    //isLoadingPatchInterventionGroup,
    student,
    teachers,
    coaches,
    onDragStart,
    onDragEnd,
    //shareDataWith,
    notShareDataWith,
    intGrpIDsWithReadAccess,
    onIntGrpIDsWithReadAccess,
    intGrpIDsWithEditAccess,
    onIntGrpIDsWithEditAccess,

    interventionGroups,
    caseId,
    progressOverviewLoader
  } = props;

  const {
    normalizedNotFinishedGroups,
    notFinishedGroups,

    showUnsaveModal,
    showTimeModal,
    dayTime,
    isLoadingPatchInterventionGroup,
    isLoadingUnsaveInterventionGroup,

    onFinishGroup,
    onUnsaveGroup,
    onConfirmUnsaveGroup,
    onHideUnsaveGroup,
    onSaveName,
    onChangeGoalType,
    onChangeTier,
    onChangeTeacher,
    onChangeCoach,
    onAddStudent,
    onRemoveStudent,
    onRemoveFocus,
    onRemoveIntervention,

    onClickSelectTime,
    onHideSetTime,
    onSaveSetTime,
    onDragDropStudent,

    onInterventionLibraryModal,
    onAddInterventionModal,
    onDragInterventionEnd,
    onDragInterventionUpdate,
    } = useUnsavedGroupCards(caseId);

  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname); // Type as string

  const hasMounted = useRef(false);

  const openRecommendationGroupFromData = useSelector<ApplicationState, boolean>(
    (s) => s.cases.openRecommendationGroupFromData
  )

  useEffect(() => {
    // Only execute this effect if the component has mounted
    if (hasMounted.current) {
      setCurrentPath(location.pathname); // Update current path to the new path
    } else {
      hasMounted.current = true; // Mark that the component has mounted
    }
  }, [location]); // Depend on location changes

  useEffect(() => {
    if(!openRecommendationGroupFromData) {
      if(currentPath?.includes('groups')) {
        dispatch(changeIntGroupAssigmentCollapsedSettings({
          id: 0,
          collapsed: false
        }));
        setCollapsed(false);
      }
    } else {
      dispatch(hideGroupRecommendationFromData())
    }
  },[currentPath])

  const isStudentDetail = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);


  const tabName:any = useSelector<ApplicationState>((s)=> s.cases.tabName);
  
  // const {
  //   employeeAssignment,
  //   interventionGroups,
  //   unfinishedGroupsIds,
  // } = props.interventionGroupMap;
  const { showArchivedGroups, isReadonly, isReadonlyCaseUser, isTeacher, isCoach, isDistrictOwner } = props;


  //comment the below code as per RC-550 due to all group not closed
  // useEffect(() => {
  //   setCollapsed((c) =>
  //     c
  //       ? c
  //       : props.collapsedIntGroupAssignmentsIds.includes(employeeAssignment.id)
  //   );
  // }, [employeeAssignment]);


  let unfinishedGroupsIds = useMemo(() => {
    return notFinishedGroups?.map((intGrp) =>intGrp.id)
  }, [notFinishedGroups])

  const loggedInUserId = useSelector<
    ApplicationState
  >((s) => s.auth.userInfo?.id);

  let intGrpAccess = useMemo(() => {
    if(!isStudentDetail) {
      for (let j = 0; j < interventionGroups.length; j++) {
        const interventionGroup = interventionGroups[j];
        if (interventionGroup.coach_assignment?.user.id == loggedInUserId) { 
          if(!intGrpIDsWithEditAccess?.includes(interventionGroup.id)) {
            onIntGrpIDsWithEditAccess?.(interventionGroup.id)
          }
        } 
        else if (interventionGroup.teacher_assignment?.user.id == loggedInUserId) { 
          if(!intGrpIDsWithEditAccess?.includes(interventionGroup.id)) {
            onIntGrpIDsWithEditAccess?.(interventionGroup.id)
          }
        } 
        else if (interventionGroup.additional_educator_plan != null) { 
          if(interventionGroup.additional_educator_plan.some((addEduPlan) =>(addEduPlan.user_id == loggedInUserId) && addEduPlan.can_edit)
          ) {
            if(!intGrpIDsWithEditAccess?.includes(interventionGroup.id)) {
              onIntGrpIDsWithEditAccess?.(interventionGroup.id)
            }
          }
          if(interventionGroup.additional_educator_plan.some((addEduPlan) =>(addEduPlan.user_id == loggedInUserId) && !addEduPlan.can_edit)
          ) {
            if(!intGrpIDsWithReadAccess?.includes(interventionGroup.id)) {
              onIntGrpIDsWithReadAccess?.(interventionGroup.id)
            }
          }
        }
      }
      return {
        intGrpIDsWithReadAccess,
        intGrpIDsWithEditAccess
      }
    } else {
      let intGrpIDsWithReadAccess: any[] = [];
      let intGrpIDsWithEditAccess: any[] = [];
      return {
        intGrpIDsWithReadAccess,
        intGrpIDsWithEditAccess
      }
    }
      

  },[intGrpIDsWithReadAccess, intGrpIDsWithEditAccess]);


  useEffect(() => {
    const { intGrpIDsWithEditAccess, intGrpIDsWithReadAccess } = intGrpAccess;
    if(intGrpIDsWithEditAccess?.length || intGrpIDsWithReadAccess?.length) {
      dispatch(interventionGroupAccess({
        intGrpIDsWithEditAccess,
        intGrpIDsWithReadAccess
      }))
    }
  }, [intGrpAccess]);

 

  const unfinishedGroups = useMemo(() => {
    return unfinishedGroupsIds?.length
      ? normalizedNotFinishedGroups.filter((g) =>
          unfinishedGroupsIds.includes(g.id!)
        )
      : [];
  }, [unfinishedGroupsIds, normalizedNotFinishedGroups]);


  const { groupSort, exitedStudentsGroup } = useSelector<
    ApplicationState,
    DataFilterState
  >((s) => s.dataFilter);

  let exists;

  return (
    <>
    {interventionGroups?.filter((intGrp) => intGrp.finished).map((interventionGroup, index) => 
     {
       if(isStudentDetail && (isCoach || isTeacher) && !intGrpIDsWithReadAccess?.length) {
        return <InterventionGroupGridView
                  key={interventionGroup.id}
                  isReadonly={isReadonly}
                  showArchivedGroups={showArchivedGroups}
                  interventionGroup={interventionGroup}
                  student={props.student}
                  onDragDropStudent={onDragDropStudent(interventionGroup as any)}
                  exitedStudentsGroup={exitedStudentsGroup}
                  //shareDataWith={shareDataWith}
                  notShareDataWith={notShareDataWith}
                  caseId= {caseId}
                  progressOverviewLoader= {progressOverviewLoader}
                /> 
      } else if(isStudentDetail && isDistrictOwner) {
        return <InterventionGroupGridView
                  key={interventionGroup.id}
                  isReadonly={isReadonly}
                  showArchivedGroups={showArchivedGroups}
                  interventionGroup={interventionGroup}
                  student={props.student}
                  onDragDropStudent={onDragDropStudent(interventionGroup as any)}
                  exitedStudentsGroup={exitedStudentsGroup}
                  //shareDataWith={shareDataWith}
                  notShareDataWith={notShareDataWith}
                  caseId= {caseId}
                  progressOverviewLoader= {progressOverviewLoader}
                /> 
      } else {
          if(intGrpIDsWithEditAccess) { 
            if (intGrpIDsWithEditAccess.includes(interventionGroup.id)) {
              return <InterventionGroupGridView
                      key={interventionGroup.id}
                      isReadonly={isReadonly}
                      showArchivedGroups={showArchivedGroups}
                      interventionGroup={interventionGroup}
                      student={props.student}
                      onDragDropStudent={onDragDropStudent(interventionGroup as any)}
                      exitedStudentsGroup={exitedStudentsGroup}
                      //shareDataWith={shareDataWith}
                      notShareDataWith={notShareDataWith}
                      caseId= {caseId}
                      progressOverviewLoader= {progressOverviewLoader}
                    /> 
            } 
          }

          if(intGrpIDsWithReadAccess) {
            if(intGrpIDsWithReadAccess.includes(interventionGroup.id)) {
              return  <InterventionGroupGridView
                key={interventionGroup.id}
                isReadonly={isReadonly}
                showArchivedGroups={showArchivedGroups}
                interventionGroup={interventionGroup}
                student={props.student}
                onDragDropStudent={onDragDropStudent(interventionGroup as any)}
                exitedStudentsGroup={exitedStudentsGroup}
                //shareDataWith={shareDataWith}
                notShareDataWith={notShareDataWith}
                caseId= {caseId}
                progressOverviewLoader= {progressOverviewLoader}
              /> 
            }
          }
            
        //}
      }
    
  }
      // <InterventionGroupGridView
      //   key={interventionGroup.id}
      //   isReadonly={isReadonly}
      //   showArchivedGroups={showArchivedGroups}
      //   interventionGroup={interventionGroup}
      //   student={props.student}
      //   onDragDropStudent={onDragDropStudent(interventionGroup as any)}
      //   exitedStudentsGroup={exitedStudentsGroup}
      //   //shareDataWith={shareDataWith}
      //   notShareDataWith={notShareDataWith}
      //   caseId= {caseId}
      //   progressOverviewLoader= {progressOverviewLoader}
      // />
    )}
    
    {unfinishedGroups.length ?
      unfinishedGroups?.filter(
          (unfinish) =>
            (tabName == 'groups/behavior'
            ? unfinish.goal_type == 'behavior' 
            : unfinish.goal_type == 'academic')
          ).map((group) => {
        return <>
          {
            (exists = group.students.some(
              (v) => v.id === student?.id
            ))
          }
          {(student == undefined || exists) && (
            <GroupCard
              key={group.id}
              interventionGroup={notFinishedGroups.find(
                (ig) => group.id != null && ig.id === group.id //todo optimize
              )}
              groupRecommendation={group}
              onInterventionLibraryModal={onInterventionLibraryModal(
                group
              )}
              onAddInterventionModal={onAddInterventionModal(group)}
              teachers={teachers}
              student={student}
              coaches={coaches}
              isTeacher={isTeacher}
              isPatchLoading={isLoadingPatchInterventionGroup}
              onSaveGroup={() => onFinishGroup(group)}
              onRemoveGroup={() => onUnsaveGroup(group)}
              onSaveName={onSaveName(group)}
              onChangeGoalType={onChangeGoalType(group)}
              onChangeTier={onChangeTier(group)}
              onChangeTeacher={onChangeTeacher(group)}
              onChangeCoach={onChangeCoach(group)}
              onAddStudent={onAddStudent(group)}
              onRemoveStudent={onRemoveStudent(group)}
              onRemoveFocus={onRemoveFocus(group)}
              onRemoveIntervention={onRemoveIntervention(group)}
              onClickSelectTime={onClickSelectTime(group)}
              onDragDropStudent={onDragDropStudent(group)}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragInterventionEnd={onDragInterventionEnd(group)}
              onDragInterventionUpdate={onDragInterventionUpdate(
                group
              )}
            />
          )}
        </>
      }) : ''}
  
   <SetTimeModal
        showModal={showTimeModal}
        time={dayTime}
        onHide={onHideSetTime}
        onSave={onSaveSetTime}
        isLoading={isLoadingPatchInterventionGroup}
      />
      <UnsaveConfirmModal
        showModal={showUnsaveModal}
        onHide={onHideUnsaveGroup}
        onConfirm={onConfirmUnsaveGroup}
        isLoading={isLoadingUnsaveInterventionGroup}
      />

    </>
  );
};

const mapStateToProps = ({
  onboarding,
  dataFilter,
  tour,
  cases
}: ApplicationState): StateProps => {
  return {
    collapsedIntGroupAssignmentsIds: onboarding.collapsedIntGroupAssignmentsIds,
    showArchivedGroups: dataFilter.showArchivedGroups,
    selectedInterventionGroupId: tour.selectedInterventionGroupId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      push: push,
      changeIntGroupAssigmentCollapsedSettings: changeIntGroupAssigmentCollapsedSettings
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InterventionsGroup);
