import React, { FunctionComponent, useMemo } from "react";

import {
  ChecklistItem,
  CommonSteps,
  Intervention,
  InterventionGroup,
} from "../../../../../../../../../store/onboarding/cases/types";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import FidelityValueSpan from "./FidelityValueSpan";
import InterventionStepRow from "./InterventionStepRow";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import { UserInfo } from "../../../../../../../../../store/auth/types";
import { getFirstName, getFullName } from "../../../../../../../../../utils/NamesUtils";
import { DataFilterState } from "../../../../../../../../../store/dataFilter/types";
import { CaseLoadUser, CheckListItems, InterventionStatsDetail } from "../../../../../../../../../store/onboarding/types";


type OwnProps = {
  selectedInterventionStats?: InterventionStatsDetail
  selectedCommonStats?: CheckListItems[]
  selectedInterventionGroup?: InterventionGroup;
};

type Props = OwnProps;

const InterventionStatsTable: FunctionComponent<Props> = (props) => {
  const { selectedInterventionStats, selectedCommonStats, selectedInterventionGroup } = props;

  const educator = useSelector<ApplicationState, CaseLoadUser | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const overviewModelType = useSelector<ApplicationState, String | undefined>(
    (s) => s.groups.overviewModelType
  );

  // const selectedGroup = useSelector(
  //   (s: ApplicationState) => s.cases.selectedInterventionGroup
  // );
  
  // let interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
  //   (s) => s.cases.interventionGroups
  // );

//   const { showArchivedGroups } = useSelector<
//   ApplicationState,
//   DataFilterState
// >((s) => s.dataFilter);
// if(educator) interventionGroups = interventionGroups.filter((data, index) => data.teacher_assignment?.user.id == educator?.id);

// let uniqueIntervention: any[] = [];
// for (let i = 0; i < interventionGroups.length; i++) {
//   if(interventionGroups[i].finished && interventionGroups[i].interventions.length > 0){
//     interventionGroups[i].interventions.forEach((data, index)=> {
//       if(uniqueIntervention.indexOf(data.id) == -1){
//         uniqueIntervention.push(data);
//       }
//     })
//   }
// }


// let interventions:any = {};
// for (let ui = 0; ui < uniqueIntervention.length; ui++) {
//   let groupData = [];
//   let teachersData = [];
//   for (let j = 0; j < interventionGroups.length; j++) {
//     if(interventionGroups[j].finished && interventionGroups[j].interventions.length > 0){
//       let groups = interventionGroups[j].interventions.filter((int)=> int.id == uniqueIntervention[ui].id);
//       if(groups.length > 0) {
//         groupData.push(groups[0]);
//         teachersData.push(interventionGroups[j].teacher_assignment?.user)
//       } 
//     }
//   }
//   interventions[uniqueIntervention[ui].id] = {
//     'groups':groupData,
//     'teachers': Array.from(new Map(teachersData.map((item:any) => [item["id"], item])).values())
//   }
// }
// let interventionCount = stats.intervention !== undefined ?  interventions[stats.intervention!.id] : {};

// if((overviewModelType == 'all_group') || (overviewModelType == 'one_educator')) {
//   stats.groupsIds = (interventionCount != undefined) && Object.keys(interventionCount).length > 0 ? interventionCount.groups : 0;
//   stats.teachersIds = (interventionCount != undefined) && Object.keys(interventionCount).length > 0 ? interventionCount.teachers : 0;
// } 

 const groupsCountTitle = useMemo(() => {
   if(overviewModelType) {
     const gCount = selectedInterventionStats?.group_count;
      return gCount !== undefined
        ? `${gCount} group${(gCount > 1 ? "s": "")} ${(overviewModelType == 'all_group' ? " across " : "")}`
        : "";
   }
  }, [selectedInterventionStats?.group_count]);

  const teachersCountTitle = useMemo(() => {
    if(overviewModelType) {
    const tCount = selectedInterventionStats?.case_count;
    return tCount !== undefined && overviewModelType !== 'one_educator'
      ? `${tCount} teacher${tCount !== 1 ? "s" : ""}`
      : "";
    }
  }, [selectedInterventionStats?.case_count]);
  
  const params = useMemo(() => {
    if (selectedInterventionStats?.title) {
      return {
        title: selectedInterventionStats?.title,
        groupName: selectedInterventionGroup?.name,
        fidelity: selectedInterventionStats?.fedility_percentage,
        loggedTimes: `Logged ${selectedInterventionStats?.logged_times} time${
          selectedInterventionStats?.logged_times === 1 ? "" : "s"
        }${
          ((overviewModelType !== 'one_group')) && selectedInterventionStats?.logged_times
            ? ` (${groupsCountTitle}${
                groupsCountTitle ? " " : ""
              }${teachersCountTitle})`
            : ""
        }`,
      };
    }

    return {
      title: "Common Research-Based Instructional Components",
      fidelity: null,
      loggedTimes: '',
    };
  }, [selectedInterventionStats, selectedCommonStats, groupsCountTitle, teachersCountTitle]);

  

  let popOverMsg = '';

  if(overviewModelType === 'all_group') {
    popOverMsg = `These statistics are for ${params.title}. You are viewing an
    overview across teachers and groups.`;
  
  } else if(overviewModelType === 'one_educator') {
    if(params.title !== 'Common Research-Based Instructional Components') {
      popOverMsg = `These statistics are for ${params.title.toLocaleLowerCase()}. 
      You are viewing an overview of all ${educator?.case_name+"'s"} groups.`;
    } else {
      popOverMsg = `These statistics are for ${params.title.toLocaleLowerCase()}. 
      You are viewing an overview across interventions and groups for this teacher.`;
    }
  
  } else if(overviewModelType === 'one_group') {
    popOverMsg = `These statistics are for ${params.title}. 
    You are viewing fidelity statistics for ${params.groupName}.`;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-2">
        <OverlayTrigger
          overlay={
            <Popover id="overall-fidelity-popover">
              <Popover.Content>
                {popOverMsg}
              </Popover.Content>
            </Popover>
          }
        >
          <div>
            <h3>{params.title}</h3>
            {(params.title !== 'Common Research-Based Instructional Components') && <p className="mb-0">{params.loggedTimes}</p>}
          </div>
        </OverlayTrigger>
        <FidelityValueSpan
          fidelity={params.fidelity}
          style={{ fontSize: "x-large" }}
        />
      </div>

      <Table bordered responsive className="goalTable">
        <thead>
          <tr>
            <th>Intervention Step</th>
            <th className="text-center">Fidelity</th>
          </tr>
        </thead>
        <tbody>
          {(selectedInterventionStats?.checklist_items?.length || selectedCommonStats?.length) ? (
            (selectedInterventionStats?.checklist_items?.length) ? 
              selectedInterventionStats?.checklist_items.sort((a: any, b: any) => a.order > b.order ? 1 : -1).map((checklistItem: CheckListItems, index: number) => (
                // <InterventionStepRow
                //   key={index}
                //   checklistItem={ci}
                //   index={index}
                //   loggedTimes={stats.logged_times}
                // />
                <tr key={checklistItem.id}>
                      <td className={"font-weight-bold"}>
                        {checklistItem.id
                          ? `Step ${index + 1}: ${checklistItem.title}`
                          : checklistItem.title}
                          {/* <br/>{"============"}<br/>{checklistItem.common_step_name} */}
                      </td>
                      <td>
                        <div>
                          <OverlayTrigger
                            overlay={
                              <Popover id="particular-step-fidelity-popover">
                                <Popover.Content>
                                  {overviewModelType !== 'one_educator' ? 
                                    `This is the fidelity for this common step as well as how 
                                    many times it was implemented out of how many times 
                                    it occurred across all interventions and groups.`
                                  :
                                    `This is the fidelity for this common step as well as how 
                                    many times it was implemented out of how many times 
                                    it occurred across all interventions used by this teacher.`
                                  }
                
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <div className="text-center">{checklistItem.intervention_fedility_percentage} ({checklistItem.numerator} of {checklistItem.denominator})</div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
              ))
            :
            CommonSteps.map((step) => 
              selectedCommonStats?.sort((a: any, b: any) => a.order > b.order ? 1 : -1).map((ci: CheckListItems, index: number) => (
                (step.value == ci.title) && 
                // <InterventionStepRow
                //   key={index}
                //   checklistItem={ci}
                //   index={index}
                //   loggedTimes={stats.implChecks}
                //   selectedInterventionGroup = {selectedInterventionGroup}
                // />
                <tr key={ci.id}>
                      <td className={"font-weight-bold"}>
                        {ci.id
                          ? `Step ${index + 1}: ${ci.title}`
                          : ci.title}
                          {/* <br/>{"============"}<br/>{checklistItem.common_step_name} */}
                      </td>
                      <td>
                        <div>
                          <OverlayTrigger
                            overlay={
                              <Popover id="particular-step-fidelity-popover">
                                <Popover.Content>
                                  {overviewModelType !== 'one_educator' ? 
                                    `This is the fidelity for this common step as well as how 
                                    many times it was implemented out of how many times 
                                    it occurred across all interventions and groups.`
                                  :
                                    `This is the fidelity for this common step as well as how 
                                    many times it was implemented out of how many times 
                                    it occurred across all interventions used by this teacher.`
                                  }
                
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <div className="text-center">{ci.common_fidelity_percentage} ({ci.numerator} of {ci.denominator})</div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
              ))
            ) 
          ) : (
            <tr>
              <td colSpan={2}>No Data</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default InterventionStatsTable;
