import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { ValueType } from "react-select";
import { DataPeriod } from "../../../store/onboarding/types";
import { Overlay, Popover } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { patchDataPeriod, resetIsNewUploadedAssessment } from "../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import NewDataPeriodsModal from "./NewDataPeriodsModal";
import usePrevProps from "../../../utils/hooks/usePrevProps";
import useUserRole from "../../../utils/hooks/useUserRole";

type OwnProps = {
  small?: boolean;
};

type DispatchProps = {
  patchDataPeriod: (
    dataPeriodId: number,
    dataPeriod: Partial<DataPeriod>
  ) => any;
  resetIsNewUploadedAssessment: () => void;
};

type PropsFromState = {
  isLoading: {
    getDataPeriods: boolean;
    updateDataPeriod: boolean;
    uploadEvidenceData: boolean;
    addAssessmentToDataPeriod: boolean;
    uploadAssessmentData: boolean;
    uploadUndefinedAssessmentData: boolean;
  };
  dataPeriods: DataPeriod[];
  currentDataPeriod?: DataPeriod;
  isUploadedAssessment: boolean;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const CoachDataPeriodSelector: FunctionComponent<Props> = (props) => {
  const [showHintPopup, setShowHintPopup] = useState(false);
  const dataPeriodSelectorNode = useRef<any>(null);

  const [showNewDataPeriods, setShowNewDataPeriods] = useState<boolean>(false);
  const [newDataPeriods, setNewDataPeriods] = useState<DataPeriod[]>([]);

  const firstDataPeriodUpdate = useRef(true);

  const prevProps = usePrevProps({
    dataPeriods: props.dataPeriods,
    uploadEvidenceData: props.isLoading.uploadEvidenceData,
    addAssessmentToDataPeriod: props.isLoading.addAssessmentToDataPeriod,
    uploadAssessmentData: props.isLoading.uploadAssessmentData,
    uploadUndefinedAssessmentData:
      props.isLoading.uploadUndefinedAssessmentData,
  });

  useEffect(() => {
    if (firstDataPeriodUpdate.current  && !props.dataPeriods.length) {
      firstDataPeriodUpdate.current = false;
      return;
    }

    const newPeriods = props.dataPeriods.filter(
      (dp) => !prevProps?.dataPeriods.some((pdp) => pdp.id === dp.id)
    );

    //todo
    if (prevProps?.dataPeriods.length && newPeriods.length) {
      if (newPeriods.length > 1) {
        setNewDataPeriods(newPeriods);
        setShowNewDataPeriods(true);
      } 
      // else {
      //   //RC-996 comment the patch api calling. 
      //   //props.patchDataPeriod(newPeriods[0].id, { is_selected: true });
      // }
    }
    //RC-996 only selected data period would be set 
    //so that on reload page it would be auto selected in dropdown. 
    let filteredDataPeriod: DataPeriod[] = props.dataPeriods.filter((dp) => dp.is_selected);
    if(filteredDataPeriod.length && props.isUploadedAssessment) {
      props.patchDataPeriod(filteredDataPeriod[0].id, { is_selected: true, all_data_period_ids: props.dataPeriods.map((dp) => dp?.id) || []  });
      props.resetIsNewUploadedAssessment();
    }
  }, [props.dataPeriods]);

  useEffect(() => {
    if (
      (prevProps?.uploadEvidenceData && !props.isLoading.uploadEvidenceData) ||
      (prevProps?.uploadAssessmentData &&
        !props.isLoading.uploadAssessmentData) ||
      (prevProps?.uploadUndefinedAssessmentData &&
        !props.isLoading.uploadUndefinedAssessmentData) ||
      (prevProps?.addAssessmentToDataPeriod &&
        !props.isLoading.addAssessmentToDataPeriod)
    ) {
      setShowHintPopup(true);
    }
  }, [prevProps, props.isLoading]);

  const handleDataPeriodChange = (value: ValueType<DataPeriod, false>) => {
    if (value && props.currentDataPeriod?.id !== (value as DataPeriod).id) {
      props.patchDataPeriod((value as DataPeriod).id, { is_selected: true, all_data_period_ids: props.dataPeriods.map((dp) => dp?.id) || []});
    }
  };

  const handleCloseModal = () => {
    setShowNewDataPeriods(false);
    setNewDataPeriods([]);
  };

  const handleSelectNewDataPeriod = (dataPeriodId: number) => () => {
    props.patchDataPeriod(dataPeriodId, { is_selected: true, all_data_period_ids: props.dataPeriods.map((dp) => dp?.id) || [] });
    handleCloseModal();
  };

  const customStyles: any = useMemo(
    () => ({
      control: (base: any) => ({
        ...base,
        minHeight: 30,
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      valueContainer: (base: any) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base: any) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
      menu: (base: any) => ({ ...base, zIndex: 100 }),
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    }),
    []
  );

  const { isTeacher } = useUserRole();
  const { isCoach } = useUserRole();
  const myId = useSelector<ApplicationState, number | undefined>(
    (s) => s.auth.userInfo?.id
  );

  return (
    <>
      <div ref={dataPeriodSelectorNode as any} id="data-period-panel">
        <Select
          inputId="select-data-period"
          styles={props.small ? customStyles : undefined}
          isLoading={
            (!props.dataPeriods.length && props.isLoading.getDataPeriods) ||
            props.isLoading.updateDataPeriod
          }
          className="dataFiltersSelect"
          value={props.dataPeriods.find(
            (x) =>
              //props.currentDataPeriod && x.id === props.currentDataPeriod.id
              x.is_selected
          )}
          options={props.dataPeriods}
          getOptionLabel={(value: DataPeriod) =>
            `${value.name}${
              isTeacher && myId !== value.user_id ? ` (Shared by ${
                value.user_name ? value.user_name : value.user_email
              })` : ""
            }${
              isCoach && myId !== value.user_id
                ? ` (Shared by ${
                    value.user_name ? value.user_name : value.user_email
                  })`
                : ""
            }`
          }
          getOptionValue={(value: DataPeriod) => value.id.toString()}
          onChange={handleDataPeriodChange}
          menuPortalTarget={document.body} 
        />
      </div>
      <Overlay
        target={dataPeriodSelectorNode && dataPeriodSelectorNode.current}
        show={showHintPopup}
        // placement={"right"}
        rootClose
        rootCloseEvent={"click"}
        onHide={() => setShowHintPopup(false)}
      >
        <Popover id="popover-contained" style={{ zIndex: 1040 }}>
          <Popover.Content>
            Data uploaded for different data periods can be accessed with this
            drop down menu.
          </Popover.Content>
        </Popover>
      </Overlay>

      <NewDataPeriodsModal
        newDataPeriods={newDataPeriods}
        showModal={showNewDataPeriods}
        onClose={handleCloseModal}
        onSelect={handleSelectNewDataPeriod}
      />
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    isLoading: {
      getDataPeriods: onboarding.isLoading.getDataPeriods,
      updateDataPeriod: onboarding.isLoading.updateDataPeriod,
      uploadEvidenceData: onboarding.isLoading.uploadEvidenceData,
      addAssessmentToDataPeriod: onboarding.isLoading.addAssessmentToDataPeriod,
      uploadAssessmentData: onboarding.isLoading.uploadAssessmentData,
      uploadUndefinedAssessmentData:
      onboarding.isLoading.uploadUndefinedAssessmentData,
    },
    dataPeriods: onboarding.dataPeriods,
    currentDataPeriod: onboarding.currentDataPeriod,
    isUploadedAssessment: onboarding.isUploadedAssessment,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      patchDataPeriod: patchDataPeriod,
      resetIsNewUploadedAssessment: resetIsNewUploadedAssessment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachDataPeriodSelector);
