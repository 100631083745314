import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  GoalTypes,
  GroupRecommendation,
  Intervention,
  InterventionGroup,
  InterventionPlan,
} from "../../../../../../store/onboarding/cases/types";
import { Collapse, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import {
  Student,
  TeacherInfo,
  CoachInfo,
} from "../../../../../../store/onboarding/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCheck,
  faPencilAlt,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Specific, TierValues } from "../../../../../../store/groups/types";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";
import { IS_READY_COACH } from "../../../../../../constants";
import GroupCardGoalsSection from "../GroupCardGoalsSection";
import GoalTypeSelect from "../../../../onboarding/third-step/group-students/GoalTypeSelect";
import { connect, useDispatch, useSelector } from "react-redux";
import InterventionDayItem from "../../../../../pages/coach-dashboard/tabs/your-assigments/assigment/InterventionDayItem";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import InterventionsList from "./InterventionsList";
import StudentFocusesModal from "./student-focuses-modal/StudentFocusesModal";
import { changeInterventionPlanOrder } from "../../../../../utils";
import FocusSection from "./focus-section/FocusSection";
import AddStudentsPopover from "./AddStudentsPopover";
import InterventionistSelector from "./InterventionistSelector";
import InterventionSchedule from "./InterventionSchedule";
import TierSelector from "./TierSelector";
import { showConfirmDialog } from "../../../../../../store/confirm-dialog/actions";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { deleteInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { ApplicationState } from "../../../../../../store";
import AdditionalEducatorsSelector from "./AdditionalEducatorsSelector";
import { Dispatch, bindActionCreators } from "redux";
import { changeIntGroupInnerCollapsedSettings, openTeacherInviteIndividuallyModal } from "../../../../../../store/onboarding/actions";
import useUserAssignedToGroup from "../../../../../pages/coach-dashboard/tabs/your-assigments/assigment/hooks/useUserAssignedToGroup";
import { Cell } from "fixed-data-table-2";
import StudentGroupIconOverlay from "../../../../onboarding/second-step/evidence-tab/student-data-table/StudentGroupIconOverlay";
import InterviewTimeDayElements from "../../../../../pages/coach-dashboard/tabs/your-assigments/assigment/InterviewTimeDayElements";
import { toastr } from "react-redux-toastr";

type StateProps = {
  collapsedIntGroupInnerIds: any[];
};

type DispatchProps = {
  changeIntGroupInnerCollapsedSettings: (args: {
    accordianSetting: any;
    loggedInUserId: number;
    interventionGroupId: number;
  }) => any;
};

type Props = DispatchProps & StateProps & {
  groupRecommendation: GroupRecommendation;
  interventionGroup?: InterventionGroup;
  isPatchLoading?: boolean;
  teachers: any[];
  coaches?: CoachInfo[];
  isTeacher?: boolean;
  student?: Student;
  onInterventionLibraryModal?: () => void;
  onAddInterventionModal?: () => void;
  onSaveGroup?: () => void;
  onRemoveGroup?: () => void;
  onSaveName: (name: string) => void;
  onChangeTier: (tier: 1 | 2 | 3) => void;
  onChangeGoalType?: (type?: GoalTypes) => void;
  onChangeTeacher: (id: number) => void;
  onChangeCoach?: (id: number) => void;
  onDragDropStudent: (student: Student, group: GroupRecommendation) => void;
  onDragInterventionEnd?: () => void;
  onDragInterventionUpdate?: (interventionPlan: InterventionPlan) => void;
  onAddStudent: (student: Student) => void;
  onRemoveStudent: (student: Student) => void;
  onRemoveFocus: (focus: Specific) => void;
  onRemoveIntervention?: (intervention: Intervention) => void;
  onClickSelectTime?: (day: string) => void;
  onDragStart: (
    student: Student,
    group: GroupRecommendation
  ) => (event: any) => any;
  onDragEnd: () => any;
  isLoading?: boolean;
  fromRecommendation?: boolean;
};

const CustomToggle = React.forwardRef((props: any, ref: any) => {
  return (
    <h4 onClick={props.onClick} ref={ref} className="pointer">
      <FontAwesomeIcon icon={faPlusCircle} size="lg" />
    </h4>
  );
});

CustomToggle.displayName = "CustomToggle";

const MAX_STUDENT_DISPLAYED_NAMES_NUM = 8;

const GroupCard = (props: Props) => {
  const dispatch = useDispatch();
  const {
    interventionGroup,
    groupRecommendation,
    isTeacher,
    coaches,
    student,
    fromRecommendation,
  } = props;
//  console.log('groupRecommendation 2',groupRecommendation.student_goals)

  const { isCoach } = useUserRole();

  const [isEditName, setIsEditName] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>("");
  const [showStudentFocusesModal, setShowStudentFocusesModal] = useState<
    boolean
  >(false);
  const [showAllStuduntWithGoal, setStateShowAllStudentWithGoal] = useState<boolean>(false);
  const [showAllStuduntWithoutGoal, setStateshowAllStuduntWithoutGoal] = useState<boolean>(false);
  const [originalGroup, setOriginalGroup] = useState<
    GroupRecommendation | undefined
  >();
  const [additionalEducatorCollapsed, setadditionalEducatorCollapsed] = useState<boolean>(true);

  const { isCoachOrTeacharAssignedToGroup } = useUserAssignedToGroup(interventionGroup);

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const interventionGroups = useSelector(
    (s: ApplicationState) => s.cases.studentInterventionGroups
  )

  useEffect(() => {
    setOriginalGroup(props.groupRecommendation);
  }, []);

  // const firstUpdate = useRef(true);
  // useEffect(() => {
  //   if(prevGroupState) {
  //     let showStudentWarnArray:any[] = 
  //           prevGroupState?.finished ? 
  //             prevGroupState.students.filter(
  //               (student:any) => prevGroupState.student_goals.find(
  //                 (sg:any) => (student.id == sg.student.id) && !sg.archived))
  //           : prevGroupState.students;
  //     if(!prevGroupState?.finished && bothStudentGroupUpdated && (showStudentWarnArray.length <= 1) ) {
  //       if(firstUpdate.current){
  //       dispatch(deleteInterventionGroup(prevGroupState.id!));
  //       firstUpdate.current = false;
  //         return;
  //       }

  //     }
  //   }
  // }, [bothStudentGroupUpdated, prevGroupState])

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };
  
  const handleDrop =  (event: React.DragEvent) => {
    const studentData = event.dataTransfer.getData("student");
    const prevGroupData = event.dataTransfer.getData("prevGroup");
    if (studentData && prevGroupData) {
      const student = JSON.parse(studentData);
      const prevGroup = JSON.parse(prevGroupData);
      if (!groupRecommendation.students.some((s) => s.id === student.id)) {
        const isSourceRealGroup = prevGroup.id! > 0;
        const isTargetRealGroup = groupRecommendation.id! > 0;
        // prevent adding a student from a recommendation to a real group card
        if (!isTargetRealGroup && isSourceRealGroup) {
          return;
        }
        if (IS_READY_COACH && isSourceRealGroup) {
          let showStudentWarnArray:any[] = 
            prevGroup?.finished ? 
              prevGroup.students.filter(
                (student:any) => prevGroup.student_goals.find(
                  (sg:any) => (student.id == sg.student.id) && !sg.archived))
            : prevGroup.students;
          
            //setPrevGroupState(prevGroup);
            let isStudentWithGoal: boolean = prevGroup.student_goals.some(
              (sg:any) => (student.id == sg.student.id) && !sg.archived);  
    
            if(isStudentWithGoal) {
              dispatch(
                showConfirmDialog({
                  title: "Confirmation",
                  text: 
                    "Are you sure you want to move this student? Primary goals will be archived but do not move to the new group. Secondary goals will be deleted, not archived and do not move to the new group.",
                  showLastStudentWarning:  showStudentWarnArray.length > 1 ? false : true,
                  countDownTimer: showStudentWarnArray.length > 1 ? 0 : 3,
                  isFinished: prevGroup?.finished,
                  cancelButtonText: "No",
                  confirmButtonText: "Yes",
                  onConfirm: () => {
                    props.onDragDropStudent(student, prevGroup);
                  }
                })
              );
            } else {
              props.onDragDropStudent(student, prevGroup);
            }  
          
        } else {
          props.onDragDropStudent(student, prevGroup);
          //setPrevGroupState(prevGroup);
        }
      }
    }
  };

  const onDragInterventionUpdate = (result: DropResult) => {
    if (props.onDragInterventionUpdate && interventionGroup) {
      const { source, destination, draggableId } = result;
      if (destination && destination.droppableId === source.droppableId) {
        const { intervention_plan } = interventionGroup;
        const { interventions } = groupRecommendation;
        const changedPlan = changeInterventionPlanOrder(
          intervention_plan,
          interventions,
          source.index,
          destination.index,
          draggableId
        );
        props.onDragInterventionUpdate(changedPlan);
      }
    }
  };

  const onDragInterventionEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      props.onDragInterventionEnd &&
      destination &&
      !(
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
    ) {
      props.onDragInterventionEnd();
    }
  };

  const handleAddStudent = (student: Student) => {
    const originalStudent = originalGroup?.students.find(
      (s) => s.id! === student.id
    );
    props.onAddStudent(originalStudent ?? student);
  };

  const onEditName = () => {
    setIsEditName(true);
    setEditName(props.groupRecommendation.name);
  };

  const handleSaveName = () => {
    props.onSaveName(editName);
    setIsEditName(false);
  };

  const handleChangeName = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setEditName(target.value);
  };

  const handleTeacherChange = (value: ValueType<TeacherInfo, false>) => {
    if (value) {
      props.onChangeTeacher((value as TeacherInfo).user.id);
    }
  };

  const handleCoachChange = (value: ValueType<CoachInfo, false>) => {
    const id = value?.user.profile.current_assignment?.id;
    if (value && id && props.onChangeCoach) {
      props.onChangeCoach(id);
    }
  };

  const handleAddIntervention = () => {
    //TODO uncomment
    // if (
    //   groupRecommendation.goal_type === GoalTypes.ACADEMIC &&
    //   groupRecommendation.focuses.length > 0
    // ) {
    //   setShowStudentFocusesModal(true);
    // } else {
    props.onInterventionLibraryModal && props.onInterventionLibraryModal();
    // }
  };

  const showFocusSection = useMemo(() => {
    return (
      groupRecommendation.goal_type !== GoalTypes.BEHAVIOR &&
      (groupRecommendation.focuses.length > 0 ||
        groupRecommendation.students.some((s) => s.focuses?.length))
    );
  }, [
    groupRecommendation.goal_type,
    groupRecommendation.focuses.length,
    student,
  ]);

  const interventionist = useMemo(() => {
    let interventionistData =  isTeacher
      ? props.coaches
        ? props.coaches.filter((teacherAsCoach) => {
          if(currentUser.allowed_assigned_teachers_ids !== undefined) {
            if(currentUser.allowed_assigned_teachers_ids.length) {
              return currentUser.allowed_assigned_teachers_ids.includes(teacherAsCoach.user.id) 
            } else {
              return true;
            }
          } else {
            return false;
          }
        })
        : []
      : props.teachers.filter((teacher) => {
        if(currentUser.allowed_assigned_teachers_ids !== undefined) {
          if(currentUser.allowed_assigned_teachers_ids.length) {
            return currentUser.allowed_assigned_teachers_ids.includes(teacher.user.id) 
          } else {
            return true;
          }
        } else {
          return false;
        }
      })
      let dummyUserSuffix = '_no_coach';
      if(isCoach) {
        dummyUserSuffix = '_self_interventionist';
      } 
      // Find the index of the logged in user email
      let index = interventionistData.findIndex((interventionist) => {
        let interventionistEmail = interventionist.user.email.replace('@','') + dummyUserSuffix;
        let currentUserEmail = currentUser.email.replace('@','') + dummyUserSuffix;
        return interventionistEmail.includes(currentUserEmail)
      });

      if (index !== -1) {
          // Remove the element at the found index
          let [element] = interventionistData.splice(index, 1);
          // Add the element to the start of the array
          interventionistData.unshift(element);
      }

      return interventionistData;
  },[props.coaches, props.teachers])

  const handleGroupSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(interventionGroup) {
      if(!interventionGroup?.session_frequency || !interventionGroup?.session_duration) {
        toastr.error('Alert!!', 'Please make Intervention Schedule selections before moving on.')
        return;
      }
    }
    props.onSaveGroup && props.onSaveGroup();
  };


  const groupRecommendationStudents = groupRecommendation.students.filter(
    (item) => !student || item.id === student.id
  );

  const handleAdditionalEducatorCollapse = () => {
    let accordianSetting: any = {};
    let accordianGroupSetting: any = {};

    accordianGroupSetting[interventionGroup!.id]= {
      additionalEducatorCollapsed: !additionalEducatorCollapsed
    };
    let loggedInUserId = currentUser!.id;
    accordianSetting[loggedInUserId]= accordianGroupSetting;

    props.changeIntGroupInnerCollapsedSettings({
      accordianSetting: accordianSetting, loggedInUserId: loggedInUserId, interventionGroupId: interventionGroup!.id
    });
    setadditionalEducatorCollapsed((pS) => !pS);
  };

//  console.log('groupRecommendation 3',groupRecommendation.student_goals)

  return (
    <div
      data-cy="group-card"
      className="groupRecommendationCard"
      id={`group_card_${groupRecommendation?.id}`}
    >
      <div
        className={`groupRecommendationCardHeader ${
          groupRecommendation?.id !== undefined && groupRecommendation.id < 0
            ? "recommended"
            : "unfinished"
        }`}
      >
        <div className="font-weight-bold groupRecommendationName">
          {isEditName ? (
            <>
              <input
                className="stdInput"
                type="text"
                value={editName}
                onChange={handleChangeName}
              />
              <div onClick={handleSaveName} className="pointer">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1x"
                  style={{ color: "#ffffff" }}
                />
              </div>
            </>
          ) : (
            <>
              <h3>{groupRecommendation.name}</h3>
              <div onClick={onEditName} className="pointer">
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  size="1x"
                  style={{ color: "#ffffff" }}
                />
              </div>
            </>
          )}
        </div>

        {!isEditName && (
          <TierSelector
            groupTier={groupRecommendation.tier}
            onChangeTier={props.onChangeTier}
          />
        )}
      </div>
      <div className="groupRecommendationCardBody">
        <div>
        {IS_READY_COACH && interventionGroup && isCoachOrTeacharAssignedToGroup && (
          <>
          <div
            aria-controls={"additional-educator-collapse"}
            aria-expanded={additionalEducatorCollapsed}
            onClick={() => handleAdditionalEducatorCollapse()}
          >
            <div className="interventionAvg">
              <h5 className="section w-100">
                ADDITIONAL EDUCATOR(S)
              </h5>
                <span className="pointer pr-1 interventionAvgArrow">
                  <FontAwesomeIcon
                    icon={additionalEducatorCollapsed ? faCaretDown : faCaretLeft}
                    size="1x"
                  />
                </span>
            </div>
          </div>
          <Collapse in={additionalEducatorCollapsed}>
            <div id="additional-educator-collapse">
              <AdditionalEducatorsSelector 
              interventionGroup={interventionGroup}/>
            </div>
          </Collapse>
          </>
        )}

          {IS_READY_COACH && interventionGroup && (
            <>
              <h5 className="font-weight-bold text-uppercase">
                Intervention Schedule
              </h5>
              <InterviewTimeDayElements interventionGroup={groupRecommendation} />
              {/* <InterventionSchedule groupRecommendation={groupRecommendation} /> */}
            </>
          )}
          <form
            id={`group-card-form-${groupRecommendation.id}`}
            onSubmit={handleGroupSave}
            noValidate
          >
            {fromRecommendation ? 
              <>
                <h5 className="font-weight-bold">
                  ADDITIONAL EDUCATOR(S)
                </h5>
                <AdditionalEducatorsSelector
                  fromRecommendation={fromRecommendation}
                  groupRecommendation={ groupRecommendation }
                />
              </>
            : ''}
            {isCoachOrTeacharAssignedToGroup || fromRecommendation ? 
            <>
              <h5 className="font-weight-bold">
                {isTeacher ? "COACH" : "INTERVENTIONIST"}{" "}
                {isCoach 
                ? interventionist.some((interventionistUser) => 
                    interventionistUser.user.email?.indexOf('self_interventionist') != -1)
                  ?
                  <span className="pointer" onClick={() => dispatch(openTeacherInviteIndividuallyModal())}>
                    <FontAwesomeIcon icon={faPlusCircle} size="lg" title="Invite Interventionist" />
                  </span>
                  : '' 
                : ''}
              </h5>

              <InterventionistSelector
                Interventionists={interventionist}
                isTeacher={isTeacher}
                onInterventionistChange={
                  isTeacher ? handleCoachChange : handleTeacherChange
                }
                defaultInterventionistId={
                  isTeacher
                    ? groupRecommendation.default_coach_id
                    : groupRecommendation.default_interventionist_id
                }
              />
            </>
            : ''
            }

            {!isCoach &&
              (groupRecommendation?.id !== undefined &&
              groupRecommendation.id < 0
                ? true
                : false) && (
                <>
                  <h5 className="font-weight-bold">{"COACH"}</h5>

                  <InterventionistSelector
                    Interventionists={props.coaches ? props.coaches : []}
                    isTeacher={true}
                    isCoachSelector={true}
                    onInterventionistChange={handleCoachChange}
                    defaultInterventionistId={
                      groupRecommendation.default_coach_id
                    }
                  />
                </>
              )}
          </form>
          {showFocusSection && fromRecommendation ? (
            <FocusSection
              students={groupRecommendation.students}
              focuses={groupRecommendation.focuses}
              interventionGroup={groupRecommendation as InterventionGroup}
            />
          ): !interventionGroup?.finished ? 
          <FocusSection
              students={interventionGroup?.students || []}
              focuses={interventionGroup?.focuses || []}
              interventionGroup={interventionGroup}
            /> : ''}
          <h5 className="font-weight-bold text-uppercase">
            {fromRecommendation ? `Students ` : `Students With Goal `}
            {fromRecommendation ? <AddStudentsPopover
              onAdd={handleAddStudent}
              selectedStudents={groupRecommendation.students}
              groupRecommendation= { groupRecommendation }
              fromRecommendation = {fromRecommendation}
            /> : ''}
          </h5>
          <div
            className="groupInfoStudentContainer w-100 students-section"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {groupRecommendationStudents.filter(
                  (student:any) => {
                    if(!fromRecommendation) {
                      return interventionGroup?.student_goals.some(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived)
                    } else {
                      return student;
                    }
                  } 
                ).length ? (
              <>
                {(showAllStuduntWithGoal
                  ? groupRecommendationStudents.filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  )
                  : groupRecommendationStudents.filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  ).slice(0, MAX_STUDENT_DISPLAYED_NAMES_NUM)
                ).filter(
                  (student:any) => {
                    if(!fromRecommendation) {
                      return interventionGroup?.student_goals.some(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived)
                    } else {
                      return student;
                    }
                  } 
                ).map((student) => {
                  const studentInterventionGroups = interventionGroups ? interventionGroups[student.id!] : [];
                return  <div
                    className="studentGroupCell"
                    key={student.id}
                    draggable
                    onDragStart={props.onDragStart(student, groupRecommendation)}
                    onDragEnd={props.onDragEnd}
                  >
                  <div className="d-flex parentBlueStrip">
                  {fromRecommendation && studentInterventionGroups?.length 
                    ?
                    <Cell className="blueStrip">
                      <StudentGroupIconOverlay
                      interventionGroups={interventionGroups}
                      student={student}
                    />
                    </Cell>
                    :
                    '' 
                  }  
                    <OverlayTrigger
                      trigger={
                        student.focuses ||
                        groupRecommendation.goal_type === GoalTypes.ACADEMIC
                          ? ["hover", "focus"]
                          : []
                      }
                      overlay={
                        (student.focuses || student.teachers?.length) ? (
                            <Popover id={"skillFocusesPopover" || "studentTeacherPopover"}>
                              <Popover.Content>
                                <div className="font-weight-bold">Student: </div>
                                <div className="skillFocusPopoverItem">
                                  {getFullNameForStudent(student)}
                                </div>
                                {(student.focuses!= undefined) && student.focuses!.length ? 
                                  <div className="font-weight-bold pt-1">Area(s) of Need: </div> : '' }
                                {(student.focuses!= undefined) && student.focuses!.map((focus, index) => (
                                  <div
                                    className="skillFocusPopoverItem"
                                    key={index}
                                  >
                                    {focus.display_name}
                                  </div>
                                ))}
                                {student.teachers?.length ? 
                                  <div className="font-weight-bold pt-1">Teacher(s): </div> : '' }
                                {student.teachers?.map((teacher, index) => (
                                  <div
                                    className="skillFocusPopoverItem"
                                    key={index}
                                  >
                                    {`${teacher.first_name} ${teacher.last_name}`}
                                  </div>
                                ))}
                              </Popover.Content>
                            </Popover>
                          ) : groupRecommendation.goal_type ===
                            GoalTypes.ACADEMIC ? (
                            <Popover id={"skillFocusesEmptyPopover"}>
                              <Popover.Content>
                                <div>Empty</div>
                              </Popover.Content>
                            </Popover>
                          ) : (
                            <div />
                          )
    
                      }
                    >
                      <div className="d-flex grab w-100">
                        {student.grade && (
                          <div className="studentGroupCellGrade">
                            {student.grade}
                          </div>
                        )}
                        <div className="studentGroupCellName d-flex flex-column" style={{width: "100px"}}>
                          <span className="font-weight-bold">
                            {getFullNameForStudent(student)}
                          </span>
                          {student.teachers && student.teachers.length > 0 && (
                            <span className="small">
                              {`${student.teachers[0].first_name} ${student.teachers[0].last_name}`}
                              {student.teachers.length > 1 ? <span className="font-weight-bold"> +{student.teachers.length-1}</span> : ''}
                            </span>
                          )}
                        </div>
                        <div
                          className="studentGroupCellRemove"
                          onClick={() => props.onRemoveStudent(student)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            size="1x"
                            style={{ color: "#ffffff" }}
                          />
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                  </div>
                }
                )}
                {(!showAllStuduntWithGoal && (groupRecommendationStudents.filter(
                  (student:any) => {
                    if(!fromRecommendation) {
                      return interventionGroup?.student_goals.some(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived)
                    } else {
                      return student;
                    }
                  } 
                ).length > MAX_STUDENT_DISPLAYED_NAMES_NUM) && (
                  <OverlayTrigger
                    overlay={
                      <Popover id={"tooltip"}>
                        <Popover.Content>
                          {groupRecommendationStudents.filter(
                  (student:any) => {
                    if(!fromRecommendation) {
                      return interventionGroup?.student_goals.some(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived)
                    } else {
                      return student;
                    }
                  } 
                )
                            .slice(MAX_STUDENT_DISPLAYED_NAMES_NUM)
                            .map(getFullName)
                            .join(", ")}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <div
                      className="moreStudentsContainer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setStateShowAllStudentWithGoal(true);
                      }}
                    >
                      +{groupRecommendationStudents.filter(
                  (student:any) => {
                    if(!fromRecommendation) {
                      return interventionGroup?.student_goals.some(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived)
                    } else {
                      return student;
                    }
                  } 
                ).length - MAX_STUDENT_DISPLAYED_NAMES_NUM} more
                    </div>
                  </OverlayTrigger>
                ))}
              </>

            ) : (
              <i>No students</i>
            )}
          </div>
          {!fromRecommendation 
          ?
          <>
            <h5 className="font-weight-bold text-uppercase">
              <span className="mr-1">Students Without Goal</span> 
                <AddStudentsPopover
                onAdd={handleAddStudent}
                selectedStudents={groupRecommendation.students}
                groupRecommendation= { groupRecommendation }
                fromRecommendation = {fromRecommendation}
              />
            </h5>
            <div
              className="groupInfoStudentContainer w-100 students-section"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {groupRecommendationStudents.filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return !interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  ).length ? (
                <>
                  {(showAllStuduntWithoutGoal
                    ? groupRecommendationStudents.filter(
                      (student:any) => {
                        if(!fromRecommendation) {
                          return !interventionGroup?.student_goals.some(
                            (sg:any) => (student.id == sg.student.id) && !sg.archived)
                        } else {
                          return student;
                        }
                      } 
                    )
                    : groupRecommendationStudents.filter(
                      (student:any) => {
                        if(!fromRecommendation) {
                          return !interventionGroup?.student_goals.some(
                            (sg:any) => (student.id == sg.student.id) && !sg.archived)
                        } else {
                          return student;
                        }
                      } 
                    ).slice(0, MAX_STUDENT_DISPLAYED_NAMES_NUM)
                  )
                  .filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return !interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  )
                  .map((student) => (
                    <div
                      className="studentGroupCell"
                      key={student.id}
                      draggable
                      onDragStart={props.onDragStart(student, groupRecommendation)}
                      onDragEnd={props.onDragEnd}
                    >
                      <OverlayTrigger
                        trigger={
                          student.focuses ||
                          groupRecommendation.goal_type === GoalTypes.ACADEMIC
                            ? ["hover", "focus"]
                            : []
                        }
                        overlay={
                          (student.focuses || student.teachers?.length) ? (
                              <Popover id={"skillFocusesPopover" || "studentTeacherPopover"}>
                                <Popover.Content>
                                  {(student.focuses!= undefined) && student.focuses!.map((focus, index) => (
                                    <div
                                      className="skillFocusPopoverItem"
                                      key={index}
                                    >
                                      {focus.display_name}
                                    </div>
                                  ))}
                                  {student.teachers?.length ? 
                                    <div className="font-weight-bold pt-1">Teacher(s): </div> : '' }
                                  {student.teachers?.map((teacher, index) => (
                                    <div
                                      className="skillFocusPopoverItem"
                                      key={index}
                                    >
                                      {`${teacher.first_name} ${teacher.last_name}`}
                                    </div>
                                  ))}
                                </Popover.Content>
                              </Popover>
                            ) : groupRecommendation.goal_type ===
                              GoalTypes.ACADEMIC ? (
                              <Popover id={"skillFocusesEmptyPopover"}>
                                <Popover.Content>
                                  <div>Empty</div>
                                </Popover.Content>
                              </Popover>
                            ) : (
                              <div />
                            )

                        }
                      >
                        <div className="d-flex grab">
                          {student.grade && (
                            <div className="studentGroupCellGrade">
                              {student.grade}
                            </div>
                          )}
                          <div className="studentGroupCellName d-flex flex-column">
                            <span className="font-weight-bold">
                              {getFullNameForStudent(student)}
                            </span>
                            {student.teachers && student.teachers.length > 0 && (
                              <span className="small">
                                {`${student.teachers[0].first_name} ${student.teachers[0].last_name}`}
                                {student.teachers.length > 1 ? <span className="font-weight-bold"> +{student.teachers.length-1}</span> : ''}
                              </span>
                            )}
                          </div>
                          <div
                            className="studentGroupCellRemove"
                            onClick={() => props.onRemoveStudent(student)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              style={{ color: "#ffffff" }}
                            />
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  ))}
                  {(!showAllStuduntWithoutGoal && (groupRecommendationStudents.filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return !interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  ).length > MAX_STUDENT_DISPLAYED_NAMES_NUM) && (
                    <OverlayTrigger
                      overlay={
                        <Popover id={"tooltip"}>
                          <Popover.Content>
                            {groupRecommendationStudents
                              .slice(MAX_STUDENT_DISPLAYED_NAMES_NUM)
                              .map(getFullName)
                              .join(", ")}
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <div
                        className="moreStudentsContainer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStateshowAllStuduntWithoutGoal(true);
                        }}
                      >
                        +{groupRecommendationStudents.filter(
                    (student:any) => {
                      if(!fromRecommendation) {
                        return !interventionGroup?.student_goals.some(
                          (sg:any) => (student.id == sg.student.id) && !sg.archived)
                      } else {
                        return student;
                      }
                    } 
                  ).length - MAX_STUDENT_DISPLAYED_NAMES_NUM} more
                      </div>
                    </OverlayTrigger>
                  ))}
                </>

              ) : (
                <i>No students</i>
              )}
            </div>
          </>
          : ''}    
        </div>

        {IS_READY_COACH && interventionGroup && (
          <>
            {/* <div>
              <h5 className="font-weight-bold">GOAL TYPE</h5>
              <GoalTypeSelect
                value={groupRecommendation.goal_type}
                onChange={props.onChangeGoalType || (() => null)}
              />
            </div> */}
            <GroupCardGoalsSection group={interventionGroup} />
          </>
        )}

        {interventionGroup && (
          <div
            data-cy="group-interventions-area"
            className="groupRecommendationInterventions"
          >
            <h5 className="font-weight-bold d-flex w-100 justify-content-between align-items-center">
              INTERVENTION(S)
              {groupRecommendation.interventions.length > 0 &&
                (IS_READY_COACH ? (
                  <>
                    <span onClick={handleAddIntervention} className="pointer">
                      <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                    </span>
                    <StudentFocusesModal
                      showModal={showStudentFocusesModal}
                      setShowModal={setShowStudentFocusesModal}
                      interventionGroup={interventionGroup}
                    />
                  </>
                ) : (
                  <Dropdown className="m-1 recommendationsSelectInterventions">
                    <Dropdown.Toggle id="dropdown-basic" as={CustomToggle} />
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={props.onInterventionLibraryModal}
                      >
                        From Toolkit
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={props.onAddInterventionModal}
                      >
                        From School Resources
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
            </h5>
            <div className="d-flex flex-column align-items-center">
              {!groupRecommendation.interventions.length ? (
                <>
                  {IS_READY_COACH ? (
                    <button
                      data-cy="select-intervention-btn"
                      className="blueBtnSm mb-1"
                      disabled={!groupRecommendation.student_goals.length}
                      onClick={props.onInterventionLibraryModal}
                    >
                      SELECT INTERVENTION
                    </button>
                  ) : (
                    <>
                      <Dropdown className="m-1 recommendationsSelectInterventions">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          as={"button"}
                          className="blueBtnSm"
                        >
                          SELECT INTERVENTION
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as="button"
                            onClick={props.onInterventionLibraryModal}
                          >
                            From Toolkit
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={props.onAddInterventionModal}
                          >
                            From School Resources
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="recommendationsSchedule">
                        <div>
                          <p>Mon</p>
                        </div>
                        <div>
                          <p>Tue</p>
                        </div>
                        <div>
                          <p>Wed</p>
                        </div>
                        <div>
                          <p>Thu</p>
                        </div>
                        <div>
                          <p>Fri</p>
                        </div>
                      </div>
                    </>
                  )}

                  {groupRecommendation.student_goals.length ? (
                    <h4>First, find an Intervention</h4>
                  ) : (
                    <h4>First, set a Goal</h4>
                  )}
                </>
              ) : (
                <>
                  <DragDropContext
                    onDragUpdate={onDragInterventionUpdate}
                    onDragEnd={onDragInterventionEnd}
                  >
                    <InterventionsList
                      interventionGroupId={groupRecommendation.id!}
                      interventions={groupRecommendation.interventions}
                      interventionPlan={groupRecommendation.intervention_plan}
                      isLoading={props.isPatchLoading}
                      onRemoveIntervention={props.onRemoveIntervention}
                    />
                  </DragDropContext>
                  {!IS_READY_COACH && (
                    <InterventionSchedule
                      groupRecommendation={groupRecommendation}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="groupRecommendationCardButtons">
        {interventionGroup ? (
          <>
            {groupRecommendation.interventions.length > 0 && (
              <button
                data-cy="start-working-on-group-btn"
                className="blueBtnSm mb-2"
                type="submit"
                form={`group-card-form-${groupRecommendation.id}`}
              >
                {IS_READY_COACH
                  ? "Start Working on Group"
                  : "MOVE THIS GROUP TO YOUR GROUPS"}
              </button>
            )}
            {IS_READY_COACH ? (
              <button className="whiteBtnSm" onClick={props.onRemoveGroup}>
                DISCARD GROUP
              </button>
            ) : (
              <button className="whiteBtnSm" onClick={props.onRemoveGroup}>
                UNSAVE THIS GROUP
              </button>
            )}
          </>
        ) : (
          <>
            <button
              className="blueBtnSm mb-3"
              type="submit"
              form={`group-card-form-${groupRecommendation.id}`}
            >
              SAVE GROUP
            </button>
            <button className="whiteBtnSm" onClick={props.onRemoveGroup}>
              DISCARD GROUP
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  onboarding,
}: ApplicationState): StateProps => {
  return {
    collapsedIntGroupInnerIds: onboarding.collapsedIntGroupInnerIds
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeIntGroupInnerCollapsedSettings: changeIntGroupInnerCollapsedSettings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCard);
