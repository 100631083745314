import React, { Component, useEffect, useMemo, useState } from "react";
import { Col, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import {
  BasicUserInfo,
  CoachInfo,
  DataPeriod,
  EvidenceColumnGroup,
  EvidencePeriodData,
  EvidenceType,
  InvitesResponse,
  Student,
  SuccessfulInvite,
  TeacherInfo,
} from "../../../../../store/onboarding/types";
import CreatableSelect from "react-select/creatable";
import { ValueType } from "react-select/src/types";
import GroupCountIcon from "./common/GroupCountIcon";
import GroupingStudentsModal from "./GroupingStudentsModal";
import {
  GoalTypes,
  GroupRecommendation,
  InterventionGroupRequest,
} from "../../../../../store/onboarding/cases/types";
import { toastr } from "react-redux-toastr";
import { IS_READY_COACH } from "../../../../../constants";
import { Specific } from "../../../../../store/groups/types";
import {
  getBasicUserInfoFromString,
  getFullNameForStudent,
} from "../../../../../utils/StudentMapUtils";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getCoaches,
  getTeachers,
  isAllStudentsHaveDiagnosticInfo,
  openConfirmRecommendModal,
  openTeacherInviteIndividuallyModal,
} from "../../../../../store/onboarding/actions";
import {
  addStudentsToNewGroup,
  callFromStudentDetail,
  cancelCreateGroupManually,
  createInterventionGroup,
  openGroupingStudentsModal,
  removeAllStudentsFromNewGroup,
  removeStudentFromNewGroup,
  resetGoalChooseConcernModel,
} from "../../../../../store/onboarding/cases/actions";
import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import GoalTypeSelect from "./GoalTypeSelect";
import BehaviorDataTooltipContent from "./BehaviorDataTooltipContent";
import ReadingDataTooltipContent from "./ReadingDataTooltipContent";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import AddMoreData from "./AddMoreData";
import AdditionalEducatorsSelector from "../../../reading-interventions/intervention-tools/recommend-groups/group-card/AdditionalEducatorsSelector";

type PropsFromState = {
  userInfo?: UserInfo;
  invites: InvitesResponse;
  evidencePeriodData?: EvidencePeriodData;
  teachersRoster: Array<TeacherInfo>; //TODO change types and mapping for this component
  coachesRoster: Array<CoachInfo>;
  currentDataPeriod?: DataPeriod;
  newGroupStudents: Array<Student>;
  isEnterSpecificSkillActive: boolean;
  silentGroupCreation?: boolean;
  setGoalChooseConcernModel?: boolean;
  isLoading: {
    createInterventionGroup: boolean;
    getTeachers: boolean;
  };
  errors: {
    createInterventionGroup?: string;
  };
};

type DispatchProps = {
  getTeachers: () => any;
  createInterventionGroup: (interventionGroup: InterventionGroupRequest) => any;
  addStudentsToNewGroup: (students: Array<Student>) => any;
  removeStudentFromNewGroup: (studentId: number) => any;
  removeAllStudentsFromNewGroup: () => any;
  openGroupingStudentsModal: () => any;
  openConfirmRecommendModal: (hasAcademic?: boolean, hasBehavior?: boolean) => any;
  isAllStudentsHaveDiagnosticInfo: () => any;
  push: (location: string) => any;
  cancelCreateGroupManually: () => any;
  openTeacherInviteIndividuallyModal: (
    newTeacher?: BasicUserInfo,
    callback?: Function
  ) => any;
  resetGoalChooseConcernModel: (setGoalChooseConcernModel: boolean) => any;
};

type Props = PropsFromState & DispatchProps;

const DEFAULT_GROUP = {
  name: IS_READY_COACH ? "My Intervention Group" : "",
  associated_assignments: [],
  additional_educator_plan: [],
  students: [],
  goal_type: undefined,
};

const GroupStudents: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const [isEstablished, setIsEstablished] = useState<boolean>(false);
  const [isCommonSkills, setIsCommonSkills] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<InterventionGroupRequest>(
    DEFAULT_GROUP
  );
  const [skills, setSkills] = useState<Specific[]>([]);
  const [educators, setEducators] = useState<UserInfo[]>([]);

  const [hasBehavior, setHasBehavior] = useState<boolean>(false);
  const [hasAcademic, setHasAcademic] = useState<boolean>(false);

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const title = useMemo(
    () =>
      IS_READY_COACH
        ? "Create a New Intervention Group"
        : "Select student(s) for Intervention planning",
    []
  );

  const establishBtnTitle = useMemo(
    () => (IS_READY_COACH ? "Establish Goals" : "Add Group Info"),
    []
  );

  const { isTeacher } = useUserRole();

  useEffect(() => {
    if (isTeacher) {
      dispatch(getCoaches());
    }
    if(!props.teachersRoster.length && !props.isLoading.getTeachers) {
      props.getTeachers();
    }
  }, [isTeacher]);
  
  const coachesRoster = useMemo(() => {
    return props.coachesRoster.map((t) => t.user);
  }, [props.coachesRoster
  ]);

  const teachers = useMemo(() => {
    return props.teachersRoster.map((t) => t.user);
  }, [props.teachersRoster]);

  useEffect(() => {
    for (let i = 0; i < props.evidencePeriodData!.evidence_column_groups!.length; i++) {
      const evidanceColumnGroup: EvidenceColumnGroup = props.evidencePeriodData!.evidence_column_groups[i];
      if(evidanceColumnGroup.is_primary_screening) {
        setHasAcademic(evidanceColumnGroup.is_primary_screening)
      } 
      if(evidanceColumnGroup.is_behaviour_primary_screening) {
        setHasBehavior(evidanceColumnGroup.is_behaviour_primary_screening)
      } 
      
    }
   
  }, [props.evidencePeriodData]);
  

  const allEducators = useMemo(() => {
    return [...coachesRoster, ...teachers];
  }, [coachesRoster, teachers]);

  useEffect(() => {
    if (isTeacher) {
      setEducators(coachesRoster);
    } else {
      setEducators(teachers);
    }
  }, [teachers, coachesRoster, isTeacher]);

  const onSuccess = () => {
    if (IS_READY_COACH) {
        props.setGoalChooseConcernModel == false ? props.openGroupingStudentsModal() : '';
    }
  };

  useLoading(
    props.isLoading.createInterventionGroup,
    props.errors.createInterventionGroup,
    onSuccess
  );

  useEffect(() => {
    generateSkillList();
  }, [props.newGroupStudents, props.evidencePeriodData]);

  useEffect(() => {
    if (IS_READY_COACH) {
      const { successful_invites } = props.invites;
      setEducators((teachers) => [
        ...teachers,
        ...successful_invites.map((invite) => invite.user),
      ]);
    }
  }, []);

  const generateSkillList = () => {
    const { evidencePeriodData, newGroupStudents } = props;
    const diagnosticTagsMap = new Map<Specific, number>();
    const result: Specific[] = [];
    if (evidencePeriodData) {
      evidencePeriodData.evidence_column_groups.forEach((ecg) => {
        const diagColumn = ecg.evidence_columns.find(
          (ec) => ec.evidence_type === EvidenceType.DiagnosticInfo
        );
        if (diagColumn) {
          diagColumn.student_entries.forEach((se) => {
            if (
              se.diagnostic_tags &&
              se.diagnostic_tags.length > 0 &&
              newGroupStudents.some((student) => student.id === se.student_id)
            ) {
              se.diagnostic_tags.forEach((dt) => {
                const identifier = dt[dt.tag_type];
                if (identifier) {
                  const tag: Specific = {
                    identifier: identifier,
                    display_name: dt.display_name,
                  };
                  const tagInMap = Array.from(diagnosticTagsMap.keys()).find(
                    (t) => t.identifier === identifier
                  );
                  if (tagInMap) {
                    diagnosticTagsMap.set(
                      tagInMap,
                      diagnosticTagsMap.get(tagInMap)! + 1
                    );
                  } else {
                    diagnosticTagsMap.set(tag, 1);
                  }
                }
              });
            }
          });
        }
      });
    }
    diagnosticTagsMap.forEach((amount, tag) => {
      if (
        amount === props.newGroupStudents.length &&
        !result.some((t) => t.identifier === tag.identifier)
      )
        result.push(tag);
    });
    if (result.length === 0) {
      diagnosticTagsMap.forEach((amount, tag) => {
        if (!result.some((t) => t.identifier === tag.identifier))
          result.push(tag);
      });
      setIsCommonSkills(false);
      setSkills(result);
    } else {
      setIsCommonSkills(true);
      setSkills(result);
    }
  };

  const getSkillsNamesForStudent = (student: Student): Array<String> => {
    const { evidencePeriodData } = props;
    const result: String[] = [];
    if (evidencePeriodData) {
      evidencePeriodData.evidence_column_groups.forEach((ecg) => {
        const diagColumn = ecg.evidence_columns.find(
          (ec) => ec.evidence_type === EvidenceType.DiagnosticInfo
        );
        if (diagColumn) {
          diagColumn.student_entries.forEach((se) => {
            if (
              se.diagnostic_tags &&
              se.diagnostic_tags.length > 0 &&
              student.id === se.student_id
            ) {
              se.diagnostic_tags.map((dt) => {
                result.push(dt.display_name);
              });
            }
          });
        }
      });
    }
    return result;
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (event: any) => {
    const data = event.dataTransfer.getData("student");
    if (data) {
      const student = JSON.parse(data);
      if (!props.newGroupStudents.some((s) => s.id === student.id)) {
        props.addStudentsToNewGroup([student]);
      }
    }
  };

  const handleStudentRemove = (student: Student) => () => {
    props.removeStudentFromNewGroup(student.id!);
  };

  const handleGoalsEstablish = () => {
    setIsEstablished(true);
    setNewGroup(DEFAULT_GROUP);
  };

  const handleGoalsClose = () => {
    setIsEstablished(false);
    setNewGroup(DEFAULT_GROUP);
    props.removeAllStudentsFromNewGroup();
  };

  const handleAssignedTeacherChange = (
    newValue?: ValueType<UserInfo, false>
  ) => {
    setNewGroup((prevState) => ({
      ...prevState,
      [isTeacher ? "coach_assignment" : "teacher_assignment"]: newValue
        ? (newValue as UserInfo).profile.current_assignment!.id
        : undefined,
    }));
  };

  // const handleAssociatedTeacherChange = (
  //   newValues?: ValueType<UserInfo, true>
  // ) => {
  //   setNewGroup((prevState) => ({
  //     ...prevState,
  //     associated_assignments: newValues
  //       ? (newValues as Array<UserInfo>).map((v) => v.profile.current_assignment!.id)
  //       : [],
  //   }));
  // };

  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setNewGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGroupGoalTypeChange = (value: string) => {
    setNewGroup((prevState) => ({
      ...prevState,
      goal_type: value as GoalTypes,
    }));
  };

  const resetForm = () => {
    setIsEstablished(false);
    setIsCommonSkills(false);
    setNewGroup(DEFAULT_GROUP);
    setSkills([]);
  };

  const handleGroupCreate = () => {
    const interventionGroup: InterventionGroupRequest = {
      ...newGroup,
      teacher_assignment: newGroup.teacher_assignment,
      students: props.newGroupStudents
        .filter((s) => s.id != null)
        .map((s) => s.id!),
      focuses: skills.map((s) => s.identifier),
    };

    props.createInterventionGroup(interventionGroup).then(
      () => {
        resetForm();
        dispatch(callFromStudentDetail(false))
        props.cancelCreateGroupManually();
        if (!IS_READY_COACH) {
          const { currentDataPeriod } = props;
          props.push(
            currentDataPeriod
              ? "/group_recommendations/" + currentDataPeriod.id
              : ""
          );
        }
      },
      (err: string) => toastr.error("Failed to add the intervention group", err)
    );
  };

  const handleRecommendGroups = () => {
    if (props.isAllStudentsHaveDiagnosticInfo()) {
      if (IS_READY_COACH) {
        let queryStr:string = '';
        
        if(hasBehavior) {
          queryStr = '/behavior';
        } else {
          queryStr = '/reading';
        }
        props.push("/cases/groups" + queryStr);
      } else {
        const { currentDataPeriod } = props;
        props.push(
          currentDataPeriod
            ? "/group_recommendations/" + currentDataPeriod.id
            : ""
        );
      }
    } else {
      props.openConfirmRecommendModal(hasAcademic, hasBehavior);
    }
  };

  const handleInviteTeacher = (input: string) => {
    props.openTeacherInviteIndividuallyModal(
      getBasicUserInfoFromString(input),
      (teachers: SuccessfulInvite[]) => {
        setNewGroup((prevState) => ({
          ...prevState,
          teacher_assignment: teachers?.[0]?.user.profile.current_assignment!.id,
        }));
      }
    );
  };

  const handleInviteAssociatedTeachers = (input: string) => {
    props.openTeacherInviteIndividuallyModal(
      getBasicUserInfoFromString(input),
      (teachers: SuccessfulInvite[]) => {
        setNewGroup((prevState) => ({
          ...prevState,
          associated_assignments: teachers?.map((t) => t.user.profile.current_assignment!.id),
        }));
      }
    );
  };

  const handleInviteAndAssignTeacher = () => {
    props.openTeacherInviteIndividuallyModal(
      undefined,
      (teachers: SuccessfulInvite[]) => {
        setNewGroup((prevState) => ({
          ...prevState,
          teacher_assignment: teachers?.[0]?.user.profile.current_assignment!.id,
        }));
      }
    );
  };

  const handleInviteAndAssignAssociatedTeacher = () => {
    props.openTeacherInviteIndividuallyModal(
      undefined,
      (teachers: SuccessfulInvite[]) => {
        setNewGroup((prevState) => ({
          ...prevState,
          associated_assignments: teachers.map((t) => t.user.profile.current_assignment!.id),
        }));
      }
    );
  };

  const { newGroupStudents: students, currentDataPeriod } = props;
  const hasStudent = students.length > 0;

  const interventionistId = useMemo(
    () => (isTeacher ? newGroup.coach_assignment : newGroup.teacher_assignment),
    [isTeacher, newGroup.coach_assignment, newGroup.teacher_assignment]
  );

  return (
    <div className={IS_READY_COACH ? "d-flex" : ""}>
      {IS_READY_COACH && <AddMoreData className="mr-3" />}

      <div className="onboardingContainer w-100">
        <div className="d-flex justify-content-between flex-column">
          {IS_READY_COACH ? (
            <div className="d-flex justify-content-around">
              <h2
                className="font-weight-bold text-center m-md-0 mb-xl-2 flex-grow-1"
              // style={{ marginBottom: 0 }}
              >
                {title}
              </h2>
              {hasStudent && (
                <button
                  className="whiteBtnSm"
                  onClick={handleGoalsClose}
                  style={{}}
                >
                  Cancel
                </button>
              )}
            </div>
          ) : (
            <h2 className="font-weight-bold orangeText text-center">{title}</h2>
          )}
          <div className="d-flex flex-row align-items-center">
            {hasStudent &&
              !IS_READY_COACH &&
              (!isEstablished ? (
                <>
                  <button className="blueBtnMd" onClick={handleGoalsEstablish}>
                    {establishBtnTitle}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="whiteBtnMd2"
                    onClick={handleGoalsClose}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="blueBtnMd"
                    disabled={
                      !newGroup.name.length || !newGroup.teacher_assignment
                    }
                    onClick={handleGroupCreate}
                  >
                    Create it!
                  </button>
                </>
              ))}
            {!IS_READY_COACH && (
              <>
                {!hasStudent && (
                  <button
                    className="blueBtnMd"
                    disabled={!currentDataPeriod}
                    onClick={handleRecommendGroups}
                  >
                    RECOMMEND GROUPS
                  </button>
                )}
                <div className="ml-3" onClick={props.cancelCreateGroupManually}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="pointer"
                    size={"2x"}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {!hasStudent ? (
          <>
            <div
              data-cy="students-drop-container"
              className="studentGroupDropContainer"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p className="text-center">
                To create your first group <strong>drag</strong> the
                students&apos; names here
              </p>
            </div>
          </>
        ) : (
          <div
            data-cy="students-drop-container"
            className="groupInfo mb-md-0"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Row data-cy="create-group-area" className="m-0">
              <Col
                md={2}
                sm={3}
                style={{ borderRight: "1px #cfd8dc solid" }}
                className="circleGroupStudents p-0"
              >
                <div className="groupCount">
                  <GroupCountIcon svgSize={80} studentsNum={students.length} />
                </div>
              </Col>
              <Col className="p-0">
                <Row className="m-0">
                  <Col sm={12}>
                    <h4 className="font-weight-bold mb-0 d-inline">
                      Students: &nbsp;
                    </h4>
                    {students.map((student) => (
                      <div className="studentGroupCell" key={student.id}>
                        <OverlayTrigger
                          overlay={
                            getSkillsNamesForStudent(student).length > 0 ? (
                              <Popover id={"skillFocusesPopover"}>
                                <Popover.Content>
                                  {getSkillsNamesForStudent(student).map(
                                    (name, index) => (
                                      <div
                                        className="skillFocusPopoverItem"
                                        key={index}
                                      >
                                        {name}
                                      </div>
                                    )
                                  )}
                                </Popover.Content>
                              </Popover>
                            ) : (
                              <Popover id={"skillFocusesEmptyPopover"}>
                                <Popover.Content>
                                  <div>Empty</div>
                                </Popover.Content>
                              </Popover>
                            )
                          }
                        >
                          <div className="d-flex">
                            <div className="studentGroupCellName">
                              {getFullNameForStudent(student)}
                            </div>
                            <div
                              className="studentGroupCellRemove"
                              onClick={handleStudentRemove(student)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                size="1x"
                                style={{ color: "#ffffff" }}
                              />
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    ))}
                  </Col>
                </Row>
                {!IS_READY_COACH &&
                  props.evidencePeriodData &&
                  props.evidencePeriodData.evidence_column_groups.some((ecg) =>
                    ecg.evidence_columns.some(
                      (ec) => ec.evidence_type === EvidenceType.DiagnosticInfo
                    )
                  ) && (
                    <div className="d-flex align-items-center">
                      <div className="groupStudentLabel">
                        <h4 className="font-weight-bold ml-4 mb-0">
                          {isCommonSkills
                            ? "Common Skill Needs:"
                            : "All Skill Needs:"}
                        </h4>
                      </div>
                      <div className="groupInfoStudentContainer">
                        {skills.map((skill) => (
                          <div
                            className="studentGroupCell"
                            key={skill.identifier}
                          >
                            <div className="studentGroupCellName">
                              {skill.display_name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                {(isEstablished || IS_READY_COACH) && (
                  <div className={IS_READY_COACH ? "groupDetails m-0" : "row"}>
                    <div
                      className={!IS_READY_COACH ? "col-md-6 px-2" : undefined}
                    >
                      {IS_READY_COACH ? (
                        <GoalTypeSelect
                          value={newGroup.goal_type}
                          onChange={handleGroupGoalTypeChange}
                        />
                      ) : (
                        <input
                          name={"name"}
                          value={newGroup.name || ""}
                          onChange={handleGroupNameChange}
                          className="stdInput"
                          style={{ width: "100%", height: "38px" }}
                          placeholder="Name the group..."
                        />
                      )}
                    </div>
                    <div
                      className={!IS_READY_COACH ? "col-md-3 px-0" : undefined}
                    >
                      <CreatableSelect
                        id={"interventionist-selector"}
                        styles={{
                          container: (styles) => ({
                            ...styles,
                            minWidth: 120,
                            margin: "5px",
                          }),
                          menu: (styles) => ({ ...styles, zIndex: 100 }),
                        }}
                        isClearable
                        value={educators.find(
                          (teacher) => teacher.profile.id === interventionistId
                        )}
                        getOptionLabel={(user: UserInfo & any) =>
                          user.value
                            ? `Invite '${user.value}'`
                            : `${user.first_name} ${user.last_name}`
                        }
                        getOptionValue={(user: UserInfo & any) =>
                          user.value || user.profile.id.toString()
                        }
                        options={educators.filter((teacher) => {
                            if(currentUser.allowed_assigned_teachers_ids !== undefined) {
                              if(currentUser.allowed_assigned_teachers_ids.length) {
                                return currentUser.allowed_assigned_teachers_ids.includes(teacher.id) 
                              } else {
                                return true;
                              }
                            } else {
                              return false;
                            }
                          })
                        }
                        onChange={handleAssignedTeacherChange}
                        placeholder={
                          isTeacher ? "No Coach" : "Interventionist..."
                        }
                        onCreateOption={
                          isTeacher ? undefined : handleInviteTeacher
                        }
                        isValidNewOption={isTeacher ? () => false : undefined} // to hide invite option
                        noOptionsMessage={
                          isTeacher
                            ? () => educators.length ? "No options" : "A coach has not yet been assigned to you. Please reach out to your District Admin to have a coach assigned to you."
                            : ((() => (
                              <button
                                className="blueBtnSm"
                                onClick={handleInviteAndAssignTeacher}
                              >
                                Invite Interventionist
                              </button>
                            )) as any)
                        }
                      />
                    </div>
                    <div
                      className={!IS_READY_COACH ? "col-md-3 px-0" : undefined}
                    >
                      <AdditionalEducatorsSelector
                        fromManualGroup={true}
                        manualGroup={newGroup}
                        setManualGroup={setNewGroup}
                      />
                      {/* <CreatableSelect
                        id={"associated-educators-selector"}
                        isMulti
                        styles={{
                          container: (styles) => ({
                            ...styles,
                            minWidth: 120,
                            margin: "5px",
                          }),
                          menu: (styles) => ({ ...styles, zIndex: 100 }),
                        }}
                        name="associatedTeachers"
                        isClearable
                        value={allEducators.filter((teacher) =>
                          newGroup.associated_assignments?.includes(
                            teacher.profile.current_assignment!.id
                          )
                        )}
                        getOptionLabel={(user: UserInfo & any) =>
                          user.value
                            ? `Invite '${user.value}'`
                            : `${user.first_name} ${user.last_name}`
                        }
                        getOptionValue={(user: UserInfo & any) =>
                          user.value || user.profile.current_assignment!.id.toString()
                        }
                        isValidNewOption={isTeacher ? () => false : undefined} // to hide invite option
                        options={allEducators.filter((teacher) => {
                          if(currentUser.allowed_assigned_teachers_ids !== undefined) {
                            if(currentUser.allowed_assigned_teachers_ids.length) {
                              return currentUser.allowed_assigned_teachers_ids.includes(teacher.id) 
                            } else {
                              return true;
                            }
                          } else {
                            return false;
                          }
                        })}
                        onChange={handleAssociatedTeacherChange}
                        placeholder="Additional Educator(s)..."
                        onCreateOption={handleInviteAssociatedTeachers}
                        noOptionsMessage={
                          isTeacher
                            ? () => "No Options"
                            : () =>
                              (
                                <button
                                  className="blueBtnSm"
                                  onClick={
                                    handleInviteAndAssignAssociatedTeacher
                                  }
                                >
                                  Invite Educator
                                </button>
                              ) as any
                        }
                      /> */}
                    </div>
                    {IS_READY_COACH && (
                      <div className="m-auto p-0">
                        <div className="text-center">
                          <button
                            data-cy={"create-btn"}
                            className="blueBtnMd"
                            disabled={
                              ( !(
                                 isTeacher ||
                                 (!isTeacher && newGroup.teacher_assignment) 
                               ) || (props.isLoading.createInterventionGroup || (isTeacher && !newGroup.coach_assignment))
                               ) || (newGroup.goal_type == undefined)
                             }
                            onClick={handleGroupCreate}
                          >
                            Create it!&nbsp;
                            {props.isLoading.createInterventionGroup && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>

      {IS_READY_COACH && (
        <div className="dataTooltip ml-3">
          <div className="dataTooltipHeader">Auto Grouping</div>
          <div className="dataTooltipBody">
            <button
              className="whiteBtnSm text-nowrap"
              onClick={handleRecommendGroups}
            >
              <FontAwesomeIcon icon={faUsers} className="mr-1" />
              <span>
                Recommend
                <br /> Groups
              </span>
            </button>
          </div>
        </div>
      )}

      {IS_READY_COACH && <GroupingStudentsModal />}
    </div>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
  cases,
}: ApplicationState): PropsFromState => {
  return {
    userInfo: auth.userInfo,
    evidencePeriodData: onboarding.evidencePeriodData,
    teachersRoster: onboarding.teachersRosterWithDummyUser,
    coachesRoster: onboarding.coachesRosterWithDummyUser,
    newGroupStudents: cases.newGroupStudents,
    invites: onboarding.invites,
    currentDataPeriod: onboarding.currentDataPeriod,
    isEnterSpecificSkillActive: cases.isEnterSpecificSkillActive,
    silentGroupCreation: cases.silentGroupCreation,
    setGoalChooseConcernModel: cases.modalsState.setGoalChooseConcernModel,
    isLoading: {
      createInterventionGroup: cases.isLoading.createInterventionGroup,
      getTeachers: onboarding.isLoading.getTeachers
    },
    errors: {
      createInterventionGroup: cases.errors.createInterventionGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      createInterventionGroup: createInterventionGroup,
      addStudentsToNewGroup: addStudentsToNewGroup,
      removeStudentFromNewGroup: removeStudentFromNewGroup,
      removeAllStudentsFromNewGroup: removeAllStudentsFromNewGroup,
      openGroupingStudentsModal: openGroupingStudentsModal,
      openConfirmRecommendModal: openConfirmRecommendModal,
      isAllStudentsHaveDiagnosticInfo: isAllStudentsHaveDiagnosticInfo,
      push: push,
      cancelCreateGroupManually: cancelCreateGroupManually,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
      resetGoalChooseConcernModel: resetGoalChooseConcernModel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupStudents);
