import React, { useEffect, useState } from "react";
import FormsEvidence from "./FormsEvidence";
import EvidenceTable from "./EvidenceTable";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import {
  getCustomBehaviors,
  getDataPeriods,
  getEvidenceInDataPeriod,
  getSchools,
  getStaticDataCategoryDomains,
} from "../../../../../store/onboarding/actions";
import { DataPeriod, EvidencePeriodData } from "../../../../../store/onboarding/types";
import LoadingIndicator from "../../LoadingIndicator";
import PickDataSourceModal from "./PickDataSourceModal";
import { getInterventionGroups, getStudentInterventionGroups, hideGroupRecommendationFromData } from "../../../../../store/onboarding/cases/actions";
import GroupStudents from "../../third-step/group-students/GroupStudents";
import DataFiltersPanel from "./DataFiltersPanel";
import usePrevProps from "../../../../../utils/hooks/usePrevProps";
import FirstDataUploadTour from "../../../../pages/coach-dashboard/tours/FirstDataUploadTour";
import AddMoreData from "../../third-step/group-students/AddMoreData";
import { useLoading } from "../../../../../utils/hooks/useLoading";
import { LocalStorageKeys } from "../../../../../utils/LocalStorageUtils";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { resolve } from "dns";

type PropsFromState = {
  errors: {
    getDataPeriods?: string;
    getEvidenceData?: string;
    uploadAssessmentData?: string;
    uploadUndefinedAssessmentData?: string;
    uploadEvidenceData?: string;
  };
  isLoading: {
    getDataPeriods: boolean;
    getEvidenceData: boolean;
    uploadAssessmentData: boolean;
    uploadUndefinedAssessmentData: boolean;
    uploadEvidenceData: boolean;
  };
  currentDataPeriod?: DataPeriod;
  hasEvidencePeriodData: boolean;
};

type DispatchProps = {
  getDataPeriods: () => any;
  //getInterventionGroups: (showArchivedGroups?: boolean, activeTab?:string) => any;
  getStudentInterventionGroups: (dataPeriodId: number) => any;
  getStaticDataCategoryDomains: () => any;
  getCustomBehaviors: () => any;
  getEvidenceInDataPeriod: (dataPeriodId?: number) => any;
  hideGroupRecommendationFromData: () => void;
};

type OwnProps = {
  tableOnly?: boolean;
};

type DisplayedAssessementGroup = {
  dataPeriodId: number;
  evidenceColumnGroup: Array<{
    evidenceColumnGroupId: number;
    show: boolean;
  }>;
};
type Props = OwnProps & PropsFromState & DispatchProps;

const EvidenceInfoTab: React.FunctionComponent<Props> = (props) => {
  const prevProps = usePrevProps({
    getDataPeriods: props.isLoading.getDataPeriods,
    uploadAssessmentData: props.isLoading.uploadAssessmentData,
    uploadEvidenceData: props.isLoading.uploadEvidenceData,
    uploadUndefinedAssessmentData:
      props.isLoading.uploadUndefinedAssessmentData,
  });

  const [displayedGroups, setDisplayedGroup] = useState<DisplayedAssessementGroup>();
  const [evidencePeriodData, setEvidencePeriodData] = useState<any>();
  const originalEvidencePeriodData = useSelector<ApplicationState,EvidencePeriodData | undefined >(
    (s) => s.onboarding.evidencePeriodData);
  const showArchivedGroups = useSelector(
    (s: ApplicationState) => s.dataFilter.showArchivedGroups
  );
  const tabName:any = useSelector<ApplicationState>((s)=> s.cases.tabName);
  //const history:any = useParams();

  const { isDistrictOwner } = useUserRole();

  useEffect(() => {
    props.getDataPeriods();
    props.getStaticDataCategoryDomains();
    props.getCustomBehaviors();
    props.hideGroupRecommendationFromData();
  }, []);
  
  // useEffect(() => { //commented based on optimisation code
  //   if (props.currentDataPeriod?.id && !isDistrictOwner) {
  //     //props.getInterventionGroups(showArchivedGroups, tabName);
  //   }
  // }, [props.currentDataPeriod?.id]);
  
  useEffect(() => {
    if (props.currentDataPeriod?.id) {
      props.getEvidenceInDataPeriod(props.currentDataPeriod.id);
      props.getStudentInterventionGroups(props.currentDataPeriod.id);
    }
  }, [props.currentDataPeriod]);
  
  useLoading(
    props.isLoading.uploadAssessmentData,
    props.errors.uploadAssessmentData,
    () => {
      if(!isDistrictOwner) {
      return props.getDataPeriods()
      }
    }
  );

  useEffect(() => {
    //console.log('displayedGroup', displayedGroups)
  },[displayedGroups])


  useEffect(()=> {
    if((originalEvidencePeriodData != undefined) 
    //&& originalEvidencePeriodData?.evidence_column_groups.length > 0
    ){
      let obj = {
        dataPeriodId: props.currentDataPeriod?.id!,
        evidenceColumnGroup: originalEvidencePeriodData?.evidence_column_groups,
        studentRows: originalEvidencePeriodData.student_rows
      }
      setEvidencePeriodData(obj)
      setDisplayedGroupsStorage(obj)
      .then((storageGroups: any) => {
        setDisplayedGroup(storageGroups);
      })
      .then(() => {
        setDisplayedGroup(getDisplayedGroupsFromStorage(obj))
      });
      
    }
  }, [originalEvidencePeriodData])


  useEffect(() => {
    if (
      (prevProps?.uploadEvidenceData &&
        !props.isLoading.uploadEvidenceData &&
        !props.errors.uploadEvidenceData) ||
      (prevProps?.uploadUndefinedAssessmentData &&
        !props.isLoading.uploadUndefinedAssessmentData &&
        !props.errors.uploadUndefinedAssessmentData)
    ) {
      props.getEvidenceInDataPeriod();
    }
  }, [
    prevProps?.uploadAssessmentData,
    prevProps?.uploadEvidenceData,
    prevProps?.uploadUndefinedAssessmentData,
    props.isLoading.uploadAssessmentData,
    props.isLoading.uploadEvidenceData,
    props.isLoading.uploadUndefinedAssessmentData,
    props.errors.uploadAssessmentData,
    props.errors.uploadEvidenceData,
    props.errors.uploadUndefinedAssessmentData,
    evidencePeriodData
  ]);

  const setDisplayedGroupsStorage =  (evidencePeriodData:any) => {
    const displayedGroupsData = localStorage.getItem(
      LocalStorageKeys.DisplayedGroups
    );
    let storageGroups = displayedGroupsData
      ? JSON.parse(displayedGroupsData)
      : [];

      if(!storageGroups.some((storageData:any)=> storageData.dataPeriodId == evidencePeriodData.dataPeriodId)){
        const displayedGroup = {
          dataPeriodId: evidencePeriodData.dataPeriodId,
          evidenceColumnGroup: (evidencePeriodData?.evidenceColumnGroup.length > 0) ? evidencePeriodData?.evidenceColumnGroup?.map(
            (evidenceGroup:any) => {
              return {
                evidenceColumnGroupId: evidenceGroup.id,
                show: true,
              }
            }) : []
        };
        storageGroups.push(displayedGroup);
      } else {
        storageGroups = [...storageGroups.map((storageGroup:any) => {
          let storageColumnGroupArray: any[] = storageGroup.evidenceColumnGroup.map((ecg:any) => ecg.evidenceColumnGroupId);
          if((storageGroup.dataPeriodId == evidencePeriodData.dataPeriodId)
          && !evidencePeriodData?.evidenceColumnGroup?.some((ecg:any) => storageColumnGroupArray.includes(ecg.evidenceColumnGroupId))
          ) {
            const displayedGroup = {
              dataPeriodId: evidencePeriodData.dataPeriodId,
              evidenceColumnGroup: evidencePeriodData?.evidenceColumnGroup?.map(
                (evidenceGroup:any) => {
                  return {
                    evidenceColumnGroupId: evidenceGroup.id,
                    show: true,
                  }
                })
            };
            let newEvidenceColumnGroup:any[] = 
                displayedGroup.evidenceColumnGroup
                .filter((displayECG:any) => !storageColumnGroupArray.includes(displayECG.evidenceColumnGroupId))
          
            if(newEvidenceColumnGroup.length) {
              storageGroup.evidenceColumnGroup.push(...newEvidenceColumnGroup)
            }
          } 

          if((storageGroup.dataPeriodId == evidencePeriodData.dataPeriodId)
          && evidencePeriodData?.evidenceColumnGroup.length
          && (evidencePeriodData?.evidenceColumnGroup.length < storageGroup.evidenceColumnGroup.length)){ //delete the assessment
            storageGroup = {
              ...storageGroup,
              evidenceColumnGroup:  [
                ...storageGroup.evidenceColumnGroup
                  .filter((storageECG: any) => evidencePeriodData.evidenceColumnGroup?.some((ecg: any) => storageECG.evidenceColumnGroupId == ecg.id) )
              ]
            }
          }
          return storageGroup;
        })]
      }
      
      localStorage.setItem(
        LocalStorageKeys.DisplayedGroups,
        JSON.stringify(storageGroups)
      )
      
      return Promise.resolve(storageGroups);
      }
  const getDisplayedGroupsFromStorage = (evidencePeriodData:any) => {
    if ((evidencePeriodData != undefined) && evidencePeriodData.evidenceColumnGroup.length > 0) {
      const displayedGroupsData = localStorage.getItem(
        LocalStorageKeys.DisplayedGroups
      );
      const storageGroups = displayedGroupsData
        ? JSON.parse(displayedGroupsData)
        : [];

        const existingStorageGroups = storageGroups.find(
          (displayedGroup: any) =>
          displayedGroup.dataPeriodId === evidencePeriodData.dataPeriodId
        );
        if ((existingStorageGroups != undefined) && existingStorageGroups.evidenceColumnGroup.length > 0) {
          return existingStorageGroups;
        } 
      localStorage.setItem(
        LocalStorageKeys.DisplayedGroups,
        JSON.stringify(storageGroups)
      );
      return storageGroups;
    }
  };

  const handleDisplayedGroupsChange = (
    dataPeriodId: number,
    evidenceColumnGroupId: number,
  ) => () => {
    let dataPeriodIdCheck: boolean;
    if((typeof displayedGroups == 'object') && (displayedGroups?.dataPeriodId === dataPeriodId)) {
      dataPeriodIdCheck = true;
    } else if(Array.isArray(displayedGroups)) {
      dataPeriodIdCheck = displayedGroups?.filter((dataGroup) => dataGroup.dataPeriodId === dataPeriodId).length > 0;
    } else {
      dataPeriodIdCheck = false;
    }
    if(Array.isArray(displayedGroups)) {
      displayedGroups?.map((displayedGroups) => {
        handleChangeFun(displayedGroups, dataPeriodId, evidenceColumnGroupId);
      })
    } else {
      handleChangeFun(displayedGroups, dataPeriodId, evidenceColumnGroupId);
    }
  };

  const handleChangeFun = (displayedGroups:any, dataPeriodId:any, evidenceColumnGroupId:any) => {
    if (displayedGroups?.dataPeriodId === dataPeriodId) {
      const displayedColumnsNum = displayedGroups?.evidenceColumnGroup.filter(
        (x:any) => x.show
      ).length;
      const displayedGroupData: DisplayedAssessementGroup = {
        ...displayedGroups,
        evidenceColumnGroup: displayedGroups?.evidenceColumnGroup.map(
          (evidenceColumnGroup:any) => {
            if (evidenceColumnGroup.evidenceColumnGroupId === evidenceColumnGroupId) {
              return {
                ...evidenceColumnGroup,
                show: !evidenceColumnGroup.show
                  ? !evidenceColumnGroup.show
                  : displayedColumnsNum > 1
                    ? !evidenceColumnGroup.show
                    : evidenceColumnGroup.show,
              };
            }
            return evidenceColumnGroup;
          }
        ),
      };

      const found =
      displayedGroupData.evidenceColumnGroup.filter(
          (ec) => ec.evidenceColumnGroupId === evidenceColumnGroupId
        ).length > 0;

      if (!found) {
        displayedGroupData.evidenceColumnGroup.push({
          evidenceColumnGroupId: evidenceColumnGroupId,
          show: true,
        });
      }

    
      setDisplayedGroup(displayedGroupData)
      const storageData = JSON.parse(
        localStorage.getItem(LocalStorageKeys.DisplayedGroups) || "[]"
      ) as Array<DisplayedAssessementGroup>;
      const mergedData = storageData
        .filter(
          (data) => displayedGroupData.dataPeriodId !== data.dataPeriodId
        )
        .concat(displayedGroupData);
      localStorage.setItem(
        LocalStorageKeys.DisplayedGroups,
        JSON.stringify(mergedData)
        );    
      }
  }

  const filterDisplayedGroups = (dataPeriodId: number, evidenceGroupId: number
  ) => {
    let col:any;
    if(Array.isArray(displayedGroups)) {
        const dataPeriodCol = (displayedGroups != undefined) && displayedGroups
        .find(
          (displayedColumn) => (displayedColumn != undefined) && (displayedColumn.dataPeriodId === dataPeriodId)
        );
        col = (dataPeriodCol?.evidenceColumns != undefined) && dataPeriodCol?.evidenceColumns.find((x:any) => x.evidenceColumnGroup === evidenceGroupId);

    } else if(typeof displayedGroups === 'object') {

      if(displayedGroups?.dataPeriodId === dataPeriodId){
        col = displayedGroups?.evidenceColumnGroup.find((x) => x.evidenceColumnGroupId === evidenceGroupId);
  
      }
    }
    
    if (col) {
      return col.show;
    } else {
      return true;
    }
  };

  const isChecked = (dataPeriodId:number, evidenceColumnGroupId: number) => {
    if(Array.isArray(displayedGroups)) {
      const group = displayedGroups?.find(
        (x) => x.dataPeriodId === dataPeriodId
      );
      if (group) {
        const col = group.evidenceColumnGroup.find(
          (x:any) => x.evidenceColumnGroupId === evidenceColumnGroupId
        );
        if (col) {
          return col.show;
        }
      }
    } else if(typeof displayedGroups === 'object') {
      const col = displayedGroups?.evidenceColumnGroup.find(
        (x:any) => x.evidenceColumnGroupId === evidenceColumnGroupId
      );
      if (col) {
        return col.show;
      }
    } 
     return false;
  };

  const getNumberDisplayingColumns = (): number => {
   if(displayedGroups !== undefined) {
      return displayedGroups?.evidenceColumnGroup?.filter((ec) => ec.show).length || 0;
    } else {
      return 0;
    }
  };
  return (
    <div>
      {props.isLoading.getEvidenceData || props.isLoading.getDataPeriods ? (
        <LoadingIndicator />
      ) : (
        <>
          <div>
            {props.tableOnly ? (
              <AddMoreData 
                className="inline mb-3" 
                getDataPeriodsByDistrict={() => window.location.reload()}
              />
            ) : (
              props.hasEvidencePeriodData && (
                <div className="mb-3">
                  <GroupStudents />
                </div>
              )
            )}
            <div>
              <div className="dataTableContainer">
                <div>
                  <DataFiltersPanel 
                    evidenceGroupData = {evidencePeriodData}
                    numberDisplayedGroups={getNumberDisplayingColumns()}
                    onDisplayedGroupsChange={handleDisplayedGroupsChange}
                    checkDisplayedGroups={isChecked}
                  />
                  {props.hasEvidencePeriodData && 
                    <EvidenceTable 
                      isPlanning 
                      filterDisplayedGroups = {filterDisplayedGroups}
                      isDataInterview = {true}
                    />}
                </div>
              </div>
            </div>
          </div>
          <FormsEvidence />
        </>
      )}
      <FirstDataUploadTour />
      <PickDataSourceModal />
    </div>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    errors: {
      getDataPeriods: onboarding.errors.getDataPeriods,
      getEvidenceData: onboarding.errors.getEvidenceInDatePeriod,
      uploadAssessmentData: onboarding.errors.uploadAssessmentData,
      uploadUndefinedAssessmentData:
        onboarding.errors.uploadUndefinedAssessmentData,
      uploadEvidenceData: onboarding.errors.uploadEvidenceData,
    },
    isLoading: {
      getDataPeriods: onboarding.isLoading.getDataPeriods,
      getEvidenceData: onboarding.isLoading.getEvidenceInDatePeriod,
      uploadAssessmentData: onboarding.isLoading.uploadAssessmentData,
      uploadUndefinedAssessmentData:
        onboarding.isLoading.uploadUndefinedAssessmentData,
      uploadEvidenceData: onboarding.isLoading.uploadEvidenceData,
    },
    currentDataPeriod: onboarding.currentDataPeriod,
    hasEvidencePeriodData:
      !!onboarding.evidencePeriodData &&
      onboarding.evidencePeriodData.evidence_column_groups.length > 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
      //getInterventionGroups: getInterventionGroups,
      getStudentInterventionGroups: getStudentInterventionGroups,
      getDataPeriods: getDataPeriods,
      getStaticDataCategoryDomains: getStaticDataCategoryDomains,
      getCustomBehaviors: getCustomBehaviors,
      hideGroupRecommendationFromData: hideGroupRecommendationFromData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceInfoTab);
