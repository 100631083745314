import {
  GoalTypes,
  GroupRecommendation,
  InterventionGroup,
  InterventionGroupRequest,
} from "../../../../../../store/onboarding/cases/types";
import { IS_READY_COACH } from "../../../../../../constants";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import {
  addBlankRecommendedGroup,
  changeRecommendedGroup,
  createInterventionGroup,
  discardRecommendedGroup,
} from "../../../../../../store/onboarding/cases/actions";
import { Student, TeacherInfo } from "../../../../../../store/onboarding/types";
import { ApplicationState } from "../../../../../../store";
import { Specific } from "../../../../../../store/groups/types";
import React, { useState } from "react";
import _, { noop } from "lodash";
import useHighlightGroupCard from "./useHighlightGroupCard";
import { UserInfo } from "../../../../../../store/auth/types";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { changeIntGroupAssigmentCollapsedSettings } from "../../../../../../store/onboarding/actions";

const RECOMMENDATIONS_ASSIGMENT_ID = -999; // just some uniq value to store in locale storage

const useRecommendationGroupCards = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector<ApplicationState, UserInfo | undefined>(
    (s) => s.auth.userInfo
  );

  const { isTeacher } = useUserRole();

  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => window.location.pathname.includes('groups') ? s.onboarding.teachersRosterWithDummyUser: s.onboarding.teachersRoster
  );

  const activeTabs = useSelector<ApplicationState>(
    (s) => s.cases.tabName
  );

  const collapsedIntGroupAssignmentsIds = useSelector<
  ApplicationState,
  number[]
>((s) => s.onboarding.collapsedIntGroupAssignmentsIds);

  const onSaveGroup = (group: GroupRecommendation,isCreateNewGroupWithUpdateGoal= false, callback=noop) => {
    const foundTeacher = teachers.find(
      (t) =>
        t.user.profile.current_assignment &&
        t.user.id === group.default_interventionist_id
    );

    let teacherAssignmentId: number | undefined;

    if (
      isTeacher &&
      currentUser &&
      currentUser?.id == group.default_interventionist_id
    ) {
      teacherAssignmentId = currentUser.profile.current_assignment?.id;
    } else if (foundTeacher) {
      teacherAssignmentId = foundTeacher.user.profile.current_assignment?.id;
    }

    if (teacherAssignmentId) {
      let studentFocuses:any[] = [];
      group.students.map((s) => s.focuses?.map((focus)=> (!studentFocuses.includes(focus.identifier)) ? studentFocuses.push(focus.identifier) : []));

      const request: InterventionGroupRequest = {
        id: group.id,
        coach_assignment:group.default_coach_id?group.default_coach_id:undefined,
        name: group.name,
        mtss_tier: group.tier,
        goal_type: (activeTabs == 'groups/behavior') ? GoalTypes.BEHAVIOR : GoalTypes.ACADEMIC,
        recommendation_group_key: group.group_key,
        teacher_assignment: teacherAssignmentId,
        additional_educator_plan: group.additional_educator_plan,
        focuses: group.focuses.length > 0 ? group.focuses?.map((f) => f.identifier) : studentFocuses,
        students: group.students.filter((s) => s.id != null).map((s) => s.id!),
        student_focuses: group.students.map((s) => ({
          student_id: s.id,
          focuses: s.focuses,
        })),
        ...(group.group_copied_from ? {group_copied_from: group.group_copied_from} : ''),
        ...(group.associated_assignments ? {associated_assignments: group.associated_assignments} : {associated_assignments: []})
      };
      dispatch<any>(
        createInterventionGroup(request, !IS_READY_COACH, IS_READY_COACH)
      ).then(
        (group: InterventionGroup) => {
          setTimeout(() => {
            highlightGroup(
              `employee_assignment_${isTeacher ? group.coach_assignment?.user.id :group.teacher_assignment?.user.id}`,
              group.id
            );
          }, 100)

          // if(collapsedIntGroupAssignmentsIds.length > 0) {
          //   dispatch(
          //     changeIntGroupAssigmentCollapsedSettings({
          //       id: RECOMMENDATIONS_ASSIGMENT_ID,
          //       collapsed: false
          //     })
          //   );
          // } 
          if(isCreateNewGroupWithUpdateGoal) {
            callback(isCreateNewGroupWithUpdateGoal, group);
          }
        },
        () => {}
        );
    } else {
      toastr.error(
        "Error",
        "Please select an Interventionist for this group first"
      );
    }
  };

  const onSaveName = (group: GroupRecommendation) => (name: string) => {
    dispatch(changeRecommendedGroup(group, { ...group, name: name }));
  };

  const onChangeTier = (group: GroupRecommendation) => (tier: 1 | 2 | 3) => {
    dispatch(changeRecommendedGroup(group, { ...group, tier: tier }));
  };

  const onChangeTeacher = (group: GroupRecommendation) => (id: number) => {
    dispatch(
      changeRecommendedGroup(group, {
        ...group,
        default_interventionist_id: id,
      })
    );
  };


  const onChangeCoach = (group: GroupRecommendation) => (id: number) => {
    dispatch(
      changeRecommendedGroup(group, {
        ...group,
        default_coach_id: id,
      })
    );
  };


  const onAddStudent = (group: GroupRecommendation) => (student: Student) => {
    dispatch(
      changeRecommendedGroup(group, {
        ...group,
        students: [student, ...group.students],
      })
    );
  };

  const onRemoveStudent = (group: GroupRecommendation) => (
    student: Student
  ) => {
    dispatch(
      changeRecommendedGroup(group, {
        ...group,
        students: [...group.students.filter((st) => st !== student)],
      })
    );
  };

  const onRemoveFocus = (group: GroupRecommendation) => (focus: Specific) => {
    dispatch(
      changeRecommendedGroup(group, {
        ...group,
        focuses: [...group.focuses.filter((foc) => foc !== focus)],
      })
    );
  };

  const onDragDropStudent = (nextGroup: GroupRecommendation) => (
    student: Student,
    prevGroup: GroupRecommendation
  ) => {
    if (nextGroup.id! < 0 && prevGroup.id! < 0) {
      const nextGroupStd = [...nextGroup.students, student];
      const uniqueStdFocuses:any = Array.from(new Set(nextGroupStd.flatMap((group)=> (group.focuses != undefined) ? group.focuses: []))); 
      const previousStudent:any = [...prevGroup.students.filter((st) => st.id !== student.id)]
      const previousStdFocus:any = Array.from(new Set(previousStudent.flatMap((previousStd:any) => previousStd.focuses)));
      dispatch(
        changeRecommendedGroup(nextGroup, {
          ...nextGroup,
          students: nextGroupStd,
          focuses: uniqueStdFocuses
        })
      );
      dispatch(
        changeRecommendedGroup(prevGroup, {
          ...prevGroup,
          students: previousStudent,
          focuses:previousStdFocus
        })
      );
    }
  };

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [groupToDiscard, setGroupToDiscard] = useState<
    GroupRecommendation | undefined
  >(undefined);

  const onDiscardGroup = (group: GroupRecommendation) => {
    setGroupToDiscard(group);
    setShowDiscardModal(true);
  };

  const { highlightGroup } = useHighlightGroupCard();

  const onConfirmDiscardModal = () => {
    if (groupToDiscard) {
      dispatch(discardRecommendedGroup(groupToDiscard));
      setGroupToDiscard(undefined);
      setShowDiscardModal(false);
    }
  };

  const onHideDiscardModal = () => {
    setGroupToDiscard(undefined);
    setShowDiscardModal(false);
  };

  const recommendedGroups = useSelector<
    ApplicationState,
    GroupRecommendation[]
  >((s) => s.cases.recommendedGroups);

  const onBlankGroupAdd = () => {
    const group: GroupRecommendation = {
      id: recommendedGroups.length
        ? (_.minBy(recommendedGroups, (g) => g.id)?.id ?? -1) - 1
        : -1,
      name: "New Group",
      students: [],
      focuses: [],
      interventions: [],
      student_goals: [],
      intervention_plan: null,
      time_monday_start: null,
      time_tuesday_start: null,
      time_wednesday_start: null,
      time_thursday_start: null,
      time_friday_start: null,
      time_monday_end: null,
      time_tuesday_end: null,
      time_wednesday_end: null,
      time_thursday_end: null,
      time_friday_end: null,
      session_duration: null,
      session_frequency: null
    };
    dispatch(addBlankRecommendedGroup(group));
    return Promise.resolve(1);
  };

  return {
    onSaveGroup,
    onSaveName,
    onChangeTier,
    onChangeTeacher,
    onAddStudent,
    onRemoveStudent,
    onRemoveFocus,
    onDragDropStudent,
    onDiscardGroup,
    onConfirmDiscardModal,
    onHideDiscardModal,
    showDiscardModal,
    recommendedGroups,
    onBlankGroupAdd,
    onChangeCoach
  };
};

export default useRecommendationGroupCards;
