import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { Collapse } from "react-bootstrap";
import RecommendationSettings from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/RecommendationSettings";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState, history } from "../../../../../../store";
import useRecommendationGroupCards from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useRecommendationGroupCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import GroupCard from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/GroupCard";
import useGroupCardDragging from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useGroupCardDragging";
import { CoachInfo, DataPeriod, EvidenceColumnGroup, TeacherInfo } from "../../../../../../store/onboarding/types";
import DiscardConfirmModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/DiscardConfirmModal";
import { changeIntGroupAssigmentCollapsedSettings } from "../../../../../../store/onboarding/actions";
import { InterventionGroupMap } from "../../../../../../utils/Mappers";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import NoGroupsContainer from "../../../../../common/reading-interventions/intervention-tools/groups-tab/NoGroupsContainer";
import { CaseInterventionGroup, InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import ShowDiagnosedStudentCheckBox from "../common/ShowDiagnosedStudentCheckBox";
import { hideGroupRecommendationFromData } from "../../../../../../store/onboarding/cases/actions";

type MatchParams = {
  tabName?: string;
};

type OwnProps = {
  //intGroupsMap: InterventionGroupMap[]
};

type Props = OwnProps & RouteComponentProps<MatchParams>;

const RECOMMENDATIONS_ASSIGMENT_ID = -999; // just some uniq value to store in locale storage

const RecommendationsSection: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { isParent, isSupervisor, isTeacher } = useUserRole();
  const [currentAssessment, setCurrentAssessment] = useState('');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname); // Type as string

  const hasMounted = useRef(false);

  const openRecommendationGroupFromData = useSelector<ApplicationState>(
    (s) => s.cases.openRecommendationGroupFromData
  )

  useEffect(() => {
    // Only execute this effect if the component has mounted
    if (hasMounted.current) {
      setCurrentPath(location.pathname); // Update current path to the new path
    } else {
      hasMounted.current = true; // Mark that the component has mounted
    }
  }, [location]); // Depend on location changes

  useEffect(() => {
    if(!openRecommendationGroupFromData) {
      if(currentPath?.includes('groups')) {
        dispatch(changeIntGroupAssigmentCollapsedSettings({
          id: 0,
          collapsed: false
        }));
        setCollapsed(false);
      }
    } else {
      dispatch(changeIntGroupAssigmentCollapsedSettings({
        id: RECOMMENDATIONS_ASSIGMENT_ID,
        collapsed: true
      }));
      setCollapsed(true);
      dispatch(hideGroupRecommendationFromData())
    }
  },[currentPath])

  const showRecommendedGroups = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.showRecommendedGroups);

  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRosterWithDummyUser
  );

  const coaches = useSelector<ApplicationState, CoachInfo[]>(
    (s) => s.onboarding.coachesRosterWithDummyUser
  );

  const dataPeriods = useSelector<ApplicationState, DataPeriod[]>(
    (s) => s.onboarding.dataPeriods
  );

  const currentUser: any = useSelector(
    (s: ApplicationState) => s.auth.userInfo
  );

  const currentDataPeriod: any = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const evidencePeriodData: any = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
  );
  
  
  let dataPeriodName = '';
  // if(dataPeriods.filter((data)=> (data.user_id == currentUser.id) && data.is_selected).length > 0){
  //   dataPeriodName = dataPeriods.filter((data)=> (data.user_id == currentUser.id) && data.is_selected)[0].name;
  // }

  if(currentDataPeriod !== undefined) {
    dataPeriodName = currentDataPeriod.name;
  }

  useMemo(() => {
      for (let i = 0; i < evidencePeriodData?.evidence_column_groups.length; i++) {
        const data:EvidenceColumnGroup = evidencePeriodData?.evidence_column_groups[i];
        if((props.match.params.tabName == "groups/behavior") && data.is_behaviour_primary_screening) {
          setCurrentAssessment(', '+data.assessment.name);
          break;
        } 
        if((props.match.params.tabName == "groups/reading")  && data.is_primary_screening) {
          setCurrentAssessment(', '+data.assessment.name);
          break;
        }
      }
  }, [evidencePeriodData])
  

  const {
    onSaveGroup,
    onSaveName,
    onChangeTier,
    onChangeTeacher,
    onAddStudent,
    onRemoveStudent,
    onRemoveFocus,
    onDragDropStudent,
    onDiscardGroup,
    onChangeCoach,
    onConfirmDiscardModal,
    onHideDiscardModal,
    showDiscardModal,
    recommendedGroups,
    onBlankGroupAdd,
  } = useRecommendationGroupCards();

  const { onDragStart, onDragEnd } = useGroupCardDragging();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  
  //const { intGroupsMap } = props;

  const handleCollapse = (isBlankCard?: boolean) => {
    if(isBlankCard) {
      dispatch(
        changeIntGroupAssigmentCollapsedSettings({
          id: RECOMMENDATIONS_ASSIGMENT_ID,
          collapsed: isBlankCard,
        })
      );
      setCollapsed(isBlankCard);
    } else {
      dispatch(
        changeIntGroupAssigmentCollapsedSettings({
          id: RECOMMENDATIONS_ASSIGMENT_ID,
          collapsed: !collapsed,
        })
      );
      setCollapsed((pS) => !pS);
    }
  };

  const collapsedIntGroupAssignmentsIds = useSelector<
    ApplicationState,
    number[]
  >((s) => s.onboarding.collapsedIntGroupAssignmentsIds);

  const caseInterventionGroups = useSelector<ApplicationState, CaseInterventionGroup>(
    (s) => s.cases.caseInterventionGroups
  );

  // useEffect(() => { //optimise code ====> close on load
  //   if((dataPeriodName == 'Initial Data' || !Object.keys(caseInterventionGroups).length) && !collapsedIntGroupAssignmentsIds.includes(RECOMMENDATIONS_ASSIGMENT_ID)){
  //     dispatch(changeIntGroupAssigmentCollapsedSettings({
  //       id: RECOMMENDATIONS_ASSIGMENT_ID,
  //       collapsed: !collapsed
  //     }))
  //     setCollapsed((pS) => !pS);
  //     console.log('use 1')
  //   }
  // },[dataPeriodName, caseInterventionGroups])

  useEffect(() => {
    setCollapsed((c) =>
      Object.keys(caseInterventionGroups)?.length ? collapsedIntGroupAssignmentsIds.includes(RECOMMENDATIONS_ASSIGMENT_ID) : c
    );
  }, [caseInterventionGroups, collapsedIntGroupAssignmentsIds]);

  const isLoadingGetRecommendations = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.isLoading.getRecommendations);

  const loading = useMemo(() => {
    return isLoadingGetRecommendations;
  }, [isLoadingGetRecommendations]);

  const recommendationFilterApplied = useSelector((s: ApplicationState) => s.onboarding.messageParts);

  const addBlankCard = () => {
    onBlankGroupAdd().then(() => {
      handleCollapse(true);
    })
  }

  return (
    <>
      {showRecommendedGroups && (
        <div>
          {/* fix - open filter box then switch to another tab, box has opened */}
           {props.match.params.tabName == "groups/behavior" 
            || props.match.params.tabName == "groups/reading"
          ?
          <RecommendationSettings /> : ''}
          <div>
            <div
              className="assigmentCollapseHeader"
              aria-controls={"recommendations-collapse"}
              aria-expanded={collapsed}
              onClick={() => handleCollapse(false)}
            >
              <span className="mr-3">
                <FontAwesomeIcon
                  icon={collapsed ? faCaretDown : faCaretRight}
                  size="2x"
                />
              </span>

              <div className="d-flex flex-1 justify-content-between align-items-center">
                <h2>Recommendations: {dataPeriodName == 'Initial Data' ? 'No Data Has Been Uploaded Yet' :dataPeriodName} {(dataPeriodName !== 'Initial Data') && currentDataPeriod && currentDataPeriod.user_id !== currentUser.id ? `(Shared by ${
          currentDataPeriod.user_name ? currentDataPeriod.user_name : currentDataPeriod.user_email
        })` : ''} {currentAssessment}</h2>

                <button className="blueBtnSm" onClick={addBlankCard}>
                  Add Blank Card
                </button>
              </div>
            </div>

            <Collapse in={collapsed}>
              <div id="recommendations-collapse">
                <div className="yourNewGroupContainer">
                  {loading ? (
                    <LoadingIndicator />
                  ) : (
                    <>
                      <div className="unfinished-groups-container">
                        {props.match.params.tabName == "groups/reading"
                         ? <ShowDiagnosedStudentCheckBox /> : ''}
                        {recommendedGroups.length ? (
                          <>
                            {props.match.params.tabName == "groups/behavior"
                            ? 
                              <div className="col-12 pl-0">
                                <div className="alert alert-warning py-1" role="alert">
                                  {/* {props.match.params.tabName == "groups/reading" 
                                    ? `Showing recommended groups by ${recommendationFilterApplied.length ? 'the filtering options selected above' : 'Content Areas of Need within grades'}.`:''} 
                                  */}
                                    {`Showing recommended groups by ${recommendationFilterApplied.length ? 'the filtering options selected above' : 'Content Areas of Need across grades'}.`}
                                </div>
                              </div>
                              : ''
                            } 
                            <div className="recommendationsGroupList">
                              {recommendedGroups.map((group) => (
                                <GroupCard
                                  key={`${group.name} ${group.id} ${group.default_interventionist_id}`}
                                  groupRecommendation={group}
                                  teachers={teachers}
                                  coaches={coaches}
                                  onSaveGroup={() => onSaveGroup(group)}
                                  onRemoveGroup={() => onDiscardGroup(group)}
                                  onSaveName={onSaveName(group)}
                                  onChangeTier={onChangeTier(group)}
                                  onChangeTeacher={onChangeTeacher(group)}
                                  onChangeCoach={onChangeCoach(group)}
                                  onAddStudent={onAddStudent(group)}
                                  onRemoveStudent={onRemoveStudent(group)}
                                  onRemoveFocus={onRemoveFocus(group)}
                                  onDragDropStudent={onDragDropStudent(group)}
                                  onDragStart={onDragStart}
                                  onDragEnd={onDragEnd}
                                  fromRecommendation={true}
                                />
                              ))}
                            </div>
                          </>
                        ) : (
                          <NoGroupsContainer forParent={isParent} fromRecommendation/>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      )}

      <DiscardConfirmModal
        showModal={showDiscardModal}
        onHide={onHideDiscardModal}
        onConfirm={onConfirmDiscardModal}
      />
    </>
  );
};

export default withRouter(RecommendationsSection);
