import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import InterventionDayItem from "./InterventionDayItem";
import { Alert, Spinner } from "react-bootstrap";
import moment, { Moment } from "moment";
import { GroupRecommendation, InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { patchInterventionGroup } from "../../../../../../store/onboarding/cases/actions";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InterventionGroupPatchRequest } from "../../../../../../store/onboarding/cases/types";
import { ApplicationState } from "../../../../../../store";
import { scheduleReminder } from "../../../../../../store/onboarding/actions";
import { CoachDashboardTabKeys } from "../../../../../pages/coach-dashboard/CoachDashboard";
import Select from 'react-select';
import { useLocation } from "react-router";
import useInterventionGroupAccess from "../../../../../../utils/hooks/useInterventionGroupAccess";


type OwnProps = {
  interventionGroup: InterventionGroup | GroupRecommendation;
  container?: any;
};

type StateProps = {
  isLoading: {
    patchInterventionGroup?: boolean;
  };
};

type DispatchProps = {
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
};

interface OptionType {
  value: number;
  label: number;
}

type Props = OwnProps;

const InterviewTimeDayElements: FunctionComponent<Props> = (props: any) => {
  const { interventionGroup, container } = props;
  const [saveClicked, setSaveClicked] = useState(false);
  const [selectedSessionOption, setselectedSessionOption] = useState<OptionType | null>(null);
  const [selectedMinuteOption, setselectedMinuteOption] = useState<OptionType | null>(null);
  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup.id!)
  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );
  const SessionOptions: OptionType[] = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];

  const MinuteOptions: OptionType[] = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 },
    { value: 30, label: 30 },
    { value: 35, label: 35 },
    { value: 40, label: 40 },
    { value: 45, label: 45 },
    { value: 50, label: 50 },
    { value: 55, label: 55 },
    { value: 60, label: 60 },
  ];

  const handleSave = () => {
    setSaveClicked(true);
    if (changeDetected && interventionGroup.id) {
      let newFormattedDate = {
        time_monday_start: schedule.time_monday_start ? moment(schedule.time_monday_start, "h:m a").format("HH:mm") : null,
        time_monday_end: schedule.time_monday_end ? moment(schedule.time_monday_end, "h:m a").format("HH:mm") : null,
        time_tuesday_start: schedule.time_tuesday_start ? moment(schedule.time_tuesday_start, "h:m a").format("HH:mm") : null,
        time_tuesday_end: schedule.time_tuesday_end ? moment(schedule.time_tuesday_end, "h:m a").format("HH:mm") : null,
        time_wednesday_start: schedule.time_wednesday_start ? moment(schedule.time_wednesday_start, "h:m a").format("HH:mm") : null,
        time_wednesday_end: schedule.time_wednesday_end ? moment(schedule.time_wednesday_end, "h:m a").format("HH:mm") : null,
        time_thursday_start: schedule.time_thursday_start ? moment(schedule.time_thursday_start, "h:m a").format("HH:mm") : null,
        time_thursday_end: schedule.time_thursday_end ? moment(schedule.time_thursday_end, "h:m a").format("HH:mm") : null,
        time_friday_start: schedule.time_friday_start ? moment(schedule.time_friday_start, "h:m a").format("HH:mm") : null,
        time_friday_end: schedule.time_friday_end ? moment(schedule.time_friday_end, "h:m a").format("HH:mm") : null,
      };
      let newSessionWeek = {
        'session_duration' : selectedMinuteOption?.value,
        'session_frequency' : selectedSessionOption?.value
      };

      props
        .patchInterventionGroup(interventionGroup.id, newSessionWeek);
    }
  };

  useEffect(() => {
    if (saveClicked) {
      setSaveClicked(props.isLoading.patchInterventionGroup)
      setchangeDetected(props.isLoading.patchInterventionGroup)
    }
  }, [props.isLoading.patchInterventionGroup]);

  const discardChange = () => {
    setSchedule({
      time_monday_start,
      time_monday_end,
      time_tuesday_start,
      time_tuesday_end,
      time_wednesday_start,
      time_wednesday_end,
      time_thursday_start,
      time_thursday_end,
      time_friday_start,
      time_friday_end
    })
    if(interventionGroup.session_duration && interventionGroup.session_frequency) {
      setInterventionSchedule();
    } else {
      setselectedMinuteOption(null);
      setselectedSessionOption(null);
    }
  };

  const setInterventionSchedule = () => {
    if(interventionGroup.session_duration ) {
      const setSession = MinuteOptions.find(option => option.value === interventionGroup.session_duration);
      setselectedMinuteOption(setSession || null);
    }
  
    if(interventionGroup.session_frequency ) {
      const setFrequency =  SessionOptions.find(option => option.value === interventionGroup.session_frequency);
      setselectedSessionOption(setFrequency || null);
    }
  }

  useEffect(() => {
    setInterventionSchedule();
  },[interventionGroup])

  const {
    time_monday_start,
    time_monday_end,
    time_tuesday_start,
    time_tuesday_end,
    time_wednesday_start,
    time_wednesday_end,
    time_thursday_start,
    time_thursday_end,
    time_friday_start,
    time_friday_end
  } = interventionGroup;

  const [changeDetected, setchangeDetected] = useState(false);
  const [schedule, setSchedule] = useState({
    time_monday_start,
    time_monday_end,
    time_tuesday_start,
    time_tuesday_end,
    time_wednesday_start,
    time_wednesday_end,
    time_thursday_start,
    time_thursday_end,
    time_friday_start,
    time_friday_end
  });

  useEffect(() => {
    if (JSON.stringify(schedule) != JSON.stringify({
      time_monday_start,
      time_monday_end,
      time_tuesday_start,
      time_tuesday_end,
      time_wednesday_start,
      time_wednesday_end,
      time_thursday_start,
      time_thursday_end,
      time_friday_start,
      time_friday_end
    })) {
      setchangeDetected(true);
    } else {
      setchangeDetected(false);
    }

  }, [schedule,interventionGroup])
  
    const handleChangeSession = (option:any) => {
      setselectedSessionOption(option);
      if( ! selectedMinuteOption ) {
        setselectedMinuteOption(MinuteOptions[0]);
      }
      setchangeDetected(true);
    };

    const handleChangeMinute = (option:any) => {
      setselectedMinuteOption(option);
      if( ! selectedSessionOption ) {
        setselectedSessionOption(SessionOptions[0]);
      }
      setchangeDetected(true);
    };

    const customStyles: any = useMemo(
      () => ({
        control: (base: any) => ({
          ...base,
          minHeight: 30,
        }),
        dropdownIndicator: (base: any) => ({
          ...base,
          padding: 4,
        }),
        clearIndicator: (base: any) => ({
          ...base,
          padding: 4,
        }),
        valueContainer: (base: any) => ({
          ...base,
          padding: "0px 6px",
        }),
        input: (base: any) => ({
          ...base,
          margin: 0,
          padding: 0,
        }),
        menu: (base: any) => ({ ...base, zIndex: 100 }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
      }),
      []
    );

  return (
    <>
      <div className="row mb-1">
        <div className="col-auto pr-0">
          <p className="font-weight-font-size mb-0">Frequency <small>(Session(s)/Week)</small></p>
          <Select
            value={selectedSessionOption}
            onChange={handleChangeSession}
            options={SessionOptions}
            placeholder="Select.."
            styles={customStyles}
            isDisabled={isStudentDetail || isGroupDetail || isIntGrpIDsWithReadAccess}
          />
        </div>
        <div className="col-auto pr-0">
          <p className="font-weight-font-size mb-0 ">Duration <small>(Minutes/Session)</small></p>
          <Select
            value={selectedMinuteOption}
            onChange={handleChangeMinute}
            options={MinuteOptions}
            placeholder="Select.."
            styles={customStyles}
            isDisabled={isStudentDetail || isGroupDetail || isIntGrpIDsWithReadAccess}
          />
        </div>
      {/* <InterventionDayItem
          container={container}
          title="Mon"
          schedule
          setSchedule={(data: any) => { setSchedule({ ...schedule, ...data }) }}
          startTime={schedule.time_monday_start}
          endTime={schedule.time_monday_end}
          interventionGroupId={interventionGroup.id!}
          fieldPrefix="time_monday"
        />
        <InterventionDayItem
          container={container}
          title="Tue"
          schedule
          setSchedule={(data: any) => { setSchedule({ ...schedule, ...data }) }}
          startTime={schedule.time_tuesday_start}
          endTime={schedule.time_tuesday_end}
          interventionGroupId={interventionGroup.id!}
          fieldPrefix="time_tuesday"
        />
        <InterventionDayItem
          container={container}
          title="Wed"
          schedule
          setSchedule={(data: any) => { setSchedule({ ...schedule, ...data }) }}
          startTime={schedule.time_wednesday_start}
          endTime={schedule.time_wednesday_end}
          interventionGroupId={interventionGroup.id!}
          fieldPrefix="time_wednesday"
        />
        <InterventionDayItem
          container={container}
          title="Thu"
          schedule
          setSchedule={(data: any) => { setSchedule({ ...schedule, ...data }) }}
          startTime={schedule.time_thursday_start}
          endTime={schedule.time_thursday_end}
          interventionGroupId={interventionGroup.id!}
          fieldPrefix="time_thursday"
        />
        <InterventionDayItem
          container={container}
          title="Fri"
          schedule
          setSchedule={(data: any) => { setSchedule({ ...schedule, ...data }) }}
          startTime={schedule.time_friday_start}
          endTime={schedule.time_friday_end}
          interventionGroupId={interventionGroup.id!}
          fieldPrefix="time_friday"
        /> */}

      </div>
      {
        changeDetected &&
        <div className="scheduleActions my-2">
          <button
            data-cy="save-time-btn"
            className="blueBtnSm mr-1"
            onClick={handleSave}
          >
            Save Schedule{" "}
            {props.isLoading.patchInterventionGroup && saveClicked &&(
              <Spinner animation="border" size="sm" />
            )}
          </button>
          <button
            className="whiteBtnSm"
            onClick={() => {discardChange()}}
          >
            Cancel
          </button>
        </div>
      }
    </>


  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      patchInterventionGroup: patchInterventionGroup,
    },
    dispatch
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    isLoading: {
      patchInterventionGroup: cases.isLoading.patchInterventionGroup,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewTimeDayElements);

