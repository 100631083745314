import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CaseLoadUser, CoachInfo, DataPeriod, OnboardingState, StudentInfo, TeacherInfo } from "../../../../../../store/onboarding/types";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import ToolBar from "../common/ToolBar";
import { ApplicationState } from "../../../../../../store";
import { DataFilterState } from "../../../../../../store/dataFilter/types";
//import useInterventionGroupsMap from "./hooks/useInterventionGroupsMap";
import RecommendationsSection from "./RecommendationsSection";
import { getInterventionGroups, getRecommendations, hideGroupRecommendationFromData, hideInterventionPlanModal, resetGroupCreated } from "../../../../../../store/onboarding/cases/actions";
import { bindActionCreators, Dispatch } from "redux";
import { changeIntGroupAssigmentCollapsedSettings, getCaseLoadUsers, getCoaches, getTeachers, openInterventionStatsModal } from "../../../../../../store/onboarding/actions";
import { push } from "connected-react-router";
import { Accordion, Alert, Button, Card, Collapse, Image } from "react-bootstrap";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import SvgCircle from "../common/SvgCircle";
import { getSkillFocuses, openOverviewModel } from "../../../../../../store/groups/actions";
import InterventionStatsModal from "./InterventionStatsModal";
import { UserInfo } from "../../../../../../store/auth/types";
import InterventionFidelityTour from "../../../tours/InterventionFidelityTour";
import FidelityChartTour from "../../../tours/FidelityChartTour";
import { CaseInterventionGroup, InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import InterventionsGroup from "../assigment/InterventionsGroup";
import useGroupCardDragging from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useGroupCardDragging";
import useUnsavedGroupCards from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/hooks/useUnsavedGroupCards";
import { createBrowserHistory } from "history";
import useActiveTab from "../../../../parent-dashboard/useActiveTab";
import UnsaveConfirmModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/UnsaveConfirmModal";
import AddInterventionModal from "../../../../../common/onboarding/third-step/find-intervention/AddInterventionModal";
import SetTimeModal from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/modals/SetTimeModal";

type OwnProps = {
  student?: StudentInfo;
  isYourAssignments?: boolean;
  activeTab?: string;
};

type StateProps = {
  // showArchivedGroups?: boolean;
  // exitedStudentsGroup?: boolean;
  collapsedIntGroupAssignmentsIds: number[];
  caseLoadUsers: CaseLoadUser[];
  caseInterventionGroups: CaseInterventionGroup;
  isGroupCreated: boolean;
  selectedInterventionGroup?: InterventionGroup;
  isLoading: {
    getCaseLoadUsers: boolean;
    getInterventionGroups: boolean;
    createInterventionGroup: boolean;
    createStudentGoalForInterventionGroup: boolean;
    loaderIntGroupBehavior: boolean;
    loaderIntGroupBehaviorArchived: boolean;
    loaderIntGroupReading: boolean;
    loaderIntGroupReadingArchived: boolean;
    loaderIntGroupStudentDetail: boolean;
    loaderIntGroupStudentDetailArchived: boolean;
    addInterventionsToGroup: boolean;
    finishInterventionGroup: boolean;
    getTeachers: boolean;
  },
  errors: {
    getCaseLoadUsers: any;
    getInterventionGroups: any;
  }
};

type DispatchProps = {
  push: (location: string) => any;
  changeIntGroupAssigmentCollapsedSettings: (args: {
    id: number;
    collapsed?: boolean;
  }) => any;
  getCaseLoadUsers: () => any;
  getInterventionGroups: (archived?: boolean, tabName?: string, studentId?: number ) => any;
};

type Props = OwnProps & StateProps & DispatchProps;
  
const CaseLoadUserList = ({ 
  student, 
  isYourAssignments, 
  activeTab, 
  caseLoadUsers,
  changeIntGroupAssigmentCollapsedSettings,
  caseInterventionGroups,
  isGroupCreated,
  selectedInterventionGroup,
  isLoading,
  //exitedStudentsGroup,
}: Props) => {
  const { isParent, isSupervisor, isTeacher, isCoach, isDistrictOwner } = useUserRole();
  const dispatch = useDispatch();
  const tabName = useSelector<ApplicationState>((s)=> s.cases.tabName);

  const [ isStudentDetail, setIsStudentDetail] = useState<boolean>(false);
  const [openShareData, setOpenShareData] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedStates, setCollapsedStates] = useState<any>({});
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname); // Type as string
  const [intGrpIDsWithEditAccess, setIntGrpIDsWithEditAccess] = useState<number[]>([]);
  const [intGrpIDsWithReadAccess, setIntGrpIDsWithReadAccess] = useState<number[]>([]);

  const hasMounted = useRef(false);

  const openRecommendationGroupFromData = useSelector<ApplicationState, boolean>(
    (s) => s.cases.openRecommendationGroupFromData
  )
  const { groupSearch, showArchivedGroups, groupSort, exitedStudentsGroup } = useSelector<
    ApplicationState,
    DataFilterState
  >((s) => s.dataFilter);

  const displayGroupChange = useSelector<
    ApplicationState
  >((s) => s.cases.displayGroupName);

  const loggedInUserId = useSelector<
    ApplicationState
  >((s) => s.auth.userInfo?.id);

  useEffect(() => {
    Object.keys(collapsedStates).forEach((caseId) => {
      //console.log(caseInterventionGroups,collapsedStates[caseId], caseId)
      if (collapsedStates[caseId]) {
        let caseLoadId = caseId.split('_')[2];
        if (!caseInterventionGroups[caseLoadId] || !caseInterventionGroups[caseLoadId].length) { // Fetch only if not already loaded
          setCollapsedStates((prevStates: any) => ({
            ...prevStates,
            [caseId]: !prevStates[caseId], // Toggle the state for the specific case_id
          }));
        }
      }
    });
  }, [caseInterventionGroups])
  
  useEffect(() => {
    let queryStr = '';
    if(activeTab && (activeTab == 'groups/reading' || activeTab == 'groups/behavior')) {
      if(activeTab == 'groups/reading') {
        queryStr = '?is_behavior=0'
      }
      if(activeTab == 'groups/behavior') {
        queryStr = '?is_behavior=1'
      }
      if(showArchivedGroups || (displayGroupChange == 'archived')) {
        queryStr += '&archived=true'
      }
      dispatch(getCaseLoadUsers(queryStr))
    } else {
      if(student) {
        queryStr += `?student_id=${student.id}`
        if(showArchivedGroups || (displayGroupChange == 'archived')) {
          queryStr += '&archived=true'
        } else if(exitedStudentsGroup || (displayGroupChange == 'exited_groups')) {
          queryStr += '&exited=true'
        }
        dispatch(getCaseLoadUsers(queryStr))
      }
    }
  },[activeTab, displayGroupChange, student])

  // const {
  //   interventionGroupsMap,
  //   interventionGroupsMapWithNotFinished,
  // } = useInterventionGroupsMap({ student });

  // const intGroupsMap = groupSort
  // ? interventionGroupsMap
  // : interventionGroupsMapWithNotFinished;

  const currentDataPeriod = useSelector<
      ApplicationState,
      DataPeriod | undefined
    >((s) => s.onboarding.currentDataPeriod);
  
    const isStudentDetailReducer = useSelector<
      ApplicationState
    >((s) => s.cases.isStudentDetail);
  
    useEffect(() => {
      setIsStudentDetail(!!isStudentDetailReducer);
    },[displayGroupChange,isStudentDetailReducer])


  useEffect(() => {
    if (isYourAssignments && (tabName === "groups") && !isStudentDetail) {
      dispatch(getRecommendations());
    }
    // if (isYourAssignments && (tabName === "groups/behavior" || tabName === "groups/reading")) {
    //   dispatch(getRecommendations());
    // }
  }, [currentDataPeriod?.id, tabName]);

  // useEffect(() => {
  //   // Only execute this effect if the component has mounted
  //   if (hasMounted.current) {
  //     setCurrentPath(location.pathname); // Update current path to the new path
  //   } else {
  //     hasMounted.current = true; // Mark that the component has mounted
  //   }
  // }, [location]); // Depend on location changes

  // useEffect(() => {
  //   if(!openRecommendationGroupFromData) {
  //     if(currentPath?.includes('groups')) {
  //       changeIntGroupAssigmentCollapsedSettings({
  //         id: 0,
  //         collapsed: false
  //       });
  //       setCollapsed(false);
  //     }
  //   } else {
  //     dispatch(hideGroupRecommendationFromData())
  //   }
  // },[currentPath])

  useEffect(() => {
    Object.keys(collapsedStates).forEach((caseId) => {
      //console.log(caseInterventionGroups,collapsedStates[caseId], caseId)
      if (collapsedStates[caseId]) {
        let caseLoadId = caseId.split('_')[2];
        if (!caseInterventionGroups[caseLoadId] || !caseInterventionGroups[caseLoadId].length) { // Fetch only if not already loaded
          dispatch(getInterventionGroups((showArchivedGroups || (displayGroupChange == 'archived')), activeTab, student?.id, caseLoadId, (exitedStudentsGroup || (displayGroupChange == 'exited_groups'))));
        }
      }
    });
  }, [collapsedStates]); // Trigger when any collapse state changes

  useEffect(() => {
    setTimeout(() => {
      if(isGroupCreated) {
        if(Object.keys(caseInterventionGroups).length){
          const groupCard = document.getElementById(`group_card_${selectedInterventionGroup?.id}`);
          if (groupCard) {
            groupCard.scrollIntoView();
            groupCard.classList.add("highlighted");
            setTimeout(() => groupCard.classList.remove("highlighted"), 1500);
            dispatch(resetGroupCreated());
          }
        }
      }
    },500)
  },[isGroupCreated, caseInterventionGroups])

  const historyURL = createBrowserHistory();
  const { changeActiveTab } = useActiveTab();
  
  useEffect(() => {
    return historyURL.listen((location, action) => {
      if(isYourAssignments && (tabName === "groups/behavior" || tabName === "groups/reading")) {
        if(action == 'POP') {
          changeActiveTab(tabName);
          dispatch(hideInterventionPlanModal());
        }  
      }
    })
  },[tabName])

  useEffect(() => {
    // if (isYourAssignments && tabName === "groups") {
    //   dispatch(getSkillFocuses());
    //   dispatch(getNotFinishedGroups());
    // }
    if (isYourAssignments || isDistrictOwner) {
      dispatch(getSkillFocuses());
        if(!showArchivedGroups) {
          //dispatch(getNotFinishedGroups());
        }
    }
  }, [isYourAssignments, tabName, displayGroupChange]);
  


  const teachers = useSelector<ApplicationState, TeacherInfo[]>(
    (s) => s.onboarding.teachersRosterWithDummyUser
  );

  const coaches = useSelector<ApplicationState, CoachInfo[]>(
    (s) => s.onboarding.coachesRosterWithDummyUser
  );

  useEffect(() => {
    if (!coaches.length) {
      dispatch(getCoaches());
    }
    if (!teachers.length && !isLoading.getTeachers) {
      dispatch(getTeachers());
    }
  }, []);

  const { onDragStart, onDragEnd } = useGroupCardDragging();

  const progressOverviewLoader = useMemo(() => {
    return  isLoading?.loaderIntGroupBehavior
     || isLoading?.loaderIntGroupBehaviorArchived
     || isLoading?.loaderIntGroupReading
     || isLoading?.loaderIntGroupReadingArchived
     || isLoading?.loaderIntGroupStudentDetail
     || isLoading?.loaderIntGroupStudentDetailArchived
  },[isLoading])

  const handleCollapse = (caseLoadUserId: number, caseLoadType:string) => {
    changeIntGroupAssigmentCollapsedSettings({
      id: caseLoadUserId,
      collapsed: !collapsed,
    });
    setCollapsed((pS) => !pS);
    setCollapsedStates((prevStates: any) => ({
      ...prevStates,
      [caseLoadType + caseLoadUserId]: !prevStates[caseLoadType + caseLoadUserId], // Toggle the state for the specific case_id
    }));
  };

  const activeView = useSelector<ApplicationState, "list" | "grid">(
    (s) => s.cases.activeGroupsView
  );

  return (
    <>
      <div id="groups-container">
        {!isParent && (
          <>
            <div className="mb-3">
              <ToolBar
                isYourAssignments={isYourAssignments}
                student={student}
                activeTab={activeTab}
              />
            </div>

            {isYourAssignments && !showArchivedGroups && <RecommendationsSection />}
          </>
        )}
      </div>
      {isLoading.getCaseLoadUsers ? (
          <LoadingIndicator />
        ) : caseLoadUsers?.length ? (
          <div className={groupSort ? "sorted-groups-container" : undefined}>
          {!isStudentDetail && (isCoach || isTeacher) && 
            <>
              {caseLoadUsers.filter((caseLoadUser: any) => !caseLoadUser.read_only_access).map((caseLoadUser: any, i) => {
                const isCollapsed: boolean = (collapsedStates[`edit_${i}_`+caseLoadUser.case_id]) || false;
             return <div key={i}>
                <div
                  data-cy={`employee_assignment_${caseLoadUser.case_id}`}
                  id={`employee_assignment_${caseLoadUser.case_id}`}
                  className="assigmentCollapseHeader"
                  aria-controls={`assigment-collapse_${caseLoadUser.case_id}`}
                  aria-expanded={isCollapsed}
                  onClick={() => handleCollapse(caseLoadUser.case_id, `edit_${i}_`)}
                >
                {/* {( caseLoadUser?.share_data_with || displayGroupChange == 'archived') && !groupSort && (  */}
                  <span className="mr-3">
                    <FontAwesomeIcon
                      icon={isCollapsed ? faCaretDown : faCaretRight}
                      size="2x"
                    />
                  </span>
                {/* )} */}
                <span className="mr-3">
                <Image
                  style={{
                    objectFit: "cover",
                    objectPosition: "top center",
                    border: "1px solid #616161",
                  }}
                  src={caseLoadUser.profile_image_url}
                  width={30}
                  height={30}
                  roundedCircle
                />
                </span>
                <h2>{caseLoadUser.case_name}</h2>

                {!isParent && !isCollapsed && !!caseLoadUser.alert_count && (
                  <SvgCircle
                    svgSize={30}
                    text={caseLoadUser.alert_count}
                    className="notificationBubble mx-2"
                  />
                )}
                {//caseLoadUser?.share_data_with ?
                    <button
                      className="ml-2 blueBtnSm"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openInterventionStatsModal(caseLoadUser));
                        dispatch(openOverviewModel('one_educator'));
                      }}
                    >
                      Show Overview
                    </button>
                  // :
                  // ''
                }
                </div>
                <Collapse in={isCollapsed}>
                  <div id={`assigment-collapse_${caseLoadUser.case_id}`}>
                    <div className={`assigmentInfo ${activeView}`}>
                          { isLoading.getInterventionGroups 
                          || isLoading.createInterventionGroup
                          || isLoading.createStudentGoalForInterventionGroup
                         // || progressOverviewLoader
                          || isLoading.addInterventionsToGroup
                          || isLoading.finishInterventionGroup
                            ? 
                              <LoadingIndicator />
                            :
                              caseInterventionGroups[caseLoadUser.case_id] ? 
                                <InterventionsGroup
                                  isReadonly={isParent}
                                  isReadonlyCaseUser = {caseLoadUser.read_only_access}
                                  interventionGroups={caseInterventionGroups[caseLoadUser.case_id]}
                                  student={student}
                                  // {...rest}
                                  // isLoadingPatchInterventionGroup={
                                  //   isLoadingPatchInterventionGroup
                                  // }
                                  teachers={teachers}
                                  coaches={coaches}
                                  isTeacher={isTeacher}
                                  isCoach={isCoach}
                                  isDistrictOwner={isDistrictOwner}
                                  onDragStart={onDragStart}
                                  onDragEnd={onDragEnd}
                                  //shareDataWith={false}
                                  notShareDataWith={false}
                                  isYourAssignments={isYourAssignments}
                                  caseId = {caseLoadUser.case_id}
                                  //intGrpIDsWithEditAccess={intGrpAccess.intGrpIDsWithEditAccess}
                                  intGrpIDsWithEditAccess={intGrpIDsWithEditAccess}
                                  onIntGrpIDsWithEditAccess={(interventionGroupId: number) => 
                                    setIntGrpIDsWithEditAccess((intGrpIDsWithEditAccess) => 
                                      [...intGrpIDsWithEditAccess, interventionGroupId])
                                  }
                                  progressOverviewLoader = {progressOverviewLoader} 
                                /> 
                              :
                              <Alert variant="warning">
                                No interventions groups available. 
                              </Alert>
                          }
                    </div>
                  </div>
                </Collapse> 
              </div>
              })}
              {caseLoadUsers.filter((caseLoadUser: any) => caseLoadUser.read_only_access).map((caseLoadUser: any, j) => {
                const isCollapsed: boolean = collapsedStates[`read_${j}_`+caseLoadUser.case_id] || false;
                return <div key={j}>
                  {j == 0 && 
                    <>
                      <hr/>  
                      <h3>Groups with Read-Only Access</h3> 
                    </>
                  }
                <div
                  data-cy={`employee_assignment_${caseLoadUser.case_id}`}
                  id={`employee_assignment_${caseLoadUser.case_id}`}
                  className="assigmentCollapseHeader"
                  aria-controls={`assigment-collapse_${caseLoadUser.case_id}`}
                  aria-expanded={isCollapsed}
                  onClick={() => handleCollapse(caseLoadUser.case_id, `read_${j}_`)}
                >
                {/* {( caseLoadUser?.share_data_with || displayGroupChange == 'archived') && !groupSort && (  */}
                  <span className="mr-3">
                    <FontAwesomeIcon
                      icon={isCollapsed ? faCaretDown : faCaretRight}
                      size="2x"
                    />
                  </span>
                {/* )} */}
                <span className="mr-3">
                <Image
                  style={{
                    objectFit: "cover",
                    objectPosition: "top center",
                    border: "1px solid #616161",
                  }}
                  src={caseLoadUser.profile_image_url}
                  width={30}
                  height={30}
                  roundedCircle
                />
                </span>
                <h2>{caseLoadUser.case_name}</h2>

                {!isParent && !isCollapsed && !!caseLoadUser.alert_count && (
                  <SvgCircle
                    svgSize={30}
                    text={caseLoadUser.alert_count}
                    className="notificationBubble mx-2"
                  />
                )}
                {//caseLoadUser?.share_data_with ?
                    <button
                      className="ml-2 blueBtnSm"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openInterventionStatsModal(caseLoadUser.user));
                        dispatch(openOverviewModel('one_educator'));
                      }}
                    >
                      Show Overview
                    </button>
                  // :
                  // ''
                }
                </div>
                <Collapse in={isCollapsed}>
                  <div id={`assigment-collapse_${caseLoadUser.case_id}`}>
                    <div className={`assigmentInfo ${activeView}`}>
                    { isLoading.getInterventionGroups 
                      || isLoading.createInterventionGroup
                      || isLoading.createStudentGoalForInterventionGroup
                      //|| progressOverviewLoader
                      || isLoading.addInterventionsToGroup
                      || isLoading.finishInterventionGroup
                        ? 
                          <LoadingIndicator />
                        :
                          caseInterventionGroups[caseLoadUser.case_id] ? 
                            <InterventionsGroup
                              isReadonly={isParent}
                              isReadonlyCaseUser = {caseLoadUser.read_only_access}
                              interventionGroups={caseInterventionGroups[caseLoadUser.case_id]}
                              student={student}
                              key={`teacher_${j}`}
                              // {...rest}
                              // isLoadingPatchInterventionGroup={
                              //   isLoadingPatchInterventionGroup
                              // }
                              teachers={teachers}
                              coaches={coaches}
                              isTeacher={isTeacher}
                              isCoach={isCoach}
                              isDistrictOwner={isDistrictOwner}
                              onDragStart={onDragStart}
                              onDragEnd={onDragEnd}
                              //shareDataWith={false}
                              notShareDataWith={false}
                              isYourAssignments={isYourAssignments}
                              intGrpIDsWithReadAccess={intGrpIDsWithReadAccess}
                              onIntGrpIDsWithReadAccess={(interventionGroupId: number) => 
                                setIntGrpIDsWithReadAccess((intGrpIDsWithReadAccess) => 
                                  [...intGrpIDsWithReadAccess, interventionGroupId])
                              }
                            />
                          :
                          <Alert variant="warning">
                            No interventions groups available. 
                          </Alert>
                    }
                    </div>
                  </div>
                </Collapse> 
                </div>
              })}
            </>
          }
          {isStudentDetail && (isCoach || isTeacher) ?
            <>
              {caseLoadUsers.filter((caseLoadUser: any) => caseLoadUser?.share_data_with)?.length ? 
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="" eventKey="0" onClick={() => setOpenShareData(!openShareData)}>
                        {openShareData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
                        {isCoach && caseLoadUsers.filter((caseLoadUser: any) => caseLoadUser?.share_data_with)?.length ? ` Teachers you share data with OR groups for whom you are a Coach.`  : ''}
                        {isTeacher && caseLoadUsers.filter((caseLoadUser: any) => caseLoadUser?.share_data_with)?.length ? ` Coaches you share data with OR groups for whom you are an Interventionist.`  : ''}
                      </Accordion.Toggle>
                    </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <div className="ml-2">
                    {caseLoadUsers.filter((caseLoadUser: any) => caseLoadUser?.share_data_with).map((caseLoadUser: any, k) => {
                      const isCollapsed: boolean = (collapsedStates[`edit_${k}_`+caseLoadUser.case_id]) || false;
                      return <div key={k}>
                          <div
                            data-cy={`employee_assignment_${caseLoadUser.case_id}`}
                            id={`employee_assignment_${caseLoadUser.case_id}`}
                            className="assigmentCollapseHeader"
                            aria-controls={`assigment-collapse_${caseLoadUser.case_id}`}
                            aria-expanded={isCollapsed}
                            onClick={() => handleCollapse(caseLoadUser.case_id, `edit_${k}_`)}
                          >
                          {( caseLoadUser?.share_data_with || displayGroupChange == 'archived') && !groupSort && ( 
                            <span className="mr-3">
                              <FontAwesomeIcon
                                icon={isCollapsed ? faCaretDown : faCaretRight}
                                size="2x"
                              />
                            </span>
                          )}
                          <span className="mr-3">
                          <Image
                            style={{
                              objectFit: "cover",
                              objectPosition: "top center",
                              border: "1px solid #616161",
                            }}
                            src={caseLoadUser.profile_image_url}
                            width={30}
                            height={30}
                            roundedCircle
                          />
                          </span>
                          <h2>{caseLoadUser.case_name}</h2>

                          {!isParent && !isCollapsed && !!caseLoadUser.alert_count && (
                            <SvgCircle
                              svgSize={30}
                              text={caseLoadUser.alert_count}
                              className="notificationBubble mx-2"
                            />
                          )}
                          {caseLoadUser?.share_data_with ?
                              <button
                                className="ml-2 blueBtnSm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(openInterventionStatsModal(caseLoadUser));
                                  dispatch(openOverviewModel('one_educator'));
                                }}
                              >
                                Show Overview
                              </button>
                            :
                            ''
                          }
                          </div>
                          <Collapse in={isCollapsed}>
                            <div id={`assigment-collapse_${caseLoadUser.case_id}`}>
                              <div className={`assigmentInfo ${activeView}`}>
                                    { isLoading.getInterventionGroups 
                                      ? 
                                        <LoadingIndicator />
                                      :
                                      caseInterventionGroups[caseLoadUser.case_id] && 
                                        <InterventionsGroup
                                          isReadonly={isParent}
                                          interventionGroups={caseInterventionGroups[caseLoadUser.case_id]}
                                          student={student}
                                          key={`teacher_${k}`}
                                          // {...rest}
                                          // isLoadingPatchInterventionGroup={
                                          //   isLoadingPatchInterventionGroup
                                          // }
                                          teachers={teachers}
                                          coaches={coaches}
                                          isTeacher={isTeacher}
                                          isCoach={isCoach}
                                          isDistrictOwner={isDistrictOwner}
                                          onDragStart={onDragStart}
                                          onDragEnd={onDragEnd}
                                          //shareDataWith={true}
                                          notShareDataWith={false}
                                          isYourAssignments={false}
                                          caseId = {caseLoadUser.case_id}
                                          //intGrpIDsWithEditAccess={intGrpAccess.intGrpIDsWithEditAccess}
                                        /> 
                                    }
                              </div>
                            </div>
                          </Collapse> 
                        </div>
                      })
                    }
                    </div>
                  </Accordion.Collapse>
                </Card>
                </Accordion>
                : ''
              } 
              {caseLoadUsers.filter((caseLoadUser: any) => !caseLoadUser?.share_data_with)?.length ?  
                <Card>
                  <Card.Header>
                      {isCoach && caseLoadUsers.filter((caseLoadUser: any) => !caseLoadUser?.share_data_with)?.length ? ` Teachers you do not share data with OR groups for whom you are not a Coach.` : ''}
                      {isTeacher && caseLoadUsers.filter((caseLoadUser: any) => !caseLoadUser?.share_data_with)?.length ? ` Coaches you do not share data with OR groups for whom you are not an Interventionist.` : ''}
                  </Card.Header>
                  <Card.Body>
                    <div className="px-2">
                    {caseLoadUsers.filter((caseLoadUser: any) => !caseLoadUser?.share_data_with)?.map((caseLoadUser: any, k) => {
                    return <div key={k}>
                      <div
                          className="assigmentCollapseHeader"
                        >
                        <span className="mr-3">
                        <Image
                          style={{
                            objectFit: "cover",
                            objectPosition: "top center",
                            border: "1px solid #616161",
                          }}
                          src={caseLoadUser.profile_image_url}
                          width={30}
                          height={30}
                          roundedCircle
                        />
                        </span>
                        <h2>{caseLoadUser.case_name}</h2>
                      </div>
                      </div>
                    })
                  } 
                    </div>          
                  </Card.Body>
                </Card>
              : ''}
            </>
          : ''
         }
          {!(isParent || isSupervisor || isTeacher) && isYourAssignments && (
            <>
              <InterventionFidelityTour />
              <FidelityChartTour />
            </>
          )}
        </div>
        ) : groupSearch.length ? (
          <Alert variant="warning">
            No matching interventions groups. Try to change the search options
          </Alert>
        ) : showArchivedGroups ? (
          <Alert variant="warning">
            You don&apos;t have archived groups{student && " with this student"}
            .
          </Alert>
        ) 
        : ''
        // (student || !interventionGroupsMap?.length) && (
        //   <Alert variant="warning">
        //     Student doesn&apos;t have any groups yet.
        //   </Alert>
        // )
      }

     
      <InterventionStatsModal student ={student}/>

      <AddInterventionModal />
    </>
  );
};

const mapStateToProps = ({
  onboarding,
  dataFilter,
  cases
}: ApplicationState): StateProps => {
  return {
    collapsedIntGroupAssignmentsIds: onboarding.collapsedIntGroupAssignmentsIds,
    // showArchivedGroups: dataFilter.showArchivedGroups,
    // exitedStudentsGroup: dataFilter.exitedStudentsGroup,
    caseLoadUsers: onboarding.caseLoadUsers,
    caseInterventionGroups: cases.caseInterventionGroups,
    isGroupCreated: cases.isGroupCreated,
    selectedInterventionGroup: cases.selectedInterventionGroup,
    isLoading: {
      getCaseLoadUsers: onboarding.isLoading.getCaseLoadUsers,
      getInterventionGroups: cases.isLoading.getInterventionGroups,
      createInterventionGroup: cases.isLoading.createInterventionGroup,
      createStudentGoalForInterventionGroup: cases.isLoading.createStudentGoalForInterventionGroup,
      loaderIntGroupBehavior: cases.isLoading.loaderIntGroupBehavior!,
      loaderIntGroupBehaviorArchived: cases.isLoading.loaderIntGroupBehaviorArchived!,
      loaderIntGroupReading: cases.isLoading.loaderIntGroupReading!,
      loaderIntGroupReadingArchived: cases.isLoading.loaderIntGroupReadingArchived!,
      loaderIntGroupStudentDetail: cases.isLoading.loaderIntGroupStudentDetail!,
      loaderIntGroupStudentDetailArchived: cases.isLoading.loaderIntGroupStudentDetailArchived!,
      addInterventionsToGroup: cases.isLoading.addInterventionsToGroup,
      finishInterventionGroup: cases.isLoading.finishInterventionGroup,
      getTeachers: onboarding.isLoading.getTeachers
    },
    errors: {
      getCaseLoadUsers: onboarding.errors.getCaseLoadUsers,
      getInterventionGroups: cases.errors.getInterventionGroups,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      push: push,
      changeIntGroupAssigmentCollapsedSettings: changeIntGroupAssigmentCollapsedSettings,
      getCaseLoadUsers: getCaseLoadUsers,
      getInterventionGroups: getInterventionGroups,
      resetGroupCreated: resetGroupCreated
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseLoadUserList);