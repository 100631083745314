import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useMemo } from "react";

const useInterventionGroupAccess = (interventionGroupId: any) => {
    const intGrpAccessObj =  useSelector(
        (s: ApplicationState) => s.onboarding.intGrpAccessObj
    );
    
    let isIntGrpIDsWithReadAccess = useMemo(() => {
        if(intGrpAccessObj && intGrpAccessObj.intGrpIDsWithReadAccess?.length) {
            return intGrpAccessObj.intGrpIDsWithReadAccess?.includes(interventionGroupId);
        }
    },[intGrpAccessObj]);

    return {
        isIntGrpIDsWithReadAccess
    }
}

export default useInterventionGroupAccess;