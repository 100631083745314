import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  GoalTypes,
  InterventionGroup,
  StudentGoal,
} from "../../../store/onboarding/cases/types";
import { getFullNameForStudent } from "../../../utils/StudentMapUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCheckCircle,
  faEdit,
  faPlusCircle,
  faQuestionCircle,
  faSignOutAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  changeSetGoalsModal,
  openSetGoalChooseConcernModal,
} from "../../../store/onboarding/cases/actions";
import { getGoalTitle } from "../../utils";
import AddStudentsToTableOverlay from "../../common/onboarding/second-step/evidence-tab/student-data-table/AddStudentToTableOverlay";
import { getFullName } from "../../../utils/NamesUtils";
import { openEndInterventionModal } from "../../../store/onboarding/meeting-module/actions";
import useInterventionGroupAccess from "../../../utils/hooks/useInterventionGroupAccess";
import { showConfirmDialog } from "../../../store/confirm-dialog/actions";
import { Student } from "../../../store/onboarding/types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AddSecondaryGoalPositionTour from "../coach-dashboard/tours/AddSecondaryGoalPositionTour";
import { formatDate } from "../../utils/DateTimeUtils";
import moment from "moment";

type DispatchProps = {
  changeSetGoalsModal: (
    show: boolean,
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal
  ) => void;
  openSetGoalChooseConcernModal: (
    interventionGroup?: InterventionGroup,
    studentGoal?: StudentGoal
  ) => void;
  openEndInterventionModal: ({goal_id, is_primary_goal, student}: any) => any;
};

type StateProps = {
  interventionGroup?: InterventionGroup;
};

type OwnProps = {
  showAddStudentAction?: boolean;
  hideActions?: boolean;
  isStudentDetail?: boolean;
  isGroupDetail?: boolean;
  isUserAssignedToGroup?: boolean;
};

type Props = DispatchProps & StateProps & OwnProps;

const StudentGoalTable: FunctionComponent<Props> = ({
  hideActions,
  showAddStudentAction,
  interventionGroup,
  changeSetGoalsModal,
  openSetGoalChooseConcernModal,
  openEndInterventionModal,
  isStudentDetail,
  isGroupDetail,
  isUserAssignedToGroup,
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const addRef = useRef(null);
  const dispatch = useDispatch();

  const handleAddClick = () => {
    setShowOverlay((prevState) => !prevState);
  };

  const onHideAddOverlay = () => {
    setShowOverlay(false);
  };

  if (!interventionGroup) return null;

  const { isIntGrpIDsWithReadAccess } = useInterventionGroupAccess(interventionGroup?.id);

  const handleStudentGoalEdit = (studentGoal: StudentGoal) => () => {
    //if (interventionGroup.goal_type === GoalTypes.ACADEMIC) {
      openSetGoalChooseConcernModal(interventionGroup, studentGoal);
    // } else { // show old behavior screen
    //   changeSetGoalsModal(true, interventionGroup, studentGoal);
    // }
  };



  const handleStudentInterventionEnd = (studentGoal: StudentGoal) => () => {
    if(studentGoal.is_primary_goal) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            openEndInterventionModal({
              goal_id:studentGoal.id, 
              is_primary_goal: studentGoal.is_primary_goal, 
              student: studentGoal.student
            });
          },
          centered: true,
          title: 'Confirmation',
          timerElipseLeft: true,
          countDownTimer: 3,
          text: (
            <div className="text-center">
                Exiting student(s) from the group will archive all primary and secondary goals. 
                  Once exited, archived goals can be accessed from exited student section. 
                  Are you sure you want to continue?
            </div>
          ),
          confirmButtonText: "Confirm",
        })
      );
    } else {
      dispatch(
        openEndInterventionModal({
          goal_id:studentGoal.id, 
          is_primary_goal: studentGoal.is_primary_goal, 
          student: studentGoal.student
        })
      );
    }
  };

  let allStudentIdsOfGoals = useMemo(() => Array.from(new Set(interventionGroup.student_goals.map((goal) => goal.student.id))), [])
  
  let allStudentIdsOfActiveGoals = useMemo(() => {
    let allStdIdOfActiveGoals: any[] = [];
    interventionGroup.student_goals.map((goal) =>{
      if(!goal.archived) {
        allStdIdOfActiveGoals.push(goal.student.id)
      }
    })
    return allStdIdOfActiveGoals.length ? Array.from(new Set(allStdIdOfActiveGoals)) : []
  }, [])


  let uniqueArchivedGoals = useMemo(() => {
    let uniqeArchiveGoal = Array.from(allStudentIdsOfGoals).map((id, index) => {
     return interventionGroup.student_goals.find(
       (x) => {
         
         if (x.student.id === id && x.archived && !allStudentIdsOfActiveGoals?.includes(x.student.id)) {
           return true
         }
         return false
       }
     );
   });
   
   return uniqeArchiveGoal;
  },[allStudentIdsOfActiveGoals]);


  const currentStudentExist = useMemo(() => {
    let isCurrentStudentExist: boolean = false;
    if(!interventionGroup.archived) {
      interventionGroup.students.forEach((student, index) => {
        let studentGoal: StudentGoal[] | undefined;
        if (interventionGroup.student_goals) {
          studentGoal = interventionGroup.student_goals.filter(
            (x) => x.student.id === student.id && !x.archived
          );
        }
        if((studentGoal ? 
          !studentGoal.some((sg) => sg.archived) : true)) {
            isCurrentStudentExist = true;
        }
      });
    }
    return isCurrentStudentExist;
  },[interventionGroup])

  const handleSetGoalModal = (student?: Student) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          openSetGoalChooseConcernModal(interventionGroup, {
            ...(student ? {student: student} : ''),
            is_primary_goal: true
          } as StudentGoal)
        },
        centered: true,
        title: "Confirmation",
        text: (
          <>
            The first goal set for a group is the primary goal. 
            Progress towards the primary goal is used to determine a student’s response to intervention, 
            with automated alerts providing guidance for decision making. 
          </>
        ),
        confirmButtonText: "Confirm",
        timerElipseLeft: true,
        countDownTimer: 3,
      })
    );
  }

  const uniqueByKey = (array:Student[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const mergedStudentByArchiveStudentGoal = useMemo(() => {
    let allStudentByArchiveStudentGoal: Student[] = 
            interventionGroup.student_goals.filter((sg) => sg.archived).map((sg) => sg.student)
    if(interventionGroup.archived && interventionGroup.students.length){
      interventionGroup.students.forEach((intStd) =>{
        if(!allStudentByArchiveStudentGoal.some((std) => std.id == intStd.id)) {
          allStudentByArchiveStudentGoal.push(intStd);
        }
      })
    }
    let sortedAllStudentByArchiveStudentGoal = allStudentByArchiveStudentGoal.sort((a,b) => a.first_name < b.first_name ? -1: 1)
    let uniqueArchiveStudentByStudentGoals =  uniqueByKey(sortedAllStudentByArchiveStudentGoal, 'id');
    let studentGoals: any[] = []; 
    for (let index = 0; index < uniqueArchiveStudentByStudentGoals.length; index++) {
      const uniqueArchiveStudentByStudentGoal = uniqueArchiveStudentByStudentGoals[index];
      const uniqueArchivedStudentGoals = interventionGroup.student_goals.filter((sg) => 
        sg.archived && sg.student.id == uniqueArchiveStudentByStudentGoal.id);
      studentGoals.push(
        <tr key={uniqueArchiveStudentByStudentGoal?.id}>
          <td>{getFullNameForStudent(uniqueArchiveStudentByStudentGoal)}</td>
          <td colSpan={3} style={{margin: 0, padding: 0}}>
              <Table className="table-bordered innerTable" style={{marginBottom: '0px'}}>
                  {uniqueArchivedStudentGoals?.length 
            ? uniqueArchivedStudentGoals?.sort((a: StudentGoal,b: StudentGoal) => a?.is_primary_goal ? -1 : 1)
                  .map((sg: StudentGoal) => 
                  (
                    (sg != undefined) && (sg.student.id == uniqueArchiveStudentByStudentGoal.id) &&
                    (<tr key={sg?.id}>
                    <td style={{width: '120px', textAlign: 'center'}}>
                        { sg?.is_primary_goal ? 
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              size="1x"
                              className="purpleText mr-2"
                              title="Primary Goal"
                            />
                          : ''
                        }
                    </td>
                    <td>{sg?.goal_statement ?? getGoalTitle(sg!)}</td>
                    <td style={{width: '120px', textAlign: 'center'}}>{sg?.updated ? moment.utc(sg?.updated).format("MM/DD/YYYY"): ''}</td>
                    {hideActions ? (
                      <td />
                    ) 
                      : ''
                    }
                  </tr>) 
                  )) 
            : <tr>
                <td style={{width: '120px'}}></td>
                <td>
                  <i>{getFullNameForStudent(uniqueArchiveStudentByStudentGoal)} did not have a goal(s).</i>
                </td>
              </tr>
          }
              </Table>

          </td>
        </tr>
      ); 
    }
    return studentGoals;
  },[uniqueArchivedGoals])


  return (
    <>
      <AddSecondaryGoalPositionTour />
      <h3>Current Students</h3>
      <Table className="goalTable secondryGoalTable table-bordered">
        <thead>
          <tr>
            <th style={{width: '160px'}}>
              <div className="d-flex">
                <span>Student</span>
                {showAddStudentAction ? (
                  <div className="ml-2">
                    {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess 
                    ? <span
                      className="pointer"
                      ref={addRef}
                      onClick={handleAddClick}
                      title="Add student to the group"
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{ color: "#ffffff" }}
                        size="1x"
                      />
                    </span>
                    : ''
                    }
                    <AddStudentsToTableOverlay
                      forStudentGroup
                      showOverlay={showOverlay}
                      buttonRef={addRef}
                      onHide={onHideAddOverlay}
                    />
                    {showOverlay && <div className="customDarkPopoverBackdrop" />}
                  </div>
                ) : ''}
              </div>
            </th>
            <th style={{width: '75px'}}>
              <div className="d-flex justify-content-center">
                <span className="mr-1">Action</span>
                {currentStudentExist ? <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover id={'goalAction'}>
                        <Popover.Content>
                            <p className="mb-0">
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="1x"
                              /> Add a secondary goal
                            </p>
                            <p className="mb-0">
                              <FontAwesomeIcon
                                icon={faSignOutAlt}
                                size="1x"
                                className="redText"
                              /> Exit the student(s) from the group
                            </p>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      size="1x"
                      style={{ color: "#2547a5" }}
                      className="m-1"
                    />
                </OverlayTrigger> : ''}
              </div>
            </th>
            <th style={{width: '75px'}} className="text-center">Primary</th>
            <th style={{width: '340px'}}>Goals</th>
            <th style={{width: '120px'}}>Goal Set On</th>
          </tr>
        </thead>
        <tbody>
          {!interventionGroup.archived ? interventionGroup.students.sort((a,b) => a.first_name < b.first_name ? -1 : 1).map((student, index) => {
            let studentGoal: StudentGoal[] | undefined;
            if (interventionGroup.student_goals) {
              studentGoal = interventionGroup.student_goals.filter(
                (x) => x.student.id === student.id && !x.archived
              );
            }
            return (
              (studentGoal?.length ? 
                !studentGoal.every((sg) => sg.archived) : true)
                &&  (
                <tr key={student.id}>
                  <td style={{width: '100px'}}>{getFullNameForStudent(student)}</td>
                  <td style={{width: '100px'}}>
                  {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && studentGoal?.length ? (
                    <div className="d-flex justify-content-center">
                    <span
                      className="pointer mr-3 addSecondaryGoal"
                      onClick={() =>
                        //interventionGroup.goal_type === GoalTypes.ACADEMIC ? 
                            openSetGoalChooseConcernModal(interventionGroup, {
                              student: studentGoal?.length ? studentGoal[0].student : student,
                              is_primary_goal: false,
                              is_general_outcome: true
                            } as StudentGoal)
                            // show old behavior screen
                          // : changeSetGoalsModal(true, interventionGroup, {
                          //   student: studentGoal?.length ? studentGoal[0].student : student,
                          //   is_primary_goal: false,
                          //   is_general_outcome: true
                          // } as StudentGoal)
                      }
                      title="Add a secondary goal"
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        size="1x"
                      />
                    </span>
                    {isUserAssignedToGroup 
                      && studentGoal?.length ? 
                          studentGoal?.sort((a, b) => a.is_primary_goal ? -1 : 1).map((sg) => 
                          sg.is_primary_goal 
                          ? 
                            <span
                              className="pointer"
                              onClick={handleStudentInterventionEnd(sg)}
                            >
                              <FontAwesomeIcon
                                icon={faSignOutAlt}
                                size="1x"
                                className="redText"
                                title={"Exit the student(s) from the group"}
                              />
                            </span> 
                          : '')
                         : '' }
                    </div>
                    ) : ''}
                  </td>
                  <td  className="border-0" colSpan={3} style={{margin: 0, padding: 0}}>
                    <Table className="table-bordered innerTable" style={{marginBottom: '0px'}}>
                      {studentGoal?.length ? 
                        studentGoal?.sort((a, b) => a.is_primary_goal ? -1 : 1).map((sg) => 
                         (
                        <tbody key={sg.id}>
                          <tr>
                            <td style={{width: '94px', textAlign: 'center'}}>
                                { sg.is_primary_goal ? 
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="1x"
                                      className="purpleText mr-2"
                                      title="Primary Goal"
                                    />
                                  : ''
                                }
                            </td>
                            <td className="border-right-0" style={{width: !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? '280px' : '340px'}}>
                              {sg.is_general_outcome 
                              ? 
                              <FontAwesomeIcon
                                icon={faChartLine}
                                size="1x"
                                className="blueText mr-2"
                                title="General Outcome"
                              />
                              : ''}
                              {sg.goal_statement ?? getGoalTitle(sg)}
                            </td>
                            {hideActions ? (
                              <td />
                            ) : 
                              !isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? 
                                (<td style={{minHeight: sg?.goal_statement?.length || getGoalTitle(sg).length > 50 ? '74px' : '0px', width: '60px', verticalAlign:'top'}}>
                                  {sg.goal_statement == null && 
                                  <FontAwesomeIcon
                                      icon={faEdit}
                                      size="1x"
                                      className="purpleText pointer mr-1"
                                      onClick={handleStudentGoalEdit(sg)}
                                      title={'Edit Goal'}
                                    />
                                  }
                                  {isUserAssignedToGroup && !sg.is_primary_goal ?
                                  <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      size="1x"
                                      className="purpleText pointer"
                                      title={'Remove Goal'}
                                      onClick={handleStudentInterventionEnd(sg)}
                                    /> : ''}
                                </td>)
                              : ''
                            }
                            <td className="border-right-0 goalSetOn">{sg?.created ? moment.utc(sg?.created).format("MM/DD/YYYY"): ''}</td>
                          </tr>
                        </tbody>
                        )) : interventionGroup.student_goals ? (
                          <tbody>
                            <tr className="border-0">
                              <td style={{width: '78px'}}></td>
                              <td style={{width: '282px'}}>
                                <i>
                                  {getFullName(student)} doesn&apos;t have any goals yet
                                </i>
                              </td>
                              <td style={{width: '99px'}}>
                                {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? <button
                                  className="blueBtnSm"
                                  onClick={() => handleSetGoalModal(student)}
                                >
                                  Set goal
                                </button> : ''}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          index === 0 && (
                            <tbody>
                              <tr className="border-0">
                                <td style={{width: '78px'}}></td>
                                <td style={{width: '282px'}}
                                >
                                  <i>
                                    This group doesn&apos;t have any goals yet
                                  </i>
                                </td>
                                <td style={{width: '99px'}}>
                                  {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess ? <button
                                    className="blueBtnSm"
                                    onClick={() => handleSetGoalModal()}
                                  >
                                    Set goals
                                  </button> : ''}
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      
                    </Table>
                  </td>
                </tr>
              )
            );
          }) : ''}
          {!currentStudentExist ? <tr><td colSpan={5} className="text-center">No Record found</td></tr> : ''}
        </tbody>
      </Table>
      <hr/>
      <h3>Archived Goals and/or Exited Students</h3>
      <Table className="goalTable secondryGoalTable table-bordered">
        <thead>
          <tr>
            <th>Student</th>
            <th style={{width: '120px', textAlign: 'center'}}>Primary</th>
            <th>Goals</th>
            <th style={{width: '120px', textAlign: 'center'}}>Archived / Exited ON</th>
          </tr>
        </thead>
        <tbody>
          {mergedStudentByArchiveStudentGoal}
        </tbody>
      </Table>
    </>
  );
};

const mapStateToProps = ({ cases }: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      changeSetGoalsModal: changeSetGoalsModal,
      openSetGoalChooseConcernModal: openSetGoalChooseConcernModal,
      openEndInterventionModal: openEndInterventionModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentGoalTable);