import React, { CSSProperties } from "react";
import { InterventionGroup } from "../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { getFullNameForStudent } from "../../../../../../utils/StudentMapUtils";

type Props = {
  interventionGroups: any;
  style?: CSSProperties;
};
type State = {
  selectedGroupIndex: number;
};

class StudentGroupsTooltip extends React.Component<Props, State> {
  state: Readonly<State> = {
    selectedGroupIndex: 0,
  };

  handleAnotherGroupShow = (back?: boolean) => () => {
    const groupsLastIndex = this.props.interventionGroups.length - 1;

    if (back) {
      this.setState((prevState) => ({
        selectedGroupIndex: prevState.selectedGroupIndex
          ? prevState.selectedGroupIndex - 1
          : groupsLastIndex,
      }));
    } else {
      this.setState((prevState) => ({
        selectedGroupIndex:
          prevState.selectedGroupIndex < groupsLastIndex
            ? prevState.selectedGroupIndex + 1
            : 0,
      }));
    }
  };

  render() {
    const { interventionGroups } = this.props;
    const { selectedGroupIndex } = this.state;
    return (
      <div
        className="interventionGroupsInfoTooltip"
        style={{
          ...this.props.style,
        }}
      >
         <p className="font-weight-bold mb-0">Intervention Group(s) with student(s) count:</p>
         <ul>
            {interventionGroups.map((interventionGroup: any) => 
              <li>
                {interventionGroup[0]} (
                {interventionGroup[1]} students)
              </li>
            )}
         </ul>
        {/* {interventionGroups[selectedGroupIndex].students.map((student) => (
          <h3 key={student.id}>{getFullNameForStudent(student)}</h3>
        ))}
        <div className="interventionGroupsInfoTooltipNav">
          <span onClick={this.handleAnotherGroupShow(true)}>
            <FontAwesomeIcon icon={faCaretLeft} size="2x" />
          </span>
          <span onClick={this.handleAnotherGroupShow()}>
            <FontAwesomeIcon icon={faCaretRight} size="2x" />
          </span>
        </div> */}
      </div>
    );
  }
}

export default StudentGroupsTooltip;
