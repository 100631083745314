import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useMemo } from "react";
import { EvidenceType } from "../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import { addBehaviorScreenerEcgToDataPeriod } from "../../store/onboarding/actions";

const useBehaviorScreenerECG = () => {
  const dispatch = useDispatch();

  const evidencePeriodData = useSelector(
    (s: ApplicationState) => s.onboarding.evidencePeriodData
  );

  const ecg = useMemo(
    () =>
      evidencePeriodData
        ? evidencePeriodData.evidence_column_groups.find(
            (ecg) =>
              ecg.evidence_columns.some(
                (ec) => ec.evidence_type === EvidenceType.BehaviorScreener
              )
          )
        : undefined,
    [evidencePeriodData]
  );

  const onAdd = () => {
    if (ecg) {
      toastr.info(
        "Select Screener",
        "You already have behavior screener in this data period. Click the screener button in the cell in that column for which you want to add behavior screening."
      );
    } else {
      (dispatch(addBehaviorScreenerEcgToDataPeriod(0, 'srss')) as any)?.then(() =>
        toastr.success(
          "Select Screener",
          "A new column has been added in this data period. Click the screener button in the cell in that column for which you want to add behavior screening."
        )
      );
    }
  };

  return {
    ecg,
    onAdd,
  };
};

export default useBehaviorScreenerECG;
