import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import InterventionStatsTable from "../assigment/intervention-plan-modal/workspaces/inteventions-stats/InterventionStatsTable";
import _ from "lodash";
import FidelityValueSpan from "../assigment/intervention-plan-modal/workspaces/inteventions-stats/FidelityValueSpan";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { CheckListItems, InterventionStatsDetail } from "../../../../../../store/onboarding/types";



type Props = {};

const InterventionStats: FunctionComponent<Props> = (props) => {
  const stats = useSelector((s: ApplicationState) => s.onboarding.interventionsOverview)

  const [selectedInterventionStats, setSelectedInterventionStats] = useState<InterventionStatsDetail>();
  const [selectedCommonStats, setSelectedCommonStats] = useState<CheckListItems[]>();

  // const commonChecklistItems = useMemo(
  //   () => {
  //   return  _.chain(stats)
  //       .flatMap((stats) => (stats !== undefined ) && stats.checklist_items)
  //       .filter((item) => !!item.common_step_name)
  //       .groupBy((item) => item.common_step_name)
  //       .toPairs()
  //       .map(([key, items]) => {
  //         return {
  //           title: key,
  //           usedTimes: //calculateUsedTime,
  //           _.chain(stats)
  //             .filter((stats) =>
  //             (stats !== undefined ) && stats.checklist_items.some(
  //               (ci: any) => ci.common_step_name && (ci.common_step_name != null) && (ci.common_step_name == key)
  //             )
  //             )
  //             .reduce((pV, cV) => pV + cV.loggedTimes, 0)
  //             .value(),
  //           loggedTimes: _.reduce(
  //             items,
  //             (pV, cV) => (cV.loggedTimes ? pV + cV.loggedTimes : pV),
  //             0
  //           ),
  //           notApplicableTimes: _.reduce(
  //             items,
  //             (pV, cV) => (cV.notApplicableTimes ? pV + cV.notApplicableTimes : pV),
  //             0
  //           ),
  //         };
  //       })
  //       .value()
  //   }
  //       ,
  //   [stats]
  // );

  // const handleCommonInterventionStepStatsView = () => {
  //   setSelectedStats({
  //     loggedTimes:
  //       _.chain(stats)
  //         .filter((s) =>
  //           (s !== undefined) && s.intervention?.checklist_items?.some(
  //             (ci: ChecklistItem) => !!ci.common_step_name
  //           )
  //         )
  //         .value().length || 0,
  //     //checklist_items: commonChecklistItems,
  //     groupsIds: _.chain(stats)
  //       .flatMap((s) => (s !== undefined) && s.groupsIds)
  //       .uniq()
  //       .value(),
  //     teachersIds: _.chain(stats)
  //       .flatMap((s) => (s !== undefined) && s.teachersIds)
  //       .uniq()
  //       .value(),
  //   });
  // };

   useEffect(() => {
    if(stats.interventions.length) {
      setSelectedInterventionStats(stats.interventions[0]);
    }
  },[])
  return (
    <div>
      {stats ? (
        <div className="intervention-stats-container">
          <div>
            <h3 className="mb-2">Interventions</h3>
            <ListGroup className="mb-2">
              {stats.interventions.map((stat: InterventionStatsDetail, index: number) => ((stat !== undefined ) &&
                <ListGroup.Item
                  key={index}
                  action
                  className="m-0 d-flex align-items-center justify-content-between"
                  onClick={() => {
                    setSelectedInterventionStats(stat);
                    setSelectedCommonStats([]);
                  }}
                  style={{
                    backgroundColor:
                      selectedInterventionStats &&
                      selectedInterventionStats.intervention_id === stat.intervention_id
                        ? "#cbdcf0"
                        : undefined,
                  }}
                >
                   <>{stat?.title}</>
                  <FidelityValueSpan fidelity={stat?.fedility_percentage} />
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h3 className="mb-2">Other Stats</h3>
            <ListGroup>
              <ListGroup.Item
                action
                className="m-0 d-flex align-items-center justify-content-between"
                onClick={() => {
                  setSelectedCommonStats(stats.other_common_stats)
                  setSelectedInterventionStats(undefined);
                }}
                style={{
                  backgroundColor:
                    selectedCommonStats?.length
                      ? "#cbdcf0"
                      : undefined,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Popover id="common-steps-popover">
                      <Popover.Content>
                        Some interventions share common steps. These statistics 
                        represent fidelity across multiple 
                        interventions where the steps were common.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>Common Research-Based Instructional Components</div>
                </OverlayTrigger>
                <FidelityValueSpan fidelity={null} />
              </ListGroup.Item>
            </ListGroup>
          </div>

          {selectedInterventionStats || selectedCommonStats?.length ? (
            <InterventionStatsTable 
              selectedInterventionStats={selectedInterventionStats} 
              selectedCommonStats={selectedCommonStats}
            />
          ) : (
            <i className="text-center m-auto">
              Click on an intervention to show detailed statistics
            </i>
          )}
        </div>
      ) : (
        <i>No Data</i>
      )}
    </div>
  );
};

export default InterventionStats;
