import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import {
  addStudentToSpellingTestGroup,
  createSpellingTestGroup,
  getStudentsByGrade,
  hideSpellingTestGroupUpsertModal,
  removeStudentFromSpellingTestGroup,
  updateSpellingTestGroup,
} from "../../../../store/spelling-tests/actions";
import { Grade } from "../../../../store/groups/types";
import Select from "react-select";
import {
  ReactSelectOption,
  Student,
  TeacherClass,
  TeacherInfo,
} from "../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import { toastr } from "react-redux-toastr";
import {
  getClassesAssignedToTeacher,
  getTeachers,
} from "../../../../store/onboarding/actions";
import { getFullName } from "../../../../utils/NamesUtils";
import { SpellingTestGroup } from "../../../../store/spelling-tests/types";
import _ from "lodash";

type Props = {};

const SpellingTestGroupUpsertModal: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();

  const teachersRoster = useSelector(
    (s: ApplicationState) => s.onboarding.teachersRoster
  );
  const isLoadingGetTeachers = useSelector(
    (s: ApplicationState) => s.onboarding.isLoading.getTeachers
  );

  const show = useSelector(
    (s: ApplicationState) => s.spellingTests.showSpellingTestGroupUpsertModal
  );
  const selectedGroup = useSelector(
    (s: ApplicationState) => s.spellingTests.selectedGroup
  );

  const [selectedTeacherId, setSelectedTeacherId] = useState<
    number | undefined
  >(undefined);
  const handleSelectedTeacherChange = (
    value: ValueType<TeacherInfo, false>
  ) => {
    setSelectedTeacherId(value ? (value as TeacherInfo).user.id : undefined);
  };
  useEffect(() => {
    if (selectedTeacherId) {
      setSelectedClass(null);
      dispatch(getClassesAssignedToTeacher(selectedTeacherId));
    }
  }, [selectedTeacherId]);

  const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>(
    undefined
  );
  const handleSelectedGradeChange = (
    value: ValueType<ReactSelectOption<Grade>, false>
  ) => {
    if (value) {
      dispatch(getStudentsByGrade((value as ReactSelectOption<Grade>).value));
      //todo remove students which have another grade
    }
    setSelectedGrade(
      value ? (value as ReactSelectOption<Grade>).value : undefined
    );
  };

  const [notes, setNotes] = useState<string | undefined>();
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const [students, setStudents] = useState<Student[]>([]);
  const handleSelectedStudentsChange = (options: ValueType<Student, true>) => {
    setStudents((options as Student[]) ?? []);
  };

  const studentsByGrade = useSelector(
    (s: ApplicationState) => s.spellingTests.studentsByGrade
  );

  const isLoadingGetStudentsByGrade = useSelector(
    (s: ApplicationState) => s.spellingTests.isLoading.getStudentsByGrade
  );
  const isLoadingChangeStudentsInSpellingTestGroup = useSelector(
    (s: ApplicationState) =>
      s.spellingTests.isLoading.changeStudentsInSpellingTestGroup
  );

  const handleShowModal = () => {
    dispatch(getTeachers());
    if (selectedGroup) {
      dispatch(getStudentsByGrade(selectedGroup.grade));
      setSelectedTeacherId(selectedGroup.teacher.id);
      setSelectedGrade(selectedGroup.grade);
      setNotes(selectedGroup.notes);
      setStudents(selectedGroup.students);
    } else {
      setSelectedTeacherId(undefined);
      setSelectedGrade(undefined);
      setNotes(undefined);
      setStudents([]);
    }
  };

  const handleHideModal = useCallback(() => {
    setSelectedTeacherId(undefined);
    setSelectedGrade(undefined);
    setNotes(undefined);
    dispatch(hideSpellingTestGroupUpsertModal());
  }, []);

  const isLoadingGroupUpsert = useSelector((s: ApplicationState) =>
    s.spellingTests.selectedGroup
      ? s.spellingTests.isLoading.updateSpellingTestGroup
      : s.spellingTests.isLoading.createSpellingTestGroup
  );
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedTeacherId && selectedGrade && notes) {
      if (selectedGroup) {
        (dispatch(
          updateSpellingTestGroup(selectedGroup.id, {
            teacher: selectedTeacherId,
            grade: selectedGrade,
            notes: notes,
          })
        ) as any)?.then(
          (group: SpellingTestGroup) => {
            const originalStudentsIds = group.students.map((s) => s.id!);
            const newStudentsIds = students.map((s) => s.id!);
            const isSameIds =
              originalStudentsIds.length === notes.length &&
              originalStudentsIds.every((id) => newStudentsIds.includes(id));
            if (!isSameIds) {
              const newIds: number[] = newStudentsIds.filter(
                (id) => !originalStudentsIds.includes(id)
              );
              const deletedIds: number[] = originalStudentsIds.filter(
                (id) => !newStudentsIds.includes(id)
              );

              Promise.all([
                ...newIds.map((id) =>
                  dispatch(addStudentToSpellingTestGroup(group.id, id!))
                ),
                ...deletedIds.map((id) =>
                  dispatch(removeStudentFromSpellingTestGroup(group.id, id!))
                ),
              ]).then(
                () => handleHideModal(),
                (err: string) => toastr.error("Failed to update students", err)
              );
            } else {
              handleHideModal();
            }
          },
          (err: string) => toastr.error("Failed to update the group", err)
        );
      } else {
        (dispatch(
          createSpellingTestGroup({
            teacher: selectedTeacherId,
            grade: selectedGrade,
            notes: notes,
          })
        ) as any)?.then(
          (group: SpellingTestGroup) => {
            if (students.length) {
              Promise.all(
                students.map(({ id }) =>
                  dispatch(addStudentToSpellingTestGroup(group.id, id!))
                )
              ).then(() => handleHideModal());
            } else {
              handleHideModal();
            }
          },
          (err: string) => toastr.error("Failed to create the group", err)
        );
      }
    }
  };

  const modalTitle = useMemo(() => {
    return selectedGroup
      ? "Update Spelling Test Group"
      : "Create New Spelling Test Group";
  }, [selectedGroup]);

  const gradeOptions: ReactSelectOption<Grade>[] = useMemo(() => {
    return [
      { value: "G1", label: "1st Grade" },
      { value: "G2", label: "2nd Grade" },
      { value: "G3", label: "3rd Grade" },
    ];
  }, []);

  const teachersClasses = useSelector(
    (s: ApplicationState) => s.onboarding.teachersClasses
  );
  const isLoadingGetClassesAssignedToTeacher = useSelector(
    (s: ApplicationState) => s.onboarding.isLoading.getClassesAssignedToTeacher
  );

  const [selectedClass, setSelectedClass] = useState<TeacherClass | null>(null);
  const handleSelectedClassChange = (value: ValueType<TeacherClass, false>) => {
    setSelectedClass(value as TeacherClass);
  };

  const handleAddAllStudentToGroup = () => {
    if (selectedClass) {
      setStudents((students) =>
        _.uniqBy<Student>([...students, ...selectedClass.students], "id")
      );
      setSelectedClass(null);
    }
  };

  return (
    <Modal
      show={show}
      onShow={handleShowModal}
      onHide={handleHideModal}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="upsertSpellingTestGroupForm" onSubmit={handleFormSubmit}>
          <Form.Group>
            <h4 className="font-weight-bold">Teacher</h4>
            <Select
              isClearable
              isLoading={isLoadingGetTeachers}
              placeholder="Select Educator..."
              value={teachersRoster.find(
                (t) => t.user.id === selectedTeacherId
              )}
              options={teachersRoster}
              getOptionLabel={({ user }: TeacherInfo) =>
                `${user.first_name} ${user.last_name}`
              }
              getOptionValue={({ user }: TeacherInfo) => user.id.toString()}
              onChange={handleSelectedTeacherChange}
            />
          </Form.Group>

          <Form.Group>
            <h4 className="font-weight-bold">Classes</h4>
            <div className="d-flex justify-content-between">
              <Select
                key={`class_selector_${selectedClass?.id}`}
                className="flex-grow-1 mr-3"
                isClearable
                isDisabled={!selectedTeacherId}
                isLoading={isLoadingGetClassesAssignedToTeacher}
                placeholder="Select Class..."
                name="teachersClasses"
                value={selectedClass}
                options={teachersClasses}
                getOptionLabel={({ name }: TeacherClass) => name}
                getOptionValue={({ id }: TeacherClass) => id!.toString()}
                onChange={handleSelectedClassChange}
              />

              <button
                disabled={!selectedClass}
                className="blueBtnSm"
                onClick={handleAddAllStudentToGroup}
              >
                Add all students to group
              </button>
            </div>
          </Form.Group>

          <Form.Group>
            <h4 className="font-weight-bold">Grade Level</h4>
            <Select
              isClearable
              placeholder="Select Grade-level..."
              value={
                gradeOptions.find((t) => t.value === selectedGrade) || undefined
              }
              options={gradeOptions}
              onChange={handleSelectedGradeChange}
            />
          </Form.Group>

          <Form.Group>
            <h4 className="font-weight-bold">Notes</h4>
            <Form.Control
              type="text"
              as="textarea"
              value={notes || ""}
              name="notes"
              placeholder="A brief description of the group"
              onChange={handleNotesChange}
              required
            />
          </Form.Group>

          <div className="mb-3">
            <h4 className="font-weight-bold">Students</h4>
            <Select
              disabled={!selectedGrade}
              isMulti
              isClearable
              isLoading={isLoadingGetStudentsByGrade}
              placeholder="Select Students..."
              value={students}
              options={studentsByGrade}
              getOptionLabel={(student: Student) => getFullName(student)}
              getOptionValue={(student: Student) => student.id!.toString()}
              onChange={handleSelectedStudentsChange}
            />
          </div>
        </form>

        <div className="modalActions">
          <button className="whiteBtnSm" onClick={handleHideModal}>
            Close
          </button>
          <button
            disabled={!selectedTeacherId || !selectedGrade}
            className="blueBtnSm"
            form="upsertSpellingTestGroupForm"
            type="submit"
          >
            Save{" "}
            {(isLoadingGroupUpsert ||
              isLoadingChangeStudentsInSpellingTestGroup) && (
              <Spinner animation="border" size="sm" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpellingTestGroupUpsertModal;
