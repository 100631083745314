import React, { useEffect } from "react";
import StudentsTable from "./StudentsTable";
import StudentsInvitePanel from "./StudentsInvitePanel";
import StudentsDetailsPage from "./StudentDetailsPage";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { StudentInfo } from "../../../../../store/onboarding/types";
import { push } from "connected-react-router";
import LoadingIndicator from "../../LoadingIndicator";
import { changeStudentsFilter, getStudents } from "../../../../../store/onboarding/actions";
import StudentEditModal from "./StudentEditModal";
import { changeExitedStudentsGroup, changeShowArchivedGroups } from "../../../../../store/dataFilter/actions";

type Props = { id?: string };

const StudentsPane = ({ id }: Props) => {
  const studentsNotEmpty = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.studentsRoster.length > 0
  );
  const isLoadingGetStudents = useSelector<ApplicationState, boolean>(
    (s) => s.onboarding.isLoading.getStudents
  );
  const studentById = useSelector<ApplicationState, StudentInfo | undefined>(
    (s) => s.onboarding.studentsRoster.find((st) => st.unique_id === id)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!studentsNotEmpty) {
      dispatch(getStudents());
    }
    dispatch(changeShowArchivedGroups(false)); //initial load of student detail page
    dispatch(changeExitedStudentsGroup(false)); //initial load of student detail page
  }, []);

  useEffect(() => {
   dispatch(changeStudentsFilter(''))
  }, []) 

  // useEffect(() => {
  //   if (studentsNotEmpty && !studentById) {
  //     dispatch(push("/rosters/students"));
  //   }
  // }, [studentsNotEmpty, studentById]);

  if (isLoadingGetStudents) {
    return <LoadingIndicator />;
  }
  if (studentById) {
    return <StudentsDetailsPage student={studentById} />;
  }
  if (studentsNotEmpty) {
    return (
      <>
        <StudentsTable />
        <StudentEditModal />
      </>
    );
  }
  return <StudentsInvitePanel />;
};

export default StudentsPane;
