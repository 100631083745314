import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { Modal, Tab, Tabs } from "react-bootstrap";
import InterventionStatsModalContent from "./InterventionStatsModalContent";
import { getInterventionStats, hideInterventionStatsModal } from "../../../../../../store/onboarding/actions";
import { UserInfo } from "../../../../../../store/auth/types";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { openOverviewModel } from "../../../../../../store/groups/actions";
import { CaseLoadUser, Student } from "../../../../../../store/onboarding/types";
import LoadingIndicator from "../../../../../common/onboarding/LoadingIndicator";

type OwnProps = {
  student?: Student
};

type Props = OwnProps;

const InterventionStatsModal: FunctionComponent<Props> = ({student}) => {
  const dispatch = useDispatch();

  const showArchivedGroups = useSelector(
      (s: ApplicationState) => s.dataFilter.showArchivedGroups
    );
  const exitedStudentsGroup = useSelector(
    (s: ApplicationState) => s.dataFilter.exitedStudentsGroup
  );  
  const show = useSelector(
    (s: ApplicationState) => s.onboarding.modalsState.showInterventionStatsModal
  );

  const handleHideModal = useCallback(() => {
    dispatch(hideInterventionStatsModal());
  }, []);

  const educator = useSelector<ApplicationState, UserInfo & CaseLoadUser | undefined>(
    (s) => s.onboarding.selectedEducator
  );

  const getInterventionStatsOverview = useSelector((s: ApplicationState) => s.onboarding.isLoading.getInterventionsOverview)
  
  const overviewModelType = useSelector<ApplicationState, String | undefined>(
    (s) => s.groups.overviewModelType
  );
  const activeTab:string = useMemo(() => {
    return window.location.pathname
  },[overviewModelType]);

  const title = useMemo(() => {
    //Rakesh Bhargava 6 april 2022
    if (!educator) {
      //dispatch(openOverviewModel('all_group'));
      return "Overview of all Groups";
    }
    //dispatch(openOverviewModel('one_educator'));
    return `${educator.case_name}'s Overview`;
  }, [educator]);

  useEffect(() => {
    if(show) {
      let queryStr = '';
      if(student) {
        queryStr=`?student_id=${student.id}${educator?.case_id ? '&case_id='+educator?.case_id : ''}${showArchivedGroups ? '&archived=true': ''}${exitedStudentsGroup ? '&exited=true': ''}`;
        // send selected user in API when this modal call from case load users
        dispatch(getInterventionStats(queryStr))
      } else if(activeTab && (activeTab.includes('groups/behavior') || activeTab.includes('groups/reading')) && !student) {
        if(activeTab.includes('groups/behavior')) {
          queryStr = `?is_behavior=1${educator?.case_id ? '&case_id='+educator?.case_id : ''}${showArchivedGroups ? '&archived=true': ''}`
        }
        if(activeTab.includes('groups/reading')) {
          queryStr = `?is_behavior=0${educator?.case_id ? '&case_id='+educator?.case_id : ''}${showArchivedGroups ? '&archived=true': ''}`
        }
        // send selected user in API when this modal call from case load users
        dispatch(getInterventionStats(queryStr))
      }
     
    }
  },[show, overviewModelType])

  return (
    <Modal
      show={show}
      onHide={handleHideModal}
      animation={false}
      size="xl"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="intervention-stats">
          <Tab eventKey="intervention-stats" title="Intervention Stats">
            <div className="mt-2">
              {getInterventionStatsOverview
              ? <LoadingIndicator />
              : <InterventionStatsModalContent />
              }
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default InterventionStatsModal;
