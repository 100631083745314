import React, { FunctionComponent, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { GoalTypes, InterventionGroup } from "../../../../../store/onboarding/cases/types";

type OwnProps = {};

type Props = OwnProps;

const ActiveBehaviorGroupsNavLink: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { isParent } = useUserRole();

  // const interventionGroups = useSelector<ApplicationState, InterventionGroup[]>(
  //   (s) => s.cases.interventionGroups
  // );

  // const notFinishedGroups = useSelector<ApplicationState, InterventionGroup[]>(
  //   (s) => s.cases.notFinishedGroups
  // );

  // const number = useMemo(() => {
  //   return (
  //     interventionGroups.filter((g) => ( g.goal_type! == GoalTypes.BEHAVIOR) && g.finished).length +
  //     notFinishedGroups.length
  //   );
  // }, [interventionGroups, notFinishedGroups.length]);

  return (
    <>
      <FontAwesomeIcon icon={faUsers} />
      &nbsp;
      {intl.formatMessage(
        {
          id: isParent
            ? "app.navigation.dashboardMenuPanel.activeGroupsLabel.parent"
            : "app.navigation.dashboardMenuPanel.activeGroupsLabel.defaultBehavior",
        },
        { num: 0 }
      )}
    </>
  );
};

export default ActiveBehaviorGroupsNavLink;
