import React, { useMemo, useState } from "react";
import { Grade, TimePeriod } from "../../../../../../../store/groups/types";
import BenchmarkFilters from "../BenchmarkFilters";

const useBenchmarkFilters = ({ show, evidenceColumnGroupId, isCloseModel }: { show?: boolean, evidenceColumnGroupId?: number, isCloseModel?:boolean }) => {
  const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<
    TimePeriod | undefined
  >();
  const [filterDisabled, setFilterDisabled] = useState<any | undefined>(false);

  const filters = useMemo(() => {
    if (!show) {
      return null;
    }
    if(isCloseModel) {
      //setFilterDisabled(false);
    }
    return (
      <BenchmarkFilters
        selectedGrade={selectedGrade}
        setSelectedGrade={setSelectedGrade}
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        evidenceColumnGroupId = {evidenceColumnGroupId}
        filterDisabled = {filterDisabled}
        setFilterDisabled = {setFilterDisabled}
      />
    );
  }, [show, selectedGrade, selectedTimePeriod, evidenceColumnGroupId, filterDisabled]);

  return {
    filters,
    selectedGrade,
    setSelectedGrade,
    selectedTimePeriod,
    setSelectedTimePeriod,
    filterDisabled, 
    setFilterDisabled
  };
};

export default useBenchmarkFilters;
